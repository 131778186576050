/* eslint-disable class-methods-use-this */
import type {
  AssetConfig,
  Features,
  GuidedViewingTourConfigStreamEvent,
  LsfMode,
  NavigationMode,
  ProjectDataConfig,
  ProjectUiConfig,
  SceneConfig,
  Theme,
  ThemeConfig,
  TourConfig,
  TourConfigStructureType,
  UnitsConfig,
  UnitsShort,
  WatermarkConfig,
} from '@g360/vt-types';
import {
  fetchWithRetries,
  getTourConfigStructureType,
  isInIframe,
  MakeReactive,
  parseTourConfig,
  ReactiveBase,
  sceneGroups2SubScenes,
  verStrToArray,
} from '@g360/vt-utils/';
import urlJoin from 'url-join';

import type Engine from '../../Engine';
import ContrastChecker from '../ContrastChecker';
import DEFAULT_FEATURES from '../Utils/defaultFeatures';

class TourConfigServiceBase extends ReactiveBase {
  engine: Engine | null = null;
  defaultAccent = '#ffc600';
  tourConfig: TourConfig;
  assetConfig: AssetConfig;
  configVersion: number[];
  configStructureType: TourConfigStructureType = 'buildings';
  themeConfig: ThemeConfig = 'auto';
  theme: Theme = 'dark';
  /** Full path to the branded logo image, if this is falsy, then the default logo is used  */
  welcomeImage: string | null = null;
  /** Full path to the default logo image */
  defaultWelcomeImage: string | null = null;
  /** If set to false then branded and default welcome logo is disabled */
  welcomeImageEnabled = false;
  tourWelcomeScreen = false;
  showRoomArea = false;
  showTotalAreas = true;
  units: UnitsConfig = 'metric';
  unitsShort: UnitsShort = 'm';
  projectDataConfig?: ProjectDataConfig;
  unitSwitch = true;
  /** Represent ground levels from number 0 or number 1, by default ground floors are 0.
   * Shifts floor number up in UI if set to 1, keeps negative indices the same */
  floorIndexing: 0 | 1 = 0;
  watermark?: WatermarkConfig | null;
  accent?: string;
  /** Project url, used for share link */
  projectUrl: string | null = null;
  timestampFormat: 12 | 24 = 12;
  timestampTimezone = 'UTC';
  mlsCompatible = false;
  lsfRenderingMode: LsfMode = 'off';
  navigationMode: NavigationMode = 'full';
  disabledControlsTop = false;
  region: string;
  gatedTourEnabled = false;
  features: Features;
  /** Scenes that are hidden from the tour.
   * @todo: This is just a temporary solution, we need to change how we handle tourConfig structure.
   * This just allows to hide alt scenes and make it reactive for the editor.
   */
  scenesHidden: Record<string, boolean> = {};

  private contrastChecker = new ContrastChecker('#231720');

  constructor(tourConfig: TourConfig, assetConfig: AssetConfig, requestedLsfMode: LsfMode = 'off') {
    super();

    this.assetConfig = assetConfig;
    this.features = DEFAULT_FEATURES;

    this.mlsCompatible = Boolean(tourConfig?.mlsCompatible);
    this.configVersion = verStrToArray(tourConfig.version || '0');
    this.configStructureType = getTourConfigStructureType(tourConfig, this.configVersion);
    this.region = tourConfig.region || '';

    const scenesHidden: Record<string, boolean> = {};
    Object.entries(tourConfig.scenes).forEach(([sceneKey, scene]) => {
      if (scene.hidden) scenesHidden[sceneKey] = true;
    });
    this.setScenesHidden(scenesHidden);

    this.tourConfig = sceneGroups2SubScenes(parseTourConfig(tourConfig, this.configStructureType));
    this.gatedTourEnabled = tourConfig.gatedTourEnabled ?? false;

    this.loadUiConfig(this.tourConfig.ui || {});
    this.switchLsfMode(requestedLsfMode);
    this.loadDataConfig(this.tourConfig.data || {});
  }

  get scenes() {
    return this.tourConfig.scenes;
  }

  get minPitch() {
    return this.tourConfig.minPitch ?? -90;
  }

  set minPitch(value: number) {
    this.tourConfig.minPitch = value;
  }

  public setEngine(engine: Engine) {
    this.engine = engine;
  }

  public setProjectUrl(publicUrl: string) {
    this.projectUrl = publicUrl;
  }

  public loadUiConfig(uiConfig: ProjectUiConfig) {
    this.units = uiConfig.units || 'metric';
    this.unitSwitch = Boolean(uiConfig.unitSwitch ?? true);
    this.floorIndexing = uiConfig.floorIndexing ?? 0;
    this.accent = uiConfig.accent;
    this.themeConfig = uiConfig.theme || 'auto';
    this.welcomeImage = uiConfig.welcomeImage || null;
    this.welcomeImageEnabled = uiConfig.welcomeImageEnabled || false;
    this.watermark = uiConfig.watermark;
    this.timestampFormat = uiConfig.timestampFormat || 12;
    this.navigationMode = uiConfig.navigationMode || 'full';
    this.timestampTimezone = uiConfig.timestampTimezone || 'UTC';
    this.tourWelcomeScreen = Boolean(uiConfig.welcomeScreen);
    this.showRoomArea = uiConfig.showRoomArea ?? true;
    this.showTotalAreas = uiConfig.showTotalAreas ?? true;
    this.disabledControlsTop = uiConfig.disabledControlsTop || false;
  }

  public loadDataConfig(dataConfig: ProjectDataConfig) {
    this.projectDataConfig = dataConfig;
  }

  /** Call this after every new object construction. */
  public async loadFeatures(): Promise<void> {
    let featureSource = 'default';
    let featureSubset1 = '';
    let featureRegion = '';

    switch (this.lsfRenderingMode) {
      case 'standard':
        featureSource = 'lsf';
        featureSubset1 = 'standard';
        if (this.region === 'US') featureRegion = this.region;
        break;

      case 'idealista':
        featureSource = 'lsf';
        featureSubset1 = 'idealista';
        break;

      case 'off':
      default:
        featureSource = 'default';
    }

    const embedded = isInIframe() ? 'embedded' : '';

    const url = urlJoin(
      this.assetConfig.assetPath,
      'features',
      featureSource,
      featureSubset1,
      featureRegion,
      embedded,
      'features.json'
    );

    const response = await fetchWithRetries(url);

    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `featureSource=${featureSource} featureRegion=${featureRegion} featureSubset1=${featureSubset1} "embedded=${embedded}"`
      );
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const features = await response.json();

    // Ex.: idealista mode forces units to metric
    if (features.defaultUnits !== 'floorplan-based') this.changeUnits(features.defaultUnits);

    this.features = { ...DEFAULT_FEATURES, ...features };
  }

  /**
   * @todo: Is this really necessary? We should remove this.
   * changing tour configs if they have not changed. Changed values won't be reset
   */

  public reload(rawTourConfig: TourConfig) {
    this.configVersion = verStrToArray(rawTourConfig.version || '0');
    this.configStructureType = getTourConfigStructureType(rawTourConfig, this.configVersion);
    this.tourConfig = sceneGroups2SubScenes(parseTourConfig(rawTourConfig, this.configStructureType));
    this.mlsCompatible = Boolean(rawTourConfig?.mlsCompatible);
    this.reInitialize(() => {
      this.loadUiConfig(this.tourConfig.ui || {});
    });
  }

  public getVersion(type?: 'major' | 'minor' | 'patch'): number {
    switch (type) {
      case 'major':
        return this.configVersion[0];
      case 'minor':
        return this.configVersion[2];
      case 'patch':
        return this.configVersion[3];
      default:
        return this.configVersion[0];
    }
  }

  /**
   * Change the units of measurement.
   * @param units - The new units of measurement in shorthand type (m|ft) or as a config (metric|imperial).
   */
  public changeUnits = (units: UnitsShort | UnitsConfig) => {
    this.units = units === 'm' || units === 'metric' ? 'metric' : 'imperial';
  };

  /** Gets sorted scene key list, using .sort() which is ascending by comparing sequences of UTF-16 code unit values */
  public getSortedSceneKeyList(): string[] {
    return Object.keys(this.tourConfig.scenes).sort();
  }

  /**
   * Gets first valid scene key, first checks the one defined in tour.json/firstScene.
   * If that fails then first key from {@link getSortedSceneKeyList}
   */
  public getFirstSceneKey(): string {
    const sceneKeys = this.getSortedSceneKeyList();

    if (sceneKeys.indexOf(this.tourConfig.firstScene) !== -1) {
      return this.tourConfig.firstScene;
    }

    return sceneKeys[0];
  }

  /** Returns [mainSceneKey, subSceneKey] in case of subScene or just the given sceneKey otherwise */
  public getNestedSceneLocation(sceneKey: string): string | [string, string] {
    const groupIdx = this.tourConfig.sceneGroups?.findIndex((group) => group.includes(sceneKey)) ?? -1;

    if (groupIdx === -1) return sceneKey;

    return [this.tourConfig.sceneGroups![groupIdx][0], sceneKey];
  }

  /** Gets scene config by scene key, or subScene by providing main scene key and subScene key */
  public getSceneConfigByKey(sceneKey: string | [string, string]): SceneConfig {
    const firstSceneKey = this.getFirstSceneKey();

    if (Array.isArray(sceneKey)) {
      const mainScene = this.tourConfig.scenes[sceneKey[0]];

      // In case trying to get main scene of subScene group
      if (sceneKey[0] === sceneKey[1]) return mainScene;

      if (!mainScene) {
        return this.getSceneConfigByKey(firstSceneKey);
      }

      return mainScene.subScenes?.[sceneKey[1]] ?? this.getSceneConfigByKey(firstSceneKey);
    }

    let sceneConfig = this.tourConfig.scenes[sceneKey];

    // If invalid, then return first valid scene
    if (!sceneConfig) {
      if (sceneKey === firstSceneKey) throw new Error('First scene not found');
      sceneConfig = this.getSceneConfigByKey(firstSceneKey);
    }

    return sceneConfig;
  }

  /**
   * Finds closest scene config for given x,y in floor/building.
   */
  public getSceneConfigByPosition(
    x: number,
    y: number,
    building: string | undefined,
    floor: string | undefined
  ): SceneConfig | null {
    let closestSC: SceneConfig | null = null;
    let closestDistance = Infinity;
    Object.keys(this.tourConfig.scenes).forEach((sceneKey) => {
      const scene = this.tourConfig.scenes[sceneKey];
      if (scene.floor === floor && scene.building === building) {
        const distance = Math.sqrt((scene.camera[0] - x) ** 2 + (scene.camera[1] - y) ** 2);
        if (distance < closestDistance) {
          closestSC = scene;
          closestDistance = distance;
        }
      }
    });
    return closestSC;
  }

  /** Gets the scene config based on tour.json/firstScene setting or fall-back to first valid scene */
  public getInitialSceneConfig(): SceneConfig {
    return this.getSceneConfigByKey(this.getFirstSceneKey());
  }

  /** Get projectId and companyId, in dev mode these may be undefined */
  public getTourMetaData() {
    return {
      projectId: this.tourConfig.projectId,
      companyId: this.tourConfig.companyId,
    };
  }

  // We check if the tour is mls compatible and if the link has requested an lsf rendering
  public switchLsfMode(requestedLsfMode: LsfMode) {
    if (requestedLsfMode === 'off' || !this.mlsCompatible) {
      this.lsfRenderingMode = 'off';
      return;
    }

    this.lsfRenderingMode = requestedLsfMode;
    this.watermark = undefined;

    if (this.welcomeImage) {
      this.welcomeImageEnabled = false;
    }

    if (requestedLsfMode === 'idealista') {
      this.welcomeImageEnabled = false;
    }
  }

  public loadNewSceneGroups(sceneGroups: TourConfig['sceneGroups']) {
    this.tourConfig.sceneGroups = sceneGroups;
    this.tourConfig = sceneGroups2SubScenes(this.tourConfig, { newSceneGroupOrder: true });
  }

  public setScenesHidden(scenesHidden: Record<string, boolean>) {
    this.scenesHidden = scenesHidden;
  }

  protected init() {
    this.initGuidedViewing();

    this.watch(() => {
      this.theme = this.getAutoTheme();
      const logoType = this.theme === 'light' ? 'dark' : 'light';
      this.defaultWelcomeImage = urlJoin(this.assetConfig.assetPath, `/images/welcome/default-${logoType}.png`);
    });

    this.watch(() => {
      this.unitsShort = this.units === 'metric' ? 'm' : 'ft';
    });
  }

  protected initGuidedViewing() {
    // Handle guided viewing events
    let remoteSyncReady = false;

    const remoteSyncReadyHandler = () => {
      if (remoteSyncReady || !this.engine) return;
      remoteSyncReady = true;

      // Receive
      this.engine.subscribe('tour.config.remoteUpdate', (payload) => {
        this.changeUnits(payload.units);
      });

      // Transmit
      this.watch(() => {
        const event: GuidedViewingTourConfigStreamEvent = {
          units: this.units,
          source: 'tour-config',
        };
        this.engine?.emitGuidedViewingTourConfigEvent(event);
      });
    };

    this.engine?.subscribe('remoteSyncReady', remoteSyncReadyHandler);

    if (this.engine?.isInGuidedViewing()) {
      remoteSyncReadyHandler();
    }
  }

  private getAutoTheme(color?: string): Theme {
    if (this.themeConfig === 'auto') {
      // Set auto theme based on configured accent color
      const contrastRatio = this.contrastChecker.getContrastRatio(color ?? this.accent ?? this.defaultAccent);

      if (contrastRatio) {
        return contrastRatio <= 4.5 ? 'light' : 'dark';
      }
    }

    return this.themeConfig === 'light' ? 'light' : 'dark';
  }
}

// eslint-disable-next-line import/prefer-default-export
export class TourConfigService extends MakeReactive(TourConfigServiceBase, [
  'accent',
  'showRoomArea',
  'theme',
  'themeConfig',
  'tourConfig',
  'units',
  'watermark',
  'welcomeImage',
  'defaultWelcomeImage',
  'welcomeImageEnabled',
  'tourWelcomeScreen',
  'projectDataConfig',
  'unitsShort',
  'lsfRenderingMode',
  'mlsCompatible',
  'projectUrl',
  'timestampFormat',
  'timestampTimezone',
  'navigationMode',
  'floorIndexing',
  'minPitch',
  'showTotalAreas',
  'features',
  'configStructureType',
  'gatedTourEnabled',
  'scenesHidden',
]) {}
