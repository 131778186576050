import type { SceneConfig } from '@g360/vt-types';
import { scalingM4, toRad, translationM4, transposeM4 } from '@g360/vt-utils';

import RendererDebug from '../../common/RendererDebug';
import { getPerspectiveMatrix, getRotationMatrixZX } from '../../common/Utils';
import { disableVertexAttributes, enableVertexAttributes, initShaders, loadShaders } from '../../common/webglUtils';
import type DebugControls from '../../DebugControls';
import type { ProgramName } from '../../types/internal';
import fragmentShaderSource from './depth.fs.glsl';
import vertexShaderSource from './depth.vs.glsl';

/**
 * a) Renders depthmaps for transition (just before the transition starts)
 *    There was talk for downloading instead of rendering, but AFAIK no project has the assets,
 *    and the functionality to download the depthmaps (if tour.json has the paths) has been removed
 *
 * b) Renders colorful debug view of the geometry (when debug controls are enabled)
 */
class DepthProgram {
  name: ProgramName;
  orderIndex = 0;

  pitch = 0;
  yaw = 0;
  fov = 0;

  cameraPosition: number[] = [];

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private oldGeometry = false;
  private debugControls?: DebugControls;

  private yawOffset = 0;
  private alpha = 1.0;

  private vertCoordLocation = 0;
  private matrixPerspectiveLocation: WebGLUniformLocation | null = null;
  private matrixRotationLocation: WebGLUniformLocation | null = null;
  private matrixScaleLocation: WebGLUniformLocation | null = null;
  private matrixCameraPosLocation: WebGLUniformLocation | null = null;
  private nearLocation: WebGLUniformLocation | null = null;
  private farLocation: WebGLUniformLocation | null = null;
  private resolutionLocation: WebGLUniformLocation | null = null;
  private positionBuffer: WebGLBuffer | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private floor = '-';
  private building = '-';
  private geometry: number[] = [];

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    oldGeometry: boolean,
    name?: ProgramName,
    debugControls?: DebugControls
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.oldGeometry = oldGeometry;
    this.name = name ?? 'DepthProgram';
    this.debugControls = debugControls;
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);
    if (this.program) {
      this.vertCoordLocation = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.matrixPerspectiveLocation = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.matrixRotationLocation = this.gl.getUniformLocation(this.program, 'u_rotate');
      this.matrixScaleLocation = this.gl.getUniformLocation(this.program, 'u_scale');
      this.matrixCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_cameraPos');
      this.nearLocation = this.gl.getUniformLocation(this.program, 'u_near');
      this.farLocation = this.gl.getUniformLocation(this.program, 'u_far');
      this.resolutionLocation = this.gl.getUniformLocation(this.program, 'u_resolution');
      this.positionBuffer = this.gl.createBuffer();
      this.vertexAttributes = [this.vertCoordLocation];
    }
  }

  loadGeometry(sceneConfig: SceneConfig): void {
    loadShaders(this.gl, this.program); // even no new geometry is loaded

    if (sceneConfig.geometry) {
      const floor = sceneConfig.floor;
      const building = sceneConfig.building;

      if (!this.geometry || this.floor !== floor || this.building !== building) {
        this.geometry = sceneConfig.geometry.flat(2);
        this.floor = floor || '--';
        this.building = building;
        this.prepStage();
      }
    }
  }

  prepStage(): void {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometry), this.gl.STATIC_DRAW);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  destroy(): void {}

  render(): void {
    if (!__DEV_PANEL__) return;
    if (!RendererDebug.runDebugRender.depth) return;

    this.draw();
  }

  draw(snapshotMode = false): void {
    if (!this.gl) return;

    if (!snapshotMode) loadShaders(this.gl, this.program); // shaders are already loaded in this mode

    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.depthFunc(this.gl.LESS);

    // 1 sided door fix
    // this can be done only to modern projects, as old ones have their triangles ordered the wrong way
    if (!this.oldGeometry) {
      this.gl.enable(this.gl.CULL_FACE);
      this.gl.cullFace(this.gl.FRONT);
    }

    // need to mask WHITE anything not rendered (holes in geometry or hallways too long from camera to see the other end)
    this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    const drawNear = 20; // needs to as BIG as the geometry allows it
    const drawFar = 1270; // needs to be as SMALL as geometry allows it
    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

    const perspectiveMatrix = getPerspectiveMatrix(this.fov, size, drawNear, drawFar);
    this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(transposeM4(perspectiveMatrix)));

    this.gl.uniform1f(this.nearLocation, drawNear);
    this.gl.uniform1f(this.farLocation, drawFar);
    this.gl.uniform1f(this.resolutionLocation, Math.max(size.width, size.height));

    const pos = this.debugControls && !snapshotMode ? this.debugControls.cameraManualOffsetsCalculated : [0, 0, 0];
    const cameraPosMatrix = translationM4(pos[0] - this.cameraPosition[0], pos[1] - this.cameraPosition[1], pos[2]);

    this.gl.uniformMatrix4fv(this.matrixCameraPosLocation, false, new Float32Array(cameraPosMatrix));

    const rotationMatrix = getRotationMatrixZX(this.yaw, toRad(-90) + this.pitch);
    this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(rotationMatrix));

    const scaleMatrix = scalingM4(1, 1, -1);
    this.gl.uniformMatrix4fv(this.matrixScaleLocation, false, new Float32Array(scaleMatrix));

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.geometry.length / 3);

    this.gl.disable(this.gl.CULL_FACE);
    this.gl.disable(this.gl.DEPTH_TEST);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default DepthProgram;
