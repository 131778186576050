/** Normalize angle to 0-359 range */
export function normalizeAngle(angleDeg) {
    const singleTurn = angleDeg % 360;
    return singleTurn < 0 ? 360 + singleTurn : singleTurn;
}
/** Takes in sceneKey an returns cameraModel, cameraNumber, projectNumber, panoNumber */
export function getDataFromSceneKey(sceneKey) {
    const parseResult = (result) => (result ? parseInt(result[1], 10) : null);
    const cameraNumber = parseResult(/CAM(\w+)/.exec(sceneKey));
    const projectNumber = parseResult(/PR(\d+)/.exec(sceneKey));
    const panoNumber = parseResult(/PAN(\d+)/.exec(sceneKey));
    const cameraModelRegExp = /CAM\d+(\w+)/.exec(sceneKey);
    const cameraModel = cameraModelRegExp ? cameraModelRegExp[1] : null;
    return { cameraModel, cameraNumber, projectNumber, panoNumber };
}
