import { Utils } from '@g360/vt-engine';
import { getTourConfigStructureType, linearScale, MakeReactive, ReactiveBase, verStrToArray } from '@g360/vt-utils/';
/** @todo Probably need to rename to minMapService */
class NavigationServiceBase extends ReactiveBase {
    get activeScene() {
        return this._activeScene;
    }
    set activeScene(scene) {
        this._activeScene = scene;
    }
    get activeSubScene() {
        return this._activeSubScene;
    }
    set activeSubScene(scene) {
        this._activeSubScene = scene;
    }
    static getStructure(tourConfig) {
        const structure = {
            buildings: {},
            buildingsOrder: [],
            allScenes: [],
            size: tourConfig.size && { width: tourConfig.size[0], height: tourConfig.size[1] },
        };
        const buildings = tourConfig.buildings;
        const layers = tourConfig.layers;
        if (buildings) {
            structure.buildingsOrder = Object.keys(buildings).sort(Utils.strNumSortAsc);
            Object.entries(buildings).forEach(([buildingKey, building]) => {
                const levelsOrder = Object.keys(building.floors).sort(Utils.strNumSortAsc);
                const levels = {};
                Object.entries(building.floors).forEach(([floorKey, floor]) => {
                    levels[floorKey] = { imageUrl: floor.imageUrl };
                });
                structure.buildings[buildingKey] = {
                    levelsOrder,
                    levels,
                    center: { x: building.center[0], y: building.center[1] },
                    size: { width: building.size[0], height: building.size[1] },
                };
            });
        }
        else if (layers) {
            const levelsList = [];
            const levels = {};
            Object.entries(layers).forEach(([layerKey, layer]) => {
                const levelKey = layer.level || layerKey;
                levelsList.push(levelKey);
                levels[levelKey] = {
                    imageUrl: layer.imageUrl,
                };
            });
            // NOTE: to support legacy projects we need to put every level under a single building,
            // even if scene.building values are different - they are only used to enable fade transitions
            structure.buildingsOrder = ['1'];
            structure.buildings['1'] = {
                levelsOrder: levelsList.sort(Utils.strNumSortAsc),
                levels,
                center: null,
                size: null,
            };
        }
        // If no layers or buildings, then make all the scenes `outside` scenes, so we can show the list
        if (!buildings && !layers) {
            Object.values(tourConfig.scenes).forEach((scene_) => {
                scene_.outside = true;
            });
        }
        if (tourConfig.scenes) {
            const sortedKeys = Object.keys(tourConfig.scenes).sort();
            sortedKeys.forEach((key) => {
                const scene = tourConfig.scenes[key];
                if (scene.outside) {
                    structure.outsideScenes = structure.outsideScenes || [];
                    structure.outsideScenes.push(scene);
                }
                structure.allScenes.push(scene);
            });
        }
        return structure;
    }
    static getKeyBoundary(key, keyArray) {
        if (!keyArray || keyArray.length <= 1)
            return 'both';
        const currentIndex = keyArray.indexOf(key);
        if (currentIndex === -1)
            return 'both';
        if (currentIndex === 0)
            return 'min';
        if (currentIndex >= keyArray.length - 1)
            return 'max';
        return 'none';
    }
    static createPinConfig(scene) {
        return {
            posRatio: scene.posRatio,
            key: scene.sceneKey,
        };
    }
    constructor(tourConfig) {
        super();
        this.engine = null;
        this.isEngineReady = false;
        this.tourConfigStructureType = 'buildings';
        this.engineSubscriptions = [];
        this.floorPlanError = false;
        this.floorPlanLoaded = false;
        this.showOutsideSwitch = false;
        this.transitionInProgress = false;
        this._activeScene = null;
        this._activeSubScene = null;
        this.sceneGraph = null;
        this.changeLevel = (offset) => {
            const nextLevelKey = this.getNextLevelKey(this.levelKey, this.buildingKey, offset);
            if (nextLevelKey === this.levelKey)
                return;
            if (nextLevelKey) {
                this.levelKey = nextLevelKey;
                this.levelBoundary = this.getLevelBoundary(this.buildingKey, nextLevelKey);
                this.changeFloorPlanImage();
            }
        };
        this.changeBuilding = (buildingKey) => {
            var _a, _b, _c, _d;
            if (buildingKey === this.buildingKey)
                return;
            const outsideSceneCount = (_b = (_a = this.structure.outsideScenes) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            if (buildingKey === 'outside' && outsideSceneCount) {
                this.buildingKey = 'outside';
                this.floorPlanLoaded = false;
                if (outsideSceneCount === 1 && ((_c = this.structure.outsideScenes) === null || _c === void 0 ? void 0 : _c[0])) {
                    (_d = this.engine) === null || _d === void 0 ? void 0 : _d.loadSceneKey(this.structure.outsideScenes[0].sceneKey);
                }
                return;
            }
            // TODO: refactor this.getNextBuildingKey, no more sequential switch is needed
            const result = this.getNextBuildingKey(this.levelKey, buildingKey, 0);
            if (result) {
                this.levelKey = result.levelKey;
                this.buildingKey = result.buildingKey;
                this.levelBoundary = this.getLevelBoundary(result.buildingKey, this.levelKey);
                this.changeFloorPlanImage();
            }
        };
        this.setImageLoaded = () => {
            if (!this.floorPlanLoaded) {
                this.floorPlanLoaded = true;
                this.imageType = this.imageType === 'svg' ? 'svg' : 'original';
            }
        };
        this.setImageError = (imageType) => {
            if (imageType === 'svg') {
                this.imageType = 'optimized';
                return;
            }
            if (imageType === 'original' && this.floorPlanLoaded) {
                this.imageType = 'optimized';
                return;
            }
            if (imageType === 'optimized' && !this.floorPlanLoaded) {
                this.imageType = 'original';
                return;
            }
            this.floorPlanError = true;
        };
        /**
         * Pano matching: switch to the pano that matches the new level
         * a.k.a. "Minimap Touring"
         * @param offset -- change level: -1 down, 1 up
         * @return -- true if minimap should switch to the new level
         */
        this.panoMatchLevel = (offset) => {
            if (!this.engine)
                return false;
            const tourConfigService = this.engine.getTourConfigService();
            const currentScene = tourConfigService.tourConfig.scenes[this.engine.getActiveSceneKey()];
            const currentBuilding = currentScene.building;
            const currentFloor = currentScene.floor; // floor from current scene
            const currentLevelKey = this.levelKey; // floor from minimap
            // minimap is not in sync with the current scene, most likely there are no panos in this floor
            // must not switch panos, just return true to allow floor switch in minimap
            if (currentFloor !== currentLevelKey)
                return true;
            if (!currentFloor)
                return false;
            const wantedFloor = this.getNextLevelKey(currentFloor, currentBuilding, offset);
            if (currentFloor === currentScene.floor) {
                // go to FLOOR
                const graph = this.createSceneGraph(tourConfigService.tourConfig.scenes);
                const path = NavigationServiceBase.searchSceneGraph(graph, currentScene.sceneKey, (key) => {
                    const targetScene = tourConfigService.tourConfig.scenes[key];
                    return (targetScene &&
                        targetScene.building === currentScene.building &&
                        !targetScene.outside &&
                        targetScene.floor === wantedFloor);
                });
                if (path) {
                    const nextSceneKey = path[path.length - 1];
                    this.engine.loadScene({
                        sceneKey: nextSceneKey,
                        pitch: undefined,
                        yaw: undefined,
                    });
                    return true;
                }
            }
            return false;
        };
        /**
         * Pano matching: switch to the pano that matches the new building (if there is one)
         * @param newBuilding
         * @return -- true if pano was found and changed to
         */
        this.panoMatchBuilding = (newBuilding) => {
            var _a;
            if (!this.engine)
                return false;
            const tourConfigService = this.engine.getTourConfigService();
            const currentScene = tourConfigService.tourConfig.scenes[this.engine.getActiveSceneKey()];
            if (newBuilding === 'outside') {
                // go OUTSIDE
                if (!currentScene.outside) {
                    if ((_a = this.structure.outsideScenes) === null || _a === void 0 ? void 0 : _a.length) {
                        const firstScene = tourConfigService.tourConfig.firstScene;
                        const firstSceneIsOutside = tourConfigService.tourConfig.scenes[firstScene].outside;
                        // use first outside scene from the list or if THE first scene is outside, then use it
                        const outsideScene = firstSceneIsOutside ? firstScene : this.structure.outsideScenes[0].sceneKey;
                        this.engine.loadScene({
                            sceneKey: outsideScene,
                            pitch: undefined,
                            yaw: undefined,
                        });
                        return true;
                    }
                }
            }
            else if (newBuilding !== currentScene.building || currentScene.outside) {
                // go to BUILDING
                const graph = this.createSceneGraph(tourConfigService.tourConfig.scenes);
                const path = NavigationServiceBase.searchSceneGraph(graph, currentScene.sceneKey, (key) => tourConfigService.tourConfig.scenes[key].building === newBuilding &&
                    !tourConfigService.tourConfig.scenes[key].outside);
                // path is 100% guaranteed to exist, since all scenes are connected (sometimes they are not, but we fake a path in that case)
                if (path) {
                    const nextSceneKey = path[path.length - 1];
                    this.engine.loadScene({
                        sceneKey: nextSceneKey,
                        pitch: undefined,
                        yaw: undefined,
                    });
                    return true;
                }
            }
            return false;
        };
        this.handleSceneChange = (prevScene, nextScene) => {
            const isSubScene = Array.isArray(nextScene);
            const targetMainScene = isSubScene ? nextScene[0] : nextScene;
            this.transitionInProgress = true;
            if (!targetMainScene.outside) {
                this.levelKey = targetMainScene.level;
                this.buildingKey = targetMainScene.building;
                this.levelBoundary = this.getLevelBoundary(targetMainScene.building, targetMainScene.level);
            }
            else {
                this.buildingKey = 'outside';
            }
            this.changeFloorPlanImage();
        };
        this.handleTransitionEnd = () => {
            this.transitionInProgress = false;
        };
        this.handleEngineReady = () => {
            this.isEngineReady = true;
        };
        this.tourConfig = tourConfig;
        this.tourConfigVersion = verStrToArray(tourConfig.version || '0');
        this.tourConfigStructureType = getTourConfigStructureType(tourConfig, this.tourConfigVersion);
        this.structure = NavigationServiceBase.getStructure(tourConfig);
        // Set default values
        this.levelKey = '0';
        this.buildingKey = '1';
        this.levelBoundary = this.getLevelBoundary(this.buildingKey, this.levelKey);
        this.imageUrl = null;
        this.imageType = 'optimized';
        this.createSceneGraph(tourConfig.scenes); // Force create graph to check if it is connected and send Sentry message if not. Remove this line if aggressive Sentry is not needed, graph will be created on first use (minimap navigation)
    }
    setEngine(engine) {
        this.engine = engine;
        this.initEngineListeners(this.engine);
    }
    get hasOutsideScenes() {
        var _a;
        return Boolean((_a = this.structure.outsideScenes) === null || _a === void 0 ? void 0 : _a.length);
    }
    get hasMultipleOutsideScenes() {
        var _a, _b;
        return ((_b = (_a = this.structure.outsideScenes) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 1;
    }
    get hasBuildings() {
        return this.structure.buildingsOrder.length >= 1;
    }
    get hasMultipleBuildings() {
        return this.structure.buildingsOrder.length > 1;
    }
    destroy() {
        this.engineSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    getFloorCount() {
        var _a;
        return ((_a = this.structure.buildings[this.buildingKey]) === null || _a === void 0 ? void 0 : _a.levelsOrder.length) || 0;
    }
    isFloorplan() {
        return this.structure.buildingsOrder.length > 0 && this.structure.size !== undefined;
    }
    /** Get building center offset in ratio coordinates */
    getBuildingOffsetRatio(buildingKey) {
        const buildingOffsetWorld = this.getBuildingOffset(buildingKey);
        const floorplanWorldSize = this.getFloorplanSize();
        if (buildingOffsetWorld) {
            return {
                x: 0.5 - linearScale(buildingOffsetWorld.x, [0, floorplanWorldSize.width], [0, 1]),
                y: 0.5 - linearScale(buildingOffsetWorld.y, [0, floorplanWorldSize.height], [0, 1]),
            };
        }
        return { x: 0, y: 0 };
    }
    /** Get list of pins with positions (in centimeters) converted to percent values */
    getPins(activeBuildingKey, activeLevelKey) {
        const pinList = [];
        Object.values(this.tourConfig.scenes).forEach((scene) => {
            // In older projects multiple buildings can be in the same layer, so we don't need to check if buildings match
            let sameBuilding = true;
            if (this.tourConfigStructureType === 'buildings') {
                sameBuilding = scene.building === activeBuildingKey;
            }
            if (!scene.outside && scene.level === activeLevelKey && sameBuilding) {
                pinList.push(NavigationServiceBase.createPinConfig(scene));
            }
        });
        return pinList;
    }
    /** Get floorplan size represented in real world centimeters */
    getFloorplanSize() {
        return this.structure.size;
    }
    switchSubScene(mainSceneKey, subSceneKey) {
        if (!this.engine)
            return;
        this.engine.loadScene({ sceneKey: [mainSceneKey, subSceneKey], pitch: undefined, yaw: undefined });
    }
    init() {
        var _a, _b;
        this.initGuidedViewing();
        this.watch(() => {
            this.showOutsideSwitch = this.buildingKey === 'outside' && this.hasMultipleOutsideScenes;
            this.imageUrl = this.buildingKey === 'outside' ? null : this.imageUrl;
        });
        const activeEngineScene = (_a = this.engine) === null || _a === void 0 ? void 0 : _a.getActiveSceneConfig();
        if (activeEngineScene)
            this.activeScene = activeEngineScene;
        if (!this.activeScene)
            return;
        const imageUrlConfig = this.getImageUrlConfig(this.activeScene.level, this.activeScene.building);
        this.buildingKey = this.activeScene.outside ? 'outside' : (imageUrlConfig === null || imageUrlConfig === void 0 ? void 0 : imageUrlConfig.buildingKey) || 'outside';
        this.levelKey = (imageUrlConfig === null || imageUrlConfig === void 0 ? void 0 : imageUrlConfig.levelKey) || '0';
        this.imageUrl = (imageUrlConfig === null || imageUrlConfig === void 0 ? void 0 : imageUrlConfig.imageUrl) || null;
        this.levelBoundary = this.getLevelBoundary(this.buildingKey, this.levelKey);
        this.imageType = ((_b = this.imageUrl) === null || _b === void 0 ? void 0 : _b.svg) ? 'svg' : 'optimized';
    }
    initGuidedViewing() {
        var _a, _b;
        let remoteSyncReady = false;
        const remoteSyncReadyHandler = () => {
            var _a;
            if (remoteSyncReady)
                return;
            remoteSyncReady = true;
            // Receive
            (_a = this.engine) === null || _a === void 0 ? void 0 : _a.subscribe('minimap.navigation.remoteUpdate', (payload) => {
                if (this.levelKey === payload.levelKey && this.buildingKey === payload.buildingKey)
                    return;
                this.levelKey = payload.levelKey;
                this.buildingKey = payload.buildingKey;
                this.levelBoundary = this.getLevelBoundary(payload.buildingKey, payload.levelKey);
                this.changeFloorPlanImage();
            });
            // Transmit
            this.watch(() => {
                var _a;
                const event = {
                    levelKey: this.levelKey,
                    buildingKey: this.buildingKey,
                    source: 'minimap-navigation',
                };
                (_a = this.engine) === null || _a === void 0 ? void 0 : _a.emitGuidedViewingMinimapEvent(event);
            });
        };
        (_a = this.engine) === null || _a === void 0 ? void 0 : _a.subscribe('remoteSyncReady', remoteSyncReadyHandler);
        if ((_b = this.engine) === null || _b === void 0 ? void 0 : _b.isInGuidedViewing()) {
            remoteSyncReadyHandler();
        }
    }
    initEngineListeners(engine) {
        if (this.engineSubscriptions.length) {
            this.engineSubscriptions.forEach((subscription) => subscription.unsubscribe());
            this.engineSubscriptions = [];
        }
        this.engineSubscriptions = [
            engine.subscribe('scene.transition.start', this.handleSceneChange),
            engine.subscribe('scene.transition.end', this.handleTransitionEnd),
            engine.subscribe('scene.mainscene.change', (newMainScene) => {
                this.activeScene = newMainScene;
            }),
            engine.subscribe('scene.subscene.change', (newSubScene) => {
                this.activeSubScene = newSubScene;
            }),
            engine.subscribe('ready', this.handleEngineReady),
        ];
    }
    changeFloorPlanImage() {
        var _a;
        const nextState = this.getImageUrlConfig(this.levelKey, this.buildingKey);
        if (this.imageUrl === (nextState === null || nextState === void 0 ? void 0 : nextState.imageUrl))
            return;
        this.floorPlanLoaded = false;
        this.imageUrl = (nextState === null || nextState === void 0 ? void 0 : nextState.imageUrl) || this.imageUrl;
        this.imageType = ((_a = this.imageUrl) === null || _a === void 0 ? void 0 : _a.svg) ? 'svg' : 'optimized';
    }
    /**
     * Gets valid floorplan image links for the provided level and building.
     * If image not found, then fallback to the first above ground level for the largest building.
     * Return the {@link ImageUrlConfig} and the building and level key which are changed in case of the fallback.
     */
    getImageUrlConfig(levelKey, buildingKey) {
        var _a, _b;
        if (!this.isFloorplan())
            return null;
        const imageUrl = (_b = (_a = this.structure.buildings[buildingKey]) === null || _a === void 0 ? void 0 : _a.levels[levelKey]) === null || _b === void 0 ? void 0 : _b.imageUrl;
        if (imageUrl) {
            return { imageUrl, levelKey, buildingKey };
        }
        const buildingsKeys = Object.keys(this.structure.buildings);
        let nextBuildingKey = buildingsKeys[0];
        let largestBuilding = this.structure.buildings[nextBuildingKey];
        buildingsKeys.forEach((nBuildingKey) => {
            const nextBuilding = this.structure.buildings[nBuildingKey];
            if (largestBuilding.size && nextBuilding.size) {
                const prevArea = largestBuilding.size.width * largestBuilding.size.height;
                const nextArea = nextBuilding.size.width * nextBuilding.size.height;
                if (nextArea > prevArea) {
                    largestBuilding = nextBuilding;
                    nextBuildingKey = nBuildingKey;
                }
            }
        });
        const nextLevelKey = largestBuilding.levelsOrder.find((lKey) => parseFloat(lKey) >= 0) || largestBuilding.levelsOrder[0];
        return {
            imageUrl: largestBuilding.levels[nextLevelKey].imageUrl,
            levelKey: nextLevelKey,
            buildingKey: nextBuildingKey,
        };
    }
    /**
     * Get next level key by provided offset from current level key, null if out of bounds or no levels available
     * Need to provide current building key
     */
    getNextLevelKey(currentLevelKey, currentBuildingKey, offset) {
        var _a;
        const levelsOrder = (_a = this.structure.buildings[currentBuildingKey]) === null || _a === void 0 ? void 0 : _a.levelsOrder;
        if (!levelsOrder || levelsOrder.length <= 1)
            return null;
        const currentIndex = levelsOrder.indexOf(currentLevelKey);
        if (currentIndex === -1)
            return null;
        const nextLevelKey = levelsOrder[currentIndex + offset] || null;
        return nextLevelKey || null;
    }
    /**
     * Get next building key by provided offset from current building key, null if out of bounds
     * Need to provide current level key. Will automatically switch to different level if current level is not available
     * in this building - will switch to the lowest above ground level.
     * Returns both: the next level key and the next building key
     */
    getNextBuildingKey(currentLevelKey, currentBuildingKey, offset) {
        const buildingsOrder = this.structure.buildingsOrder;
        if (!buildingsOrder)
            return null;
        const currentIndex = buildingsOrder.indexOf(currentBuildingKey);
        if (currentIndex === -1)
            return null;
        const nextBuildingKey = buildingsOrder[currentIndex + offset];
        if (!nextBuildingKey)
            return null;
        let nextLevelKey = currentLevelKey;
        if (!this.structure.buildings[nextBuildingKey].levels[currentLevelKey]) {
            // If next building is missing current active level, then switch to the lowest ground level
            const levelsOrder = this.structure.buildings[nextBuildingKey].levelsOrder;
            nextLevelKey = levelsOrder.find((levelKey) => parseFloat(levelKey) >= 0) || levelsOrder[0];
        }
        return { buildingKey: nextBuildingKey, levelKey: nextLevelKey };
    }
    /** Get building center offset in world coordinates */
    getBuildingOffset(buildingKey) {
        var _a;
        return ((_a = this.structure.buildings[buildingKey]) === null || _a === void 0 ? void 0 : _a.center) || null;
    }
    /** Check boundary for level switch */
    getLevelBoundary(currentBuildingKey, currentLevelKey) {
        var _a;
        const levelsOrder = (_a = this.structure.buildings[currentBuildingKey]) === null || _a === void 0 ? void 0 : _a.levelsOrder;
        return NavigationServiceBase.getKeyBoundary(currentLevelKey, levelsOrder);
    }
    createSceneGraph(scenes) {
        if (this.sceneGraph)
            return this.sceneGraph;
        const graph = new Map();
        if (Object.keys(scenes).length < 2)
            return graph;
        // Initialize graph with all scenes containing hotspots
        Object.entries(scenes).forEach(([key, scene]) => {
            var _a;
            if ((_a = scene.hotSpots) === null || _a === void 0 ? void 0 : _a.length)
                graph.set(key, []);
        });
        Object.entries(scenes).forEach(([sceneKey, sceneData]) => {
            const sceneNode = graph.get(sceneKey);
            if (!sceneData.hotSpots || !sceneNode)
                return;
            for (let i = 0; i < sceneData.hotSpots.length; i += 1) {
                const targetKey = sceneData.hotSpots[i].target;
                // Add only if target key is in the graph
                if (graph.get(targetKey))
                    sceneNode.push(targetKey);
            }
        });
        this.sceneGraph = graph;
        return graph;
    }
    static searchSceneGraph(graph, start, finishFunction) {
        const visited = {};
        const queue = [[start]];
        while (queue.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const path = queue.shift();
            const node = path[path.length - 1];
            // TODO: in some cases node is undefined, need to investigate
            if (node && finishFunction(node)) {
                return path;
            }
            if (!visited[node]) {
                visited[node] = true;
                const neighbors = graph.get(node);
                if (neighbors) {
                    neighbors.forEach((neighbor) => {
                        if (!visited[neighbor]) {
                            const newPath = [...path, neighbor];
                            queue.push(newPath);
                        }
                    });
                }
            }
        }
        const allKeys = Array.from(graph.keys());
        // no path found, just take first scene that matches building/floor/outside
        for (let i = 0; i < allKeys.length; i += 1) {
            const key = allKeys[i];
            if (finishFunction(key)) {
                return [start, key];
            }
        }
        return null; // No path found, this should never happen
    }
}
export default class NavigationService extends MakeReactive(NavigationServiceBase, [
    'isEngineReady',
    'levelKey',
    'buildingKey',
    'imageUrl',
    'imageType',
    'floorPlanError',
    'floorPlanLoaded',
    'levelBoundary',
    'showOutsideSwitch',
    'activeScene',
    'activeSubScene',
    'transitionInProgress',
    'tourConfigStructureType',
]) {
}
