import type { AssetConfig, BlurMaskData, SceneConfig } from '@g360/vt-types';

import { STARTING_EDITOR_BLUR } from '../../common/Globals';
import Utils from '../../common/Utils';
import getColorCorrectionUniformSettings from '../../common/Utils/getColorCorrectionUniformSettings';
import {
  createTexture,
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { ColorCorrectionUniformSettings, ProgramName } from '../../types/internal';
import fragmentShaderSource from './blur.fs.glsl';
import vertexShaderSource from './blur.vs.glsl';
import Blurrer from './Blurrer';

class BlurProgram {
  name: ProgramName;
  orderIndex = 0;

  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);

  visibleA = true; // should the blurs be rendered | toggled by transition
  visibleB = true; // | toggled by editor

  colorCorrectionUniformSettings: ColorCorrectionUniformSettings;

  private program: WebGLProgram | null = null;

  private equirectImageCache: Record<string, HTMLImageElement> = {}; // path => image
  private blurrers = new Map<string, Blurrer>(); // one for each scene
  private lastBlurIntensity = STARTING_EDITOR_BLUR; // gets stolen from last updated blur mask (blur intensities may differ per scene)
  private blurPatchImageData?: ImageData;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;

  private vertexBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private aspectRatioUniformLocation: WebGLUniformLocation | null = null;
  private yawUniformLocation: WebGLUniformLocation | null = null;
  private pitchUniformLocation: WebGLUniformLocation | null = null;
  private fovUniformLocation: WebGLUniformLocation | null = null;
  private alphaUniformLocation: WebGLUniformLocation | null = null;
  private textureObject: WebGLTexture | null = null;

  private colorCorrectionMatrixUniformLocation: WebGLUniformLocation | null = null;
  private colorCorrectionOffsetUniformLocation: WebGLUniformLocation | null = null;
  private colorBalanceVectorUniformLocation: WebGLUniformLocation | null = null;
  private shadowsUniformLocation: WebGLUniformLocation | null = null;
  private highlightsUniformLocation: WebGLUniformLocation | null = null;

  private textureLoaded = false;
  private sceneConfig?: SceneConfig;
  private ready = false;
  private alpha = 1.0;
  private yawOffset = 0;
  private readonly vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private doBlursWhenReady: (() => void) | null = null;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'BlurProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureObject = createTexture(this.gl);

    this.colorCorrectionUniformSettings = getColorCorrectionUniformSettings();
  }

  private get visible(): boolean {
    return this.visibleA && this.visibleB;
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');

      this.aspectRatioUniformLocation = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.yawUniformLocation = this.gl.getUniformLocation(this.program, 'u_yaw');
      this.pitchUniformLocation = this.gl.getUniformLocation(this.program, 'u_pitch');
      this.fovUniformLocation = this.gl.getUniformLocation(this.program, 'u_fov');
      this.alphaUniformLocation = this.gl.getUniformLocation(this.program, 'u_alpha');

      this.vertexAttributes = [this.vertexAttribute];

      this.colorCorrectionMatrixUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorCorrectionMatrix');
      this.colorCorrectionOffsetUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorCorrectionOffset');
      this.colorBalanceVectorUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorBalance');
      this.shadowsUniformLocation = this.gl.getUniformLocation(this.program, 'u_shadows');
      this.highlightsUniformLocation = this.gl.getUniformLocation(this.program, 'u_highlights');
    }

    this.ready = true;
  }

  async setSceneConfig(sceneConfig: SceneConfig, assetPath: string, assetConfig: AssetConfig): Promise<void> {
    return new Promise((resolve) => {
      this.sceneConfig = sceneConfig;
      this.yawOffset = Utils.toRad(sceneConfig.camera[3]);

      if (!this.equirectImageCache[sceneConfig.sceneKey]) {
        this.renderer.createEquirectForPano(assetPath, assetConfig).then((equirectImage) => {
          this.equirectImageCache[sceneConfig.sceneKey] = equirectImage;
          this.changeBlur(true);
          if (this.doBlursWhenReady) {
            this.doBlursWhenReady();
            this.doBlursWhenReady = null;
          }
          resolve();
        });
      } else {
        this.changeBlur(true);
        if (this.doBlursWhenReady) {
          this.doBlursWhenReady();
          this.doBlursWhenReady = null;
        }
        resolve();
      }
    });
  }

  setBlurMasksAndRegenerateBlurs(
    sceneKey: string,
    intensity: number,
    masks: BlurMaskData[],
    highQuality: boolean
  ): void {
    this.lastBlurIntensity = intensity;
    if (!this.blurrers[sceneKey]) {
      // Blurs are not yet ready. Do this when they are:
      // console.log("setBlurMasksAndRegenerateBlurs::later");
      this.doBlursWhenReady = () => {
        setTimeout(() => {
          // console.log("setBlurMasksAndRegenerateBlurs::later is now");
          this.setBlurMasksAndRegenerateBlurs(sceneKey, intensity, masks, highQuality);
        }, 100);
      };
      return;
    }

    // console.log("setBlurMasksAndRegenerateBlurs::yes");
    (this.blurrers[sceneKey] as Blurrer).setBlurMasks(intensity, masks);
    this.changeBlur(highQuality);
  }

  getBlurMaskPngDataUrl(sceneKey: string, width: number): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.blurrers[sceneKey]) {
        reject(new Error('Mask unavailable, scene was not open in editor'));
        return;
      }

      (this.blurrers[sceneKey] as Blurrer).makeComboMask(width).then((pngUrl: string) => {
        resolve(pngUrl);
      });
    });
  }

  getBlurMaskPngBlob(sceneKey: string, width: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (!this.blurrers[sceneKey]) {
        reject(new Error('Mask unavailable, scene was not open in editor'));
        return;
      }

      (this.blurrers[sceneKey] as Blurrer).makeComboMaskBlob(width).then((pngBlob: Blob) => {
        resolve(pngBlob);
      });
    });
  }

  // change to the current pano & corresponding blurmask
  changeBlur(highQuality: boolean): void {
    this.textureLoaded = false;

    const currentSceneKey = this.sceneConfig?.sceneKey || '';
    const equirectImg = this.equirectImageCache[currentSceneKey];
    if (!equirectImg) return;

    // if first time make blurs for current pano
    if (!this.blurrers[currentSceneKey]) {
      this.blurrers[currentSceneKey] = new Blurrer(equirectImg);
      this.blurrers[currentSceneKey].makeComboBlur(highQuality).then((imgData) => {
        this.onBlurDone(imgData);
      });
    } else {
      // pano already have blurs, just use latest blur mask
      this.blurrers[currentSceneKey]
        .makeComboBlur(highQuality)
        .then((imgData) => {
          this.onBlurDone(imgData);
        })
        .catch((err) => {
          console.log(`B)blurProgram::changeBlur:${err}`);
        });
    }
  }

  onBlurDone(blurImageData: ImageData): void {
    this.blurPatchImageData = blurImageData;
    this.renderer.render();

    setTimeout(() => {
      this.renderer.render();
    }, 32); // safari is sometimes slow to render the first time
  }

  destroy(): void {
    this.gl.deleteTexture(this.textureObject);
    destroyProgram(this.gl, this.program);
  }

  render(): void {
    if (!this.visible || !this.gl || !this.ready || this.renderer.isInTransition) return;
    this.draw();
  }

  private draw(): void {
    if (!this.textureObject) return;

    if (!this.textureLoaded) {
      const imageData = this.blurPatchImageData;

      if (!imageData || imageData.width <= 0 || imageData.height <= 0) return;

      this.gl.activeTexture(this.gl.TEXTURE0);
      setTextureFromImage(this.gl, this.textureObject, imageData, { useAlphaChannel: true });

      this.textureLoaded = true;
    }

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.uniformMatrix4fv(
      this.colorCorrectionMatrixUniformLocation,
      false,
      this.colorCorrectionUniformSettings.colorCorrectionMatrix
    );
    this.gl.uniform4fv(
      this.colorCorrectionOffsetUniformLocation,
      this.colorCorrectionUniformSettings.colorCorrectionOffset
    );
    this.gl.uniform3fv(this.colorBalanceVectorUniformLocation, this.colorCorrectionUniformSettings.colorBalanceVector);
    this.gl.uniform1f(this.shadowsUniformLocation, this.colorCorrectionUniformSettings.shadows);
    this.gl.uniform1f(this.highlightsUniformLocation, this.colorCorrectionUniformSettings.highlights);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.aspectRatioUniformLocation, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniformLocation, this.yaw + this.yawOffset);
    this.gl.uniform1f(this.pitchUniformLocation, this.pitch);
    this.gl.uniform1f(this.fovUniformLocation, this.fov);

    this.gl.uniform1f(this.alphaUniformLocation, this.alpha);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default BlurProgram;
