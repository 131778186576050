import type { Degree } from '@g360/vt-types';

import { enableVertexAttributes } from '../../common/webglUtils/attributeUtils';
import { initShaders } from '../../common/webglUtils/programShaderUtils';
import type { CubeMapSides } from '../../types/internal';
import fragmentShaderSource from './cube2Equirect.fs.glsl';
import vertexShaderSource from './cube2Equirect.vs.glsl';
import setCubeMapTextures from './setCubeMapTextures';

/**
 * Convert tiled cubemap images to a single equirectangular image
 *  @todo there is no need for the complex RAF loop, this can be changed to render when convert is called
 */
class Cube2Equirect {
  private program: WebGLProgram | null = null;

  private efSqrt = false;
  private efPow = false;
  private stopMov = false;
  private sphereMap = false;
  private readonly width = 512 * 4;
  private readonly height = 512 * 2;
  private downR: Degree = 180;
  private upR: Degree = 180;
  private frontR: Degree = 0;
  private backR: Degree = 0;
  private rightR: Degree = 0;
  private leftR: Degree = 0;
  private canvasTemp: HTMLCanvasElement;
  private waitForTexturesToLoad = false;
  private leftImage?: HTMLImageElement;
  private rightImage?: HTMLImageElement;
  private upImage?: HTMLImageElement;
  private downImage?: HTMLImageElement;
  private frontImage?: HTMLImageElement;
  private backImage?: HTMLImageElement;
  private canvas?: HTMLCanvasElement;
  private gl: WebGL2RenderingContext | WebGLRenderingContext | null;
  private cubemapTexture: WebGLTexture | null = null;
  private afterDrawFrame = false;
  private isDrawRequested = false;

  private resolutionUniformLocation: WebGLUniformLocation | null = null;
  private efSqrtUniformLocation: WebGLUniformLocation | null = null;
  private efPowUniformLocation: WebGLUniformLocation | null = null;
  private stopMovUniformLocation: WebGLUniformLocation | null = null;
  private sphereMapUniformLocation: WebGLUniformLocation | null = null;
  private cubemapUniformLocation: WebGLUniformLocation | null = null;
  private isRunning = false;

  private positionAttributeLocation = 0;

  private vertexAttributes: number[] = [];

  private onCreated?: (image: HTMLImageElement) => void;

  constructor() {
    this.canvasTemp = document.createElement('canvas');
    this.gl = null;

    this.renderLoop = this.renderLoop.bind(this);

    this.initializeWebGL();
    this.initializeShader();
    this.launchWebGL();
  }

  /** Stops the RAF loop - no need to keep it running all the time */
  public stop(): void {
    this.isRunning = false;
  }

  /** Starts the RAF loop */
  public start(): void {
    this.isRunning = true;
    this.renderLoop();
  }

  public async convert(comboImages: HTMLImageElement[], onCreated: (image: HTMLImageElement) => void): Promise<void> {
    this.start();

    const onDone = () => {
      this.waitForTexturesToLoad = true;
      this.setTextures(comboImages);
      this.onCreated = onCreated;
    };

    let numWaiting = comboImages.length;
    for (let i = 0; i < comboImages.length; i += 1) {
      if (comboImages[i].complete) {
        numWaiting -= 1; // tick off already loaded images
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-loop-func
      comboImages[i].addEventListener('load', () => {
        numWaiting -= 1;
        if (numWaiting === 0) {
          // when all images have finished loading
          onDone();
        }
      });
    }

    // all images were already loaded
    if (numWaiting === 0) onDone();
  }

  private initializeWebGL(): void {
    this.canvas = document.createElement('canvas');
    this.gl = this.canvas.getContext('webgl2');
    if (!this.gl) {
      this.gl = this.canvas.getContext('webgl');
    }
  }

  private initializeShader(): void {
    if (!this.gl) return;

    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (!this.program) return;
    this.resolutionUniformLocation = this.gl.getUniformLocation(this.program, 'u_resolution');
    this.efSqrtUniformLocation = this.gl.getUniformLocation(this.program, 'ef_sqrt');
    this.efPowUniformLocation = this.gl.getUniformLocation(this.program, 'ef_pow');
    this.stopMovUniformLocation = this.gl.getUniformLocation(this.program, 'stop_mov');
    this.sphereMapUniformLocation = this.gl.getUniformLocation(this.program, 'sphere_map');
    this.cubemapUniformLocation = this.gl.getUniformLocation(this.program, 'u_cubemap1');

    this.positionAttributeLocation = this.gl.getAttribLocation(this.program, 'a_Position');

    this.vertexAttributes = [this.positionAttributeLocation];
  }

  private setupCubeMap(): boolean {
    if (!this.gl) return false;

    // are all pics loaded
    if (!this.leftImage || !this.rightImage) return false;
    if (!this.frontImage || !this.backImage) return false;
    if (!this.upImage || !this.downImage) return false;

    // are all pics square
    if (
      this.leftImage.width !== this.rightImage.width ||
      this.rightImage.width !== this.upImage.width ||
      this.upImage.width !== this.downImage.width ||
      this.downImage.width !== this.backImage.width ||
      this.backImage.width !== this.frontImage.width
    ) {
      // eslint-disable-next-line no-console
      console.error('Cube2Equirect::setupCubeMap:images are supposed to be square');
      return false;
    }

    const cubeMapSides: CubeMapSides = {
      left: {
        source: this.leftImage,
        rotationAngle: this.leftR,
      },
      right: {
        source: this.rightImage,
        rotationAngle: this.rightR,
      },
      up: {
        source: this.upImage,
        rotationAngle: this.upR,
      },
      down: {
        source: this.downImage,
        rotationAngle: this.downR,
      },
      front: {
        source: this.frontImage,
        rotationAngle: this.frontR,
      },
      back: {
        source: this.backImage,
        rotationAngle: this.backR,
      },
    };

    // Delete the existing texture if it exists
    if (this.cubemapTexture) {
      this.gl.deleteTexture(this.cubemapTexture);
    }

    // Create a new texture for the cube map
    this.cubemapTexture = this.gl.createTexture();

    if (!this.cubemapTexture) {
      // eslint-disable-next-line no-console
      console.error('Failed to create cubemap texture');
      return false;
    }

    setCubeMapTextures(this.gl, this.canvasTemp, this.cubemapTexture, { cubeMapSides, useAlphaChannel: true });

    return true;
  }

  private setTextures(comboImages: HTMLImageElement[]): void {
    // ['f', 'b', 'u', 'd', 'l', 'r'];
    //   0    1    2    3    4    5
    this.leftImage = comboImages[4];
    this.rightImage = comboImages[5];
    this.upImage = comboImages[2];
    this.downImage = comboImages[3];
    this.frontImage = comboImages[0];
    this.backImage = comboImages[1];
  }

  private launchWebGL(): void {
    if (!this.gl || !this.program) return;

    const vertices = new Float32Array([-1.0, 1.0, 0.0, -1.0, -1.0, 0.0, 1.0, -1.0, 0.0, 1.0, 1.0, 0.0]);
    const indices = [0, 1, 2, 2, 3, 0];

    const vertexBuffer = this.gl.createBuffer();
    const indexBuffer = this.gl.createBuffer();

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), this.gl.STATIC_DRAW);

    this.gl.viewport(0, 0, this.width, this.height);

    this.gl.vertexAttribPointer(this.positionAttributeLocation, 3, this.gl.FLOAT, false, 0, 0);

    enableVertexAttributes(this.gl, this.vertexAttributes);
  }

  private renderLoop(): void {
    if (!this.isRunning) return;

    this.renderLoopBase();
    requestAnimationFrame(this.renderLoop);
  }

  private renderLoopBase(): void {
    if (!this.gl || !this.program) return;
    if (!this.canvas) return;

    if (this.isDrawRequested) {
      this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
      this.gl.clear(this.gl.COLOR_BUFFER_BIT);

      this.gl.useProgram(this.program);

      this.gl.uniform2f(this.resolutionUniformLocation, this.width, this.height);
      this.gl.uniform1i(this.efSqrtUniformLocation, this.efSqrt ? 1 : 0);
      this.gl.uniform1i(this.efPowUniformLocation, this.efPow ? 1 : 0);
      this.gl.uniform1i(this.stopMovUniformLocation, this.stopMov ? 1 : 0);
      this.gl.uniform1i(this.sphereMapUniformLocation, this.sphereMap ? 1 : 0);

      // gl.activeTexture(this.gl.TEXTURE0);
      this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, this.cubemapTexture);
      this.gl.uniform1i(this.gl.getUniformLocation(this.program, 'u_cubemap1'), 0);

      this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
    }

    if (this.afterDrawFrame) {
      this.saveImage();
      this.afterDrawFrame = false;
      this.isDrawRequested = false;
      this.gl.viewport(0, 0, this.width, this.height);
      this.sphereMap = false;
      // console.log("RENDER:save image");
    }

    if (this.isDrawRequested) {
      this.canvas.height = this.height;
      this.canvas.width = this.width;
      this.gl.viewport(0, 0, this.width, this.height);
      this.afterDrawFrame = true;
      this.sphereMap = false;
      // console.log("RENDER:just some draw");
    }

    if (!this.waitForTexturesToLoad) return;

    if (!this.leftImage || !this.rightImage) return;
    if (!this.frontImage || !this.backImage) return;
    if (!this.upImage || !this.downImage) return;

    this.gl.deleteTexture(this.cubemapTexture);

    const isCubeMapSetup = this.setupCubeMap();

    if (!isCubeMapSetup) {
      // eslint-disable-next-line no-console
      console.warn("setupCubeMap failed, let's try again later");
      return;
    }

    this.isDrawRequested = true;
    this.waitForTexturesToLoad = false;
  }

  private saveImage(): void {
    if (!this.canvas || !this.onCreated) return;

    const dataURL = this.canvas.toDataURL('image/jpg');
    const imageElement = document.createElement('img');
    imageElement.src = dataURL;
    this.onCreated(imageElement);
  }
}

export default Cube2Equirect;
