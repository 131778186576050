import { jsx as _jsx } from "react/jsx-runtime";
import useContrastChecker from '../../../hooks/useContrastChecker';
import ArrowIcon from '../icons/ArrowIcon';
const WelcomeButton = ({ className, iconStyle, onClick }) => {
    const darkBias = useContrastChecker();
    // Pick the stroke color based on the dark bias, 0.3 is an arbitrary threshold but it seems to work well
    const twStroke = darkBias > 0.3 ? 'stroke-modal-dark' : 'stroke-modal-light';
    return (_jsx("button", { className: `flex h-full items-center justify-center rounded-full bg-theme-accent ${className}`, type: "button", "data-testid": "toggle-welcome-screen", onClick: onClick, children: _jsx(ArrowIcon, { style: iconStyle, className: `${twStroke}` }) }));
};
export default WelcomeButton;
