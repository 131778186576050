var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MakeReactive, ReactiveBase } from '@g360/vt-utils/';
class LayoutServiceBase extends ReactiveBase {
    constructor(canvasElem, appContext) {
        super();
        /** Minimum safe size for the layout to be visible, this was handled by css
         * but it makes more sense to handle it by js so there is no confusion.
         * This may later changed based on the layout but currently it is the same for all.
         * Currently based on MiniMapMenu size - all buttons, margins and paddings included
         */
        this.minSafeSize = { width: 320, height: 240 };
        /** Controls info modal open/close state, separate from the CTA input view state */
        this.showInfoModal = false;
        /** Controls CTA modal open/close state, separate from the infoModal view state */
        this.showCTA = false;
        /** Controls minimap navigation open/close state */
        this.isNavigationOpen = false;
        /** Controls utility menu open/close state */
        this.isUtilityMenuOpen = false;
        /** Controls if utility menu should show the toggle button and is collapsible */
        this.isUtilityMenuCollapsible = false;
        /** Controls welcome screen open/close state, when false */
        this.isWelcomeScreenOpen = true;
        /** Needed to synchronize canvas animation with welcome screen animations */
        this.isWelcomeScreenClosing = false;
        /** Tutorial animation enabled when player is idle for some time */
        this.showTutorial = false;
        /** Tutorial animation enabled when user first enters measure mode */
        this.showMeasureTutorial = false;
        /** Notification animation for info button */
        this.showInfoModalIndicator = false;
        /** Controls measure disclaimer modal open/close state */
        this.showMeasureDisclaimerModal = false;
        /** Used to disable buttons or any input when in guided viewing receiver mode */
        this.isRemoteControlled = false;
        /** Currently active toast message from the list or null */
        this.activeToast = null;
        /** Controls toast message open/close state, this is needed for proper animations */
        this.showToast = false;
        /** The message that is shown when the watermark is interrupted. Change on language set/change */
        this.watermarkInterruptedMessage = 'WATERMARK_INTERRUPTED';
        /** The message that is shown when there was an error initializing measure mode */
        this.measureModeInitErrorMessage = 'MEASURE_TOOL_INIT_FAILED';
        /** The message that is shown when a user tries to start a measurement from a missing data point */
        this.measureModeMissingDataMessage = 'TOAST_MISSING_DATA';
        /** Controls gated tour modal open/close state. Does nothing if `features.gatedTour` or `tourConfig.gatedTourEnabled` are `false`. */
        this.isGatedTourModalOpen = false;
        this.measureMode = false;
        this.measureModePlatform = 'desktop';
        /** Counter incremented every second, reset on scene move */
        this.idleSceneTimer = null;
        this.notificationTimer = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.engineSubscriptions = [];
        this.engine = null;
        this.toasts = [];
        this.toastTimer = null;
        this.notificationDismissed = false;
        this.tutorialDismissed = false;
        this.measureTutorialDismissed = false;
        this.setMeasureMode = (mode) => __awaiter(this, void 0, void 0, function* () {
            if (!this.engine)
                return;
            this.measureMode = mode;
            const platform = yield this.engine.toggleMeasureMode(mode);
            if (platform)
                this.measureModePlatform = platform;
            if (mode) {
                if (!this.measureTutorialDismissed)
                    this.showMeasureTutorial = true;
            }
            else {
                this.showMeasureTutorial = false;
            }
        });
        this.toggleNavigation = (forcedState) => {
            this.isNavigationOpen = forcedState !== null && forcedState !== void 0 ? forcedState : !this.isNavigationOpen;
        };
        /** Toggles the utility menu open/close state (currently used only in idealista layout) */
        this.toggleUtilityMenu = (forcedState) => {
            this.isUtilityMenuOpen = forcedState !== null && forcedState !== void 0 ? forcedState : !this.isUtilityMenuOpen;
        };
        /** Sets the utility menu collapsible state
         * If this menu has enough space we just want to show the children buttons
         * without the menu toggle button. Currently only in idealista layout this
         * menu is collapsible as it interferes with the minimap menu.
         */
        this.setUtilityMenuCollapsible = (collapsible) => {
            this.isUtilityMenuCollapsible = collapsible;
        };
        this.resetNotificationTimer = () => {
            if (this.notificationTimer)
                clearTimeout(this.notificationTimer);
            this.showInfoModalIndicator = false;
        };
        this.addToastMessage = (message, options) => {
            var _a;
            const opts = Object.assign({ timeout: 3000, persistent: false, unique: true }, options);
            if (opts === null || opts === void 0 ? void 0 : opts.unique) {
                if (this.toasts.find((toast) => toast.message === message))
                    return;
                if (this.showToast && ((_a = this.activeToast) === null || _a === void 0 ? void 0 : _a.message) === message)
                    return;
            }
            this.toasts.push(Object.assign({ message }, opts));
            if (!this.showToast)
                this.showNextToast();
        };
        this.dismissToastMessage = () => {
            if (this.toastTimer)
                clearTimeout(this.toastTimer);
            this.showToast = false;
            setTimeout(() => {
                this.showNextToast();
            }, 1000);
        };
        this.removeToastMessage = (message) => {
            var _a;
            this.toasts = this.toasts.filter((toast) => toast.message !== message);
            if (((_a = this.activeToast) === null || _a === void 0 ? void 0 : _a.message) === message)
                this.dismissToastMessage();
        };
        this.dismissNotification = () => {
            if (this.notificationTimer)
                clearTimeout(this.notificationTimer);
            this.notificationDismissed = true;
            this.showInfoModalIndicator = false;
        };
        this.dismissTutorial = () => {
            var _a;
            if (this.idleSceneTimer)
                clearTimeout(this.idleSceneTimer);
            // If dev panel is enabled, don't remove the subscription, otherwise it will break the dismiss on interaction
            if (process.env.REACT_APP_DEV_PANEL !== 'true') {
                (_a = this.engineSubscriptions[0]) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            }
            this.tutorialDismissed = true;
            this.showTutorial = false;
        };
        this.startCanvasAnimation = () => {
            var _a;
            if (this.isWelcomeScreenOpen) {
                this.handleCanvasAnimation();
                (_a = this.engine) === null || _a === void 0 ? void 0 : _a.setHotSpotsDisabled(true);
            }
        };
        this.startIdleSceneTimer = () => {
            this.idleSceneTimer = setTimeout(() => {
                if (this.isRemoteControlled || this.tutorialDismissed)
                    return;
                this.showTutorial = true;
            }, 10000);
        };
        this.startNotificationTimer = () => {
            // Info Button CTA notification indicator
            this.notificationTimer = setTimeout(() => {
                this.showInfoModalIndicator = true;
            }, 30000);
        };
        this.showNextToast = () => {
            var _a, _b;
            if (!this.toasts.length)
                return;
            this.activeToast = (_a = this.toasts.shift()) !== null && _a !== void 0 ? _a : null;
            this.showToast = true;
            if ((_b = this.activeToast) === null || _b === void 0 ? void 0 : _b.persistent)
                return;
            if (!this.activeToast)
                return;
            this.toastTimer = setTimeout(() => {
                this.showToast = false;
                setTimeout(() => {
                    this.showNextToast();
                }, 1000);
            }, this.activeToast.timeout || 3000);
        };
        this.handleCanvasAnimation = () => {
            if (!document || !window)
                return;
            const styleElement = document.createElement('style');
            let canvasResetTimeoutFromAnimationStart;
            let canvasResetTimeout;
            // Inject additional class names in lib context for canvas parent element so that styles are properly applied
            if (this.appContext !== 'standalone') {
                const canvasParent = this.canvas.parentNode;
                if (canvasParent instanceof HTMLElement) {
                    canvasParent.classList.add('vt-player-lib');
                    canvasParent.classList.add('overflow-hidden');
                }
            }
            const canvasResetTimeoutCallback = () => {
                var _a, _b;
                (_a = this.engine) === null || _a === void 0 ? void 0 : _a.onResize();
                const controller = (_b = this.engine) === null || _b === void 0 ? void 0 : _b.getController();
                controller === null || controller === void 0 ? void 0 : controller.connect();
                if (styleElement.parentNode) {
                    styleElement.parentNode.removeChild(styleElement);
                }
                if (this.canvas) {
                    this.canvas.style.animation = '';
                }
                if (canvasResetTimeout) {
                    clearTimeout(canvasResetTimeout);
                }
                if (canvasResetTimeoutFromAnimationStart) {
                    clearTimeout(canvasResetTimeoutFromAnimationStart);
                }
            };
            const animationStartHandler = () => {
                var _a;
                const controller = (_a = this.engine) === null || _a === void 0 ? void 0 : _a.getController();
                controller === null || controller === void 0 ? void 0 : controller.disconnect();
                // Works as a fallback in case animationend event is not triggered
                canvasResetTimeoutFromAnimationStart = setTimeout(canvasResetTimeoutCallback, 1010);
                this.canvas.removeEventListener('animationstart', animationStartHandler);
            };
            // After the canvas animation is finished, we need to call onResize to make sure
            // the engine is working with the correct canvas size and clean up timer, event listener and style element
            const animationEndHandler = (evt) => {
                var _a, _b;
                if (evt.animationName === 'canvas-reset') {
                    (_a = this.engine) === null || _a === void 0 ? void 0 : _a.onResize();
                    const controller = (_b = this.engine) === null || _b === void 0 ? void 0 : _b.getController();
                    controller === null || controller === void 0 ? void 0 : controller.connect();
                    // Clear the fallback timeout
                    if (canvasResetTimeout) {
                        clearTimeout(canvasResetTimeout);
                    }
                }
                if (this.canvas) {
                    this.canvas.removeEventListener('animationend', animationEndHandler);
                    this.canvas.style.animation = '';
                }
                if (styleElement.parentNode) {
                    styleElement.parentNode.removeChild(styleElement);
                }
            };
            this.watch(() => {
                if (!this.canvas || typeof window === 'undefined')
                    return;
                if (!this.isWelcomeScreenClosing) {
                    this.canvas.classList.add('animate-canvas-swing');
                    return;
                }
                if (this.isWelcomeScreenOpen) {
                    return;
                }
                this.canvas.addEventListener('animationstart', animationStartHandler);
                this.canvas.addEventListener('animationend', animationEndHandler);
                // In order to smoothly transition from the canvas-swing animation to the canvas-reset animation,
                // we need to dynamically construct the canvas-reset animation with the current translateX value
                const transform = window.getComputedStyle(this.canvas).getPropertyValue('transform');
                const matrixValues = transform.match(/matrix.*\((.+)\)/);
                let translateX = 0;
                if (matrixValues && matrixValues.length > 1) {
                    const matrix = matrixValues[1].split(', ');
                    translateX = parseFloat(matrix[4]);
                }
                styleElement.innerHTML = `
        @keyframes canvas-reset {
          0% {transform: translateX(${translateX}px) scale(1.5);}
          100% {transform: translateX(0px) scale(1);}
        }
      `;
                this.canvas.classList.remove('animate-canvas-swing');
                document.head.appendChild(styleElement);
                this.canvas.style.animation = 'canvas-reset 1s ease-in-out forwards';
                // Just in case the animationend event is not triggered and animationstart is not called
                canvasResetTimeout = setTimeout(canvasResetTimeoutCallback, 1210);
            });
        };
        this.canvas = canvasElem;
        this.appContext = appContext;
    }
    setEngine(engine) {
        this.engine = engine;
        this.initEngineListeners(this.engine);
    }
    init() {
        if (this.appContext !== 'standalone') {
            // Tutorial and notification are available only in standalone context
            this.dismissTutorial();
            this.dismissNotification();
        }
        this.initGuidedViewing();
        this.watch(() => {
            var _a, _b, _c;
            if (!this.isWelcomeScreenOpen) {
                const tourConfigService = (_a = this.engine) === null || _a === void 0 ? void 0 : _a.getTourConfigService();
                const ctaAvailable = (_c = (_b = tourConfigService === null || tourConfigService === void 0 ? void 0 : tourConfigService.projectDataConfig) === null || _b === void 0 ? void 0 : _b.contactFormFields) === null || _c === void 0 ? void 0 : _c.length;
                if (!this.notificationDismissed && ctaAvailable)
                    this.startNotificationTimer();
                if (!this.tutorialDismissed)
                    this.startIdleSceneTimer();
            }
        });
        this.watch(() => {
            var _a;
            const open = this.isWelcomeScreenOpen;
            (_a = this.engine) === null || _a === void 0 ? void 0 : _a.setHotSpotsDisabled(open);
        });
    }
    initGuidedViewing() {
        var _a, _b;
        let remoteSyncReady = false;
        const remoteSyncReadyHandler = () => {
            var _a, _b, _c;
            if (remoteSyncReady)
                return;
            remoteSyncReady = true;
            // Receive
            (_a = this.engine) === null || _a === void 0 ? void 0 : _a.subscribe('ui.layout.remoteUpdate', (payload) => {
                if (payload.showCTA !== undefined)
                    this.showCTA = payload.showCTA;
                if (payload.showInfoModal !== undefined)
                    this.showInfoModal = payload.showInfoModal;
                if (payload.isNavigationOpen !== undefined)
                    this.isNavigationOpen = payload.isNavigationOpen;
            });
            // Transmit
            this.watch(() => {
                var _a;
                const event = {
                    showCTA: this.showCTA,
                    showInfoModal: this.showInfoModal,
                    isNavigationOpen: this.isNavigationOpen,
                    source: 'ui-layout',
                };
                (_a = this.engine) === null || _a === void 0 ? void 0 : _a.emitGuidedViewingUiLayoutEvent(event);
            });
            // Handle remote control switching
            (_b = this.engine) === null || _b === void 0 ? void 0 : _b.subscribe('tour.eventSource.change', (mode) => {
                this.isRemoteControlled = mode === 'RemoteHost';
            });
            this.isRemoteControlled = ((_c = this.engine) === null || _c === void 0 ? void 0 : _c.getTourEventSource()) === 'RemoteHost';
        };
        if ((_a = this.engine) === null || _a === void 0 ? void 0 : _a.isInGuidedViewing()) {
            remoteSyncReadyHandler();
        }
        (_b = this.engine) === null || _b === void 0 ? void 0 : _b.subscribe('remoteSyncReady', remoteSyncReadyHandler);
    }
    initEngineListeners(engine) {
        if (this.engineSubscriptions.length) {
            this.engineSubscriptions.forEach((subscription) => subscription.unsubscribe());
            this.engineSubscriptions = [];
        }
        this.engineSubscriptions = [
            engine.subscribe('scene.interaction.update', () => {
                this.dismissTutorial();
            }),
            engine.subscribe('error.set', (errorType) => {
                if (errorType === 'WATERMARK_INTERRUPTED') {
                    this.addToastMessage(this.watermarkInterruptedMessage);
                }
                else if (errorType === 'MEASURE_TOOL_INIT_FAILED') {
                    // Error while initializing measure tool, we display a toast message and toggle measure mode off
                    this.addToastMessage(this.measureModeInitErrorMessage);
                    this.setMeasureMode(false);
                }
            }),
            engine.subscribe('error.clear', (errorType) => {
                if (errorType === 'WATERMARK_INTERRUPTED') {
                    this.removeToastMessage(this.watermarkInterruptedMessage);
                }
            }),
            engine.subscribe('measuretool.toast.missing_data', () => {
                this.addToastMessage(this.measureModeMissingDataMessage);
            }),
            engine.subscribe('measuretool.tutorial.dismiss', () => {
                this.measureTutorialDismissed = true;
                this.showMeasureTutorial = false;
            }),
        ];
        if (engine.watermarkInterrupted) {
            this.addToastMessage(this.watermarkInterruptedMessage);
        }
    }
}
export default class LayoutService extends MakeReactive(LayoutServiceBase, [
    'showInfoModal',
    'showCTA',
    'isNavigationOpen',
    'isWelcomeScreenOpen',
    'isWelcomeScreenClosing',
    'showTutorial',
    'showMeasureTutorial',
    'showInfoModalIndicator',
    'isRemoteControlled',
    'activeToast',
    'showToast',
    'measureMode',
    'measureModePlatform',
    'isUtilityMenuOpen',
    'isUtilityMenuCollapsible',
    'showMeasureDisclaimerModal',
    'isGatedTourModalOpen',
]) {
}
