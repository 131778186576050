import { Utils } from '@g360/vt-engine';
import { ErrorLogger, lerp, lerpPos, linearScale } from '@g360/vt-utils';
import isEqual from 'lodash/isEqual';
import FadeAnimator from './FadeAnimator';
import FloorPlanRenderer from './FloorPlanRenderer';
import PinsRenderer from './PinsRenderer';
import { getDistanceBetweenTouches } from './utils/misc';
class CanvasMiniMapRenderer {
    constructor(params) {
        /** Object is reused to avoid creating new ones every frame */
        this.fadeAnimationValues = {
            floorPlanOpacity: 1,
            activePinOpacity: 1,
            pinsOpacity: 1,
        };
        /** Object is reused to avoid creating new ones every frame */
        this.floorPlanRendererProps = {
            yaw: 0,
            scale: 0,
            floorPlanCenter: { x: 0, y: 0 },
            halfCanvasSizeDpr: 0,
            opacity: 1,
        };
        /** DPR: pixel ratio, will calculate on resize  */
        this.devicePixelRatio = 1;
        /** half canvas size / device pixel ratio = center of canvas in CSS pixels (not actual canvas pixels) */
        this.halfCanvasSizeInDpr = 0;
        this.lastFrameTime = 0;
        this.yaw = 0;
        /** more like "zoom", bigger number means more zoomed in */
        this.scale = 0.5;
        /** current scale is animated towards this value (it is changed by user input) */
        this.scaleTarget = 0.5;
        /** will be calculated on first floorPlan load accounting for floorPlan size and canvas size */
        this.actualMinScale = 0;
        this.actualMaxScale = 0;
        /** pixels in the floorPlan image */
        this.floorPlanCenteredAt = { x: 0, y: 0 };
        this.dragging = false;
        this.dragLastPos = { x: 0, y: 0 };
        this.forceRenderNextFrame = false;
        /** on what group mouse was pressed, to check if it's the same when released */
        this.lastPressedPinGroupIndex = -1;
        /** pan and scale animation */
        this.currentAnimation = undefined;
        this.lastCanvasSize = -Infinity;
        this.fadeAnimator = new FadeAnimator();
        /** reused wheel event for zoom smoothing  */
        this.wheelEvent = { deltaY: 0, clientX: 0, clientY: 0 };
        this.wheelAccumulatedDelta = 0;
        /** Fix for bug: when resizing window so that now minimap is visible (but wasn't before)
         * the resizer was called before the correct minimap parent size was calculated  */
        this.checkResizeOnNextRender = false;
        this.frameId = 0;
        this.engine = params.engine;
        this.assetPath = params.assetPath;
        this.canvas = params.canvas;
        this.ctx = params.ctx;
        this.analytics = params.analytics;
        this.navigationService = params.navigationService;
        this.gatedTourService = params.gatedTourService;
        this.getNextActivePinLabel = params.getNextActivePinLabel;
        this.handleSceneHover = params.handleSceneHover;
        this.onFloorPlanImageLoaded = params.onFloorPlanImageLoaded;
        this.onFloorPlanImageError = params.onFloorPlanImageError;
        this.setLoading = params.setLoading;
        this.init();
    }
    setFloorPlan(params) {
        // on first load, set data for new floorplan immediately
        // on subsequent loads, do it when old floorplan/pins are faded out by animation
        const firstTime = !this.floorPlanRenderer;
        if (firstTime) {
            this.actuallySetFloorPlan(params);
        }
        else {
            this.fadeAnimator.onFadeOutCompletedNewRenderers = () => {
                this.actuallySetFloorPlan(params);
                this.setLoading(true);
            };
        }
        this.lastAccentColor = params.accentColor;
        this.fadeAnimator.startAnimation(firstTime);
    }
    /**
     * Can't just change floorplan image willy-nilly, when Navigation service feels like it,
     * it must be changed, when fade-out animation is finished and fade-in animation is not yet started.
     * This function is called once for .svg floorPlans
     * and twice for .png floorPlans, (_optimized & _original)  <-- so the _original can be
     * changed at any time even not during faded out state (if user has slow internet)
     * so for users with fast internet connection, the second image will be loaded before the fadeOut is done\
     * (and this callback is called),
     * therefore only second image will be given to the renderer
     *
     * @note -- png floorPlans are for very old projects only
     * @note -- if something about _optimized/_original change, just remove the _optimized image from everywhere
     *          (including navigation service) since it's for very old projects only
     */
    setFloorPlanImage(imgUrlConfig, imageType) {
        // preload the image - this will be called around scene change
        fetch(imgUrlConfig[imageType])
            .then(this.onFloorPlanImageLoaded)
            .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            ErrorLogger.captureMessage(`Floor plan image download failed`);
        });
        if (this.fadeAnimator.animating) {
            // add it as callback, if animation is still running
            this.fadeAnimator.onFadeOutCompletedNewFPImage = () => {
                this.actuallySetFloorPlanImage(imgUrlConfig, imageType);
                this.setLoading(true);
            };
        }
        else {
            // we were too slow, the animation is already done (animated the _optimized image)
            // and we can change the image immediately
            this.actuallySetFloorPlanImage(imgUrlConfig, imageType);
            this.setLoading(true);
        }
    }
    updatePins(pins, newActivePinSceneKey) {
        var _a;
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.updatePins(pins, newActivePinSceneKey);
    }
    forceRender() {
        var _a;
        this.forceRenderNextFrame = true;
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.forceRender();
    }
    /**
     * Called on requestAnimationFrame
     */
    render() {
        if (!this.floorPlanRenderer || !this.pinsRenderer)
            return;
        this.frameId += 1;
        const now = performance.now();
        let deltaTime = now - this.lastFrameTime;
        this.lastFrameTime = now;
        if (deltaTime > 1000 / 30) {
            // if frame was too long (browser minimized, heavy loading, etc.), cap it
            // to avoid huge jumps in animation
            deltaTime = 1000 / 30;
        }
        this.onWheelRegular();
        this.yaw = this.engine.getYaw();
        const animationChanges = this.doAnimations();
        const animChanges = this.fadeAnimator.doAnimations(deltaTime, this.fadeAnimationValues);
        const pinChanges = this.pinsRenderer.calculatePins(this.yaw, this.scale, this.floorPlanCenteredAt);
        if (!animationChanges && !animChanges && !pinChanges && !this.forceRenderNextFrame)
            return;
        this.forceRenderNextFrame = false;
        // something has changed, need to clear and re-render everything
        this.ctx.clearRect(0, 0, this.halfCanvasSizeInDpr * 2, this.halfCanvasSizeInDpr * 2); // clear canvas
        this.floorPlanRendererProps.yaw = this.yaw;
        this.floorPlanRendererProps.scale = this.scale;
        this.floorPlanRendererProps.floorPlanCenter = this.floorPlanCenteredAt;
        this.floorPlanRendererProps.opacity = this.fadeAnimationValues.floorPlanOpacity;
        this.floorPlanRenderer.render(this.floorPlanRendererProps);
        this.pinsRenderer.render(this.fadeAnimationValues.activePinOpacity, this.fadeAnimationValues.pinsOpacity);
        if (this.onRendered) {
            const pixelsVisibleAtBaseZoom = this.canvas.width / this.devicePixelRatio;
            const globalScale = pixelsVisibleAtBaseZoom / this.scale;
            this.onRendered(globalScale, this.floorPlanCenteredAt);
        }
        if (this.checkResizeOnNextRender) {
            this.onResize();
            this.checkResizeOnNextRender = false;
        }
    }
    /**
     * Called on mouse wheel or touchpad scroll event
     */
    onWheel(e) {
        var _a, _b, _c, _d, _e;
        this.wheelAccumulatedDelta += e.deltaY;
        this.wheelEvent.clientX = e.clientX;
        this.wheelEvent.clientY = e.clientY;
        (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.analyticsMiniMapZoomHelper.onWheel({
            scene_id: this.engine.getActiveSceneKey(),
            building: (_c = (_b = this.navigationService) === null || _b === void 0 ? void 0 : _b.buildingKey) !== null && _c !== void 0 ? _c : '1',
            floor: (_e = (_d = this.navigationService) === null || _d === void 0 ? void 0 : _d.levelKey) !== null && _e !== void 0 ? _e : '0',
            position: [this.floorPlanCenteredAt.x, this.floorPlanCenteredAt.y],
            zoom: this.scale,
        });
    }
    /**
     * Called on each render to smooth out the wheel event
     */
    onWheelRegular() {
        var _a, _b, _c, _d, _e;
        if (this.wheelAccumulatedDelta === 0)
            return;
        const previousScale = this.scale;
        const part = this.wheelAccumulatedDelta / 4; // magic smoothing number
        this.wheelAccumulatedDelta -= part;
        this.wheelEvent.deltaY = part;
        this.onWheelInternal(this.wheelEvent);
        // ignore long tail of small deltas
        if (this.wheelAccumulatedDelta < 1 && this.wheelAccumulatedDelta > -1) {
            this.wheelAccumulatedDelta = 0;
        }
        if (previousScale === this.scale)
            return;
        (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.analyticsMiniMapZoomHelper.onZoomUpdate({
            scene_id: this.engine.getActiveSceneKey(),
            building: (_c = (_b = this.navigationService) === null || _b === void 0 ? void 0 : _b.buildingKey) !== null && _c !== void 0 ? _c : '1',
            floor: (_e = (_d = this.navigationService) === null || _d === void 0 ? void 0 : _d.levelKey) !== null && _e !== void 0 ? _e : '0',
            position: [this.floorPlanCenteredAt.x, this.floorPlanCenteredAt.y],
            zoom: this.scale,
        });
    }
    /**
     * Actual wheel event handler, gets called on each frame if there is accumulated scroll delta
     */
    onWheelInternal(e) {
        if (e.deltaY < 0 && this.scaleTarget === this.actualMaxScale)
            return;
        if (e.deltaY > 0 && this.scaleTarget === this.actualMinScale)
            return;
        const rect = this.canvas.getBoundingClientRect();
        // original mouse position
        let mx = e.clientX - rect.left - this.halfCanvasSizeInDpr;
        let my = e.clientY - rect.top - this.halfCanvasSizeInDpr;
        // adjust for rotation
        const cos = Math.cos(-this.yaw);
        const sin = Math.sin(-this.yaw);
        const rotatedMx = mx * cos + my * sin;
        const rotatedMy = my * cos - mx * sin;
        mx = rotatedMx;
        my = rotatedMy;
        // mouse position in floorPlan, relative to center
        const mfx = mx / this.scale + this.floorPlanCenteredAt.x;
        const mfy = my / this.scale + this.floorPlanCenteredAt.y;
        const delta = Utils.clamp(e.deltaY, -CanvasMiniMapRenderer.maxScrollWheelDelta, CanvasMiniMapRenderer.maxScrollWheelDelta);
        const scaleChange = 1 - (2 * delta) / 1000;
        this.scaleTarget *= scaleChange;
        this.scaleTarget = Math.max(this.actualMinScale, Math.min(this.actualMaxScale, this.scaleTarget));
        this.floorPlanCenteredAt.x += (mfx - this.floorPlanCenteredAt.x) * (scaleChange - 1);
        this.floorPlanCenteredAt.y += (mfy - this.floorPlanCenteredAt.y) * (scaleChange - 1);
        this.currentAnimation = undefined; // cancel any animation
        this.scale = this.scaleTarget; // no easing towards scale target, just snap to it; scaleTarget is eased enough
        this.limitFloorPlanPanning();
    }
    onMouseDown(e, fingerGesture = false) {
        var _a, _b, _c, _d, _e, _f;
        if ((_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.isHoveringPinGroup()) {
            this.lastPressedPinGroupIndex = this.pinsRenderer.getHoveredPinGroupIndex();
            // click with finger tap is registered instantly (not on release)
            if (fingerGesture && this.lastPressedPinGroupIndex !== -1) {
                // force pins renderer to check hovered pin group from current touch position
                this.pinsRenderer.inputMouseMove({ x: e.clientX, y: e.clientY });
                this.pinsRenderer.forceCheckHoveredPinGroup();
                this.onClickOnPinGroup(fingerGesture);
            }
            return; // no dragging if hovering pin
        }
        this.dragging = true;
        this.updateCursor();
        this.dragLastPos = { x: e.clientX, y: e.clientY };
        (_b = this.analytics) === null || _b === void 0 ? void 0 : _b.analyticsMiniMapDragHelper.onMiniMapDragStart({
            scene_id: this.engine.getActiveSceneKey(),
            building: (_d = (_c = this.navigationService) === null || _c === void 0 ? void 0 : _c.buildingKey) !== null && _d !== void 0 ? _d : '1',
            floor: (_f = (_e = this.navigationService) === null || _e === void 0 ? void 0 : _e.levelKey) !== null && _f !== void 0 ? _f : '0',
            position: [this.floorPlanCenteredAt.x, this.floorPlanCenteredAt.y],
            zoom: this.scale,
        });
    }
    onMouseMove(e) {
        var _a, _b, _c, _d, _e, _f;
        // inform pins about the cursor position
        const rect = this.canvas.getBoundingClientRect();
        const mousePosOnCanvas = { x: e.clientX - rect.left, y: e.clientY - rect.top };
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.inputMouseMove(mousePosOnCanvas);
        // drag floorPlan
        if (!this.dragging)
            return;
        const delta = { x: e.clientX - this.dragLastPos.x, y: e.clientY - this.dragLastPos.y };
        this.dragLastPos = { x: e.clientX, y: e.clientY };
        const cos = Math.cos(-this.yaw);
        const sin = Math.sin(-this.yaw);
        const rotatedDeltaX = delta.x * cos + delta.y * sin;
        const rotatedDeltaY = delta.y * cos - delta.x * sin;
        const previousPlanCenteredAt = Object.assign({}, this.floorPlanCenteredAt);
        this.floorPlanCenteredAt.x -= rotatedDeltaX / this.scale;
        this.floorPlanCenteredAt.y -= rotatedDeltaY / this.scale;
        this.limitFloorPlanPanning();
        this.currentAnimation = undefined; // cancel any animation
        if (isEqual(previousPlanCenteredAt, this.floorPlanCenteredAt))
            return;
        (_b = this.analytics) === null || _b === void 0 ? void 0 : _b.analyticsMiniMapDragHelper.onMiniMapDragUpdate({
            scene_id: this.engine.getActiveSceneKey(),
            building: (_d = (_c = this.navigationService) === null || _c === void 0 ? void 0 : _c.buildingKey) !== null && _d !== void 0 ? _d : '1',
            floor: (_f = (_e = this.navigationService) === null || _e === void 0 ? void 0 : _e.levelKey) !== null && _f !== void 0 ? _f : '0',
            position: [this.floorPlanCenteredAt.x, this.floorPlanCenteredAt.y],
            zoom: this.scale,
        });
    }
    onMouseUp(fingerGesture = false) {
        var _a, _b, _c, _d, _e;
        this.dragging = false;
        this.updateCursor();
        // mouse click is registered on button release
        if (!fingerGesture && this.lastPressedPinGroupIndex !== -1) {
            this.onClickOnPinGroup(fingerGesture);
        }
        (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.analyticsMiniMapDragHelper.onMiniMapDragEnd({
            scene_id: this.engine.getActiveSceneKey(),
            building: (_c = (_b = this.navigationService) === null || _b === void 0 ? void 0 : _b.buildingKey) !== null && _c !== void 0 ? _c : '1',
            floor: (_e = (_d = this.navigationService) === null || _d === void 0 ? void 0 : _d.levelKey) !== null && _e !== void 0 ? _e : '0',
            position: [this.floorPlanCenteredAt.x, this.floorPlanCenteredAt.y],
            zoom: this.scale,
        });
    }
    onMouseLeave() {
        this.dragging = false;
        this.updateCursor();
    }
    onTouchStart(e) {
        if (e.touches.length === 1) {
            // simple tap
            const syntheticEvent = {
                clientX: e.touches[0].clientX,
                clientY: e.touches[0].clientY,
            };
            this.onMouseDown(syntheticEvent, true);
        }
        else if (e.touches.length === 2) {
            // pinch zoom
            this.initialPinchDistance = getDistanceBetweenTouches(e.touches);
        }
    }
    onTouchEnd() {
        this.onMouseUp();
    }
    onTouchMove(e) {
        if (this.dragging) {
            const syntheticEvent = {
                clientX: e.touches[0].clientX,
                clientY: e.touches[0].clientY,
            };
            this.onMouseMove(syntheticEvent);
        }
        // pinch zoom
        if (e.touches.length === 2 && this.initialPinchDistance !== undefined) {
            const newDistance = getDistanceBetweenTouches(e.touches);
            const deltaY = -(newDistance - this.initialPinchDistance) * 3;
            // first touch is the one that stays in place
            // (middle of pinch feels weird, either of touches is better option)
            const clientX = e.touches[0].clientX;
            const clientY = e.touches[0].clientY;
            this.onWheel({ clientX, clientY, deltaY });
            this.initialPinchDistance = newDistance;
        }
    }
    /**
     * Mouse or finger click on pin group
     */
    onClickOnPinGroup(fingerGesture) {
        var _a, _b, _c, _d;
        if (!this.pinsRenderer)
            return;
        let currentHoveredPinGroupIndex;
        if (fingerGesture) {
            // click with finger is recorded on press (not on release as with mouse)
            currentHoveredPinGroupIndex = this.pinsRenderer.getHoveredPinGroupIndex();
            if (currentHoveredPinGroupIndex === -1)
                return;
        }
        else {
            // it's a click if mouse was pressed and released on the same pin group
            currentHoveredPinGroupIndex = this.pinsRenderer.getHoveredPinGroupIndex();
            if (currentHoveredPinGroupIndex !== this.lastPressedPinGroupIndex || currentHoveredPinGroupIndex === -1)
                return;
        }
        const hoveredGroupSceneKey = this.pinsRenderer.getHoveredPinGroupKey();
        if (hoveredGroupSceneKey) {
            // hovered group is single pin group
            (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.push('click', 'MAP', 'Pin', { scene_id: hoveredGroupSceneKey });
            if ((_b = this.gatedTourService) === null || _b === void 0 ? void 0 : _b.checkVerification()) {
                this.engine.loadSceneKey(hoveredGroupSceneKey);
            }
        }
        else {
            // hovered group, has multiple pins
            (_c = this.analytics) === null || _c === void 0 ? void 0 : _c.push('click', 'MAP', 'Pin cluster', {
                scene_ids: (_d = this.pinsRenderer.getPinGroup(currentHoveredPinGroupIndex)) === null || _d === void 0 ? void 0 : _d.pins.map((pin) => pin.key),
            });
            let optimalScale = this.pinsRenderer.getScaleWhereThisGroupWillUngroup(currentHoveredPinGroupIndex);
            optimalScale *= 1.1; // zoom in a bit more, just to be sure
            // optionally animate to center the pin group (if it's too far from center)
            const scaleFactor = this.scale / optimalScale;
            // if starting from very small scale then ensure the visibility ensurer is more vigilant
            // (otherwise pins might not get centered)
            const positionNudgeFactor = linearScale(scaleFactor, [0, 0.35], [10, 1]);
            const groupPos = this.pinsRenderer.getPinGroupFloorPlanPosition(currentHoveredPinGroupIndex);
            const animationPositions = this.getPinGroupVisibilityTransition(groupPos, positionNudgeFactor);
            let duration = 200;
            const zoom = Math.abs(this.scale - optimalScale);
            let distance = 0;
            if (animationPositions) {
                distance = Math.sqrt(Math.pow((animationPositions.endPos.x - animationPositions.startPos.x), 2) +
                    Math.pow((animationPositions.endPos.y - animationPositions.startPos.y), 2));
            }
            // bonus duration for larger distance (a little) and zoom (a bit more)
            duration += Math.min(distance * 0.3, 100);
            duration += Math.min(zoom * 120, 300);
            this.currentAnimation = {
                startPos: animationPositions === null || animationPositions === void 0 ? void 0 : animationPositions.startPos,
                endPos: animationPositions === null || animationPositions === void 0 ? void 0 : animationPositions.endPos,
                startScale: this.scale,
                endScale: optimalScale,
                startTime: performance.now(),
                duration, // ms
            };
        }
    }
    onPinHoverChangedInScene(sceneKey) {
        var _a;
        if ((_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.inputPanoHovered(sceneKey)) {
            // will return false if it was already hovered
            // need force re-render, because nothing else in minimap has changed
            this.forceRenderNextFrame = true;
        }
    }
    /**
     * Called on window resize
     */
    onResize() {
        var _a;
        const parent = this.canvas.parentElement;
        if (!parent)
            return;
        this.devicePixelRatio = window.devicePixelRatio || 1;
        if (this.frameId === 0) {
            this.checkResizeOnNextRender = true;
            return;
        }
        const rect = parent.getBoundingClientRect();
        // very small window, canvas size=0, all NAV is hidden, can't draw anything
        if (rect.width === 0 || rect.height === 0)
            return;
        const displayedWidth = Math.round(rect.width);
        const displayedHeight = Math.round(rect.height);
        this.canvas.width = displayedWidth * this.devicePixelRatio;
        this.canvas.height = displayedHeight * this.devicePixelRatio;
        this.halfCanvasSizeInDpr = this.canvas.width / 2 / this.devicePixelRatio;
        this.floorPlanRendererProps.halfCanvasSizeDpr = this.halfCanvasSizeInDpr;
        // Adjust the canvas style to match the original display size
        this.canvas.style.width = `${displayedWidth}px`;
        this.canvas.style.height = `${displayedHeight}px`;
        // Scale the drawing context
        this.ctx.scale(this.devicePixelRatio, this.devicePixelRatio);
        this.checkScale(false);
        // minimap is resized few times during setup - animation or just tailwind warming up, looks weird, anyway:
        // scale is calculated first time around using current minimap size,
        // so we need to re-scale when size changes,
        // otherwise floorPlan will remain the same size on screen, but canvas will be bigger/smaller
        if (this.lastCanvasSize !== displayedWidth) {
            if (this.lastCanvasSize > 0) {
                const resizeFactor = displayedWidth / this.lastCanvasSize;
                this.scaleTarget *= resizeFactor;
                this.scale *= resizeFactor;
            }
            this.lastCanvasSize = displayedWidth;
            this.forceRenderNextFrame = true;
        }
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.onResize();
    }
    onSceneTransitionStart(fromScene, toScene) {
        var _a;
        const isFromSubScene = Array.isArray(fromScene);
        const isToSubScene = Array.isArray(toScene);
        const fromMainScene = isFromSubScene ? fromScene[0] : fromScene;
        const toMainScene = isToSubScene ? toScene[0] : toScene;
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.onSceneTransitionStart(fromScene, toScene);
        if (!isToSubScene)
            CanvasMiniMapRenderer.visitedScenes.set(fromMainScene.sceneKey, true);
        if (fromMainScene.building === toMainScene.building && fromMainScene.level === toMainScene.level) {
            // same level transition, check if next pin needs to be centered
            this.startAnimationIfNextPinNeedsToBeCentered(toMainScene);
        }
    }
    onSceneTransitionUpdate(progress) {
        var _a;
        this.forceRender();
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.onSceneTransitionUpdate(progress);
    }
    onSceneTransitionEnd(toScene) {
        var _a;
        this.forceRender();
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.onSceneTransitionEnd(toScene);
    }
    forceRegenerateActivePinLabel() {
        var _a;
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.forceRegenerateActivePinLabel();
    }
    forceRegenerateAccentedPinImages(accentColor) {
        var _a;
        this.lastAccentColor = accentColor;
        (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.forceRegenerateAccentedPinImages(accentColor);
    }
    /**
     * @note -- not resetting the scale, when switching floors or resizing window
     */
    forceSetState(globalScale, position, animationDuration) {
        const pixelsVisibleAtBaseZoom = this.canvas.width / this.devicePixelRatio;
        const scale = pixelsVisibleAtBaseZoom / globalScale;
        this.currentAnimation = {
            startPos: this.floorPlanCenteredAt,
            endPos: { x: position.x, y: position.y },
            startScale: this.scale,
            endScale: scale,
            startTime: performance.now(),
            duration: animationDuration,
        };
    }
    // IDE marks this as unused, is used in .tsx
    getCurrentAccentColor() {
        return this.lastAccentColor;
    }
    init() {
        this.onResize();
        this.setLoading(true);
    }
    /**
     * Recalculates min/max scale on floorPlan change or window resize
     * Currently we are experimenting with relativity loss and resetting everything all the time is the new black
     */
    checkScale(resetScale) {
        var _a;
        const floorPlanImage = (_a = this.floorPlanRenderer) === null || _a === void 0 ? void 0 : _a.getBestLoadedFloorPlanImage();
        if (!floorPlanImage)
            return;
        if (!this.buildingSize)
            throw new Error('bad size data A');
        if (!this.floorPlanSize)
            throw new Error('bad size data B');
        const imgW = floorPlanImage.htmlImageElement.width;
        const imgH = floorPlanImage.htmlImageElement.height;
        const buildingW = this.buildingSize.width;
        const buildingH = this.buildingSize.height;
        const fpW = this.floorPlanSize.width;
        const fpH = this.floorPlanSize.height;
        const usablePortionW = buildingW / fpW;
        const usablePortionH = buildingH / fpH;
        const usablePixelsW = imgW * usablePortionW;
        const usablePixelsH = imgH * usablePortionH;
        const pixelsVisibleAtBaseZoom = this.canvas.width / this.devicePixelRatio;
        const unzoomW = pixelsVisibleAtBaseZoom / usablePixelsW;
        const unzoomH = pixelsVisibleAtBaseZoom / usablePixelsH;
        const unzoom = Math.min(unzoomW, unzoomH);
        const maxDimension = Math.max(buildingW, buildingH);
        // make sure not to clip corners: zoom out a bit more - small buildings get zoomed out more, large - less
        // percentage points to decrease initialScaleFit, a lot for tiny floors, almost none for large ones
        const factor = (1 / maxDimension) * 60;
        const initialScale = CanvasMiniMapRenderer.initialScaleFit - factor;
        const scale = unzoom * floorPlanImage.pixelScale * initialScale;
        this.actualMinScale = scale * CanvasMiniMapRenderer.minScale;
        this.actualMaxScale = CanvasMiniMapRenderer.maxScale;
        if (!resetScale)
            return;
        this.scaleTarget = scale;
        this.scale = scale;
    }
    /**
     * Changed hovered scene key in minimap
     *
     * @param sceneKey
     */
    onSceneKeyHoverChangedInMinimap(sceneKey) {
        this.handleSceneHover(sceneKey);
        this.updateCursor();
    }
    /**
     * Changed hovered pin group in minimap (not necessarily scene key, it can still be null if hovering large pin group)
     */
    onPinGroupHoverChangedInMinimap() {
        this.updateCursor();
    }
    limitFloorPlanPanning() {
        if (!this.buildingSize || !this.floorCenter)
            return;
        const width = this.buildingSize.width * this.scale;
        const height = this.buildingSize.height * this.scale;
        const maxDimension = Math.max(width, height);
        const floorPlanFits = maxDimension <= this.halfCanvasSizeInDpr * 2 * 0.85;
        const centerX = this.floorCenter.x;
        const centerY = this.floorCenter.y;
        // at zero margin max allowed panning is the edge of building
        // floorplan center will always be inside the building
        // (positive margin means, that you can drag floorplan so that minimap center is outside the building)
        const margin = -50;
        let maxDistanceFromCenterX = this.buildingSize.width / 2 + margin;
        let maxDistanceFromCenterY = this.buildingSize.height / 2 + margin;
        if (floorPlanFits) {
            maxDistanceFromCenterX = 0;
            maxDistanceFromCenterY = 0;
        }
        // adjust position to make sure it's within bounds, relative to the center
        this.floorPlanCenteredAt.x = Math.max(centerX - maxDistanceFromCenterX, Math.min(this.floorPlanCenteredAt.x, centerX + maxDistanceFromCenterX));
        this.floorPlanCenteredAt.y = Math.max(centerY - maxDistanceFromCenterY, Math.min(this.floorPlanCenteredAt.y, centerY + maxDistanceFromCenterY));
    }
    updateCursor() {
        var _a;
        if (this.dragging) {
            this.canvas.style.cursor = 'grabbing';
        }
        else if ((_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.isHoveringPinGroup()) {
            this.canvas.style.cursor = 'pointer';
        }
        else {
            this.canvas.style.cursor = 'grab';
        }
    }
    /**
     * Creates animation to center [a bit] the next pin if it's too far from center
     *
     * @param nextPinSceneKey
     */
    startAnimationIfNextPinNeedsToBeCentered(nextPinSceneKey) {
        var _a;
        const pinPos = (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.getPinCanvasPosition(nextPinSceneKey.sceneKey);
        if (!pinPos)
            return; // should never happen
        const animationPositions = this.getPinVisibilityTransition(pinPos);
        if (!animationPositions)
            return; // pin is visible, no need to animate centering
        const { startPos, endPos } = animationPositions;
        this.currentAnimation = {
            startPos,
            endPos,
            startTime: performance.now(),
            duration: 700, // whole transition is 1000ms, this animation should be a bit shorter for nicer fx
        };
    }
    /**
     * Pan & scale animations
     *
     * @returns true if something has changed - animation did something
     */
    doAnimations() {
        const changed = this.scale !== this.scaleTarget;
        // optional pan/scale animation - centering the pin or ungrouping
        if (!this.currentAnimation)
            return changed;
        const now = performance.now();
        const progress = (now - this.currentAnimation.startTime) / (this.currentAnimation.duration * 2);
        if (progress >= 1) {
            this.currentAnimation = undefined;
            return true;
        }
        // animating panning
        if (this.currentAnimation.startPos && this.currentAnimation.endPos) {
            const progressEased = Utils.easeInOutSine(progress);
            this.floorPlanCenteredAt = lerpPos(this.currentAnimation.startPos, this.currentAnimation.endPos, progressEased);
        }
        // animating scaling
        if (this.currentAnimation.startScale && this.currentAnimation.endScale) {
            const progressEased = Utils.easeInOutSine(progress);
            const newScale = lerp(this.currentAnimation.startScale, this.currentAnimation.endScale, progressEased);
            this.scale = newScale;
            this.scaleTarget = newScale;
        }
        return true;
    }
    /**
     * For normal pin centering
     * Get position for animation to animate-to to ensure the given pin is visible
     *
     * @returns null if pin is OK visible, otherwise {startPos,endPos} in floorPlan coords
     *          to animate minimap to center the pin [little bit, not exactly, check params that govern it]
     * @param pinPos -- in canvas pixels
     */
    getPinVisibilityTransition(pinPos) {
        const boundsA = CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageA;
        const boundsB = CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageB;
        const center = this.halfCanvasSizeInDpr; // x and y are the same
        const distanceToCenterSquared = Math.pow((pinPos.x - center), 2) + Math.pow((pinPos.y - center), 2);
        const oObDistActivePinSquared = Math.pow((this.halfCanvasSizeInDpr * boundsA), 2);
        if (distanceToCenterSquared < oObDistActivePinSquared)
            return null; // pin is visible, no need to center
        const currentDistFromCenterPercentage = Math.sqrt(distanceToCenterSquared) / this.halfCanvasSizeInDpr;
        // move current pin position toward center to get new position (that is required % from center)
        const percentageToMove = (currentDistFromCenterPercentage - boundsB) / currentDistFromCenterPercentage;
        const pinDestinationX = lerp(pinPos.x, center, percentageToMove);
        const pinDestinationY = lerp(pinPos.y, center, percentageToMove);
        // delta in canvas coords
        const moveDeltaY = pinPos.y - pinDestinationY;
        const moveDeltaX = pinPos.x - pinDestinationX;
        // pin coords are in non-rotated, non-scaled world, so we need to rotate..
        const rad = this.yaw;
        const rotatedDeltaX = moveDeltaX * Math.cos(rad) - moveDeltaY * Math.sin(rad);
        const rotatedDeltaY = moveDeltaX * Math.sin(rad) + moveDeltaY * Math.cos(rad);
        // ..and scale (only the delta) to get real amount and direction of movement
        // to add to floorPlanCenteredAt to create animation
        const moveDeltaXFp = rotatedDeltaX / this.scale;
        const moveDeltaYFp = rotatedDeltaY / this.scale;
        // animation: start and end positions in floorPlan coords
        const minimapStartPos = Object.assign({}, this.floorPlanCenteredAt);
        const minimapEndPos = { x: minimapStartPos.x + moveDeltaXFp, y: minimapStartPos.y + moveDeltaYFp };
        return { startPos: minimapStartPos, endPos: minimapEndPos };
    }
    /**
     * For pin zoom-to-ungroup, needs more intense centering to achieve similar effect
     * Get position for animation to animate-to to ensure the given pin group is visible
     *
     * @returns null if pin is OK visible, otherwise {startPos,endPos} in floorPlan coords to animate minimap to
     *          center the pin [little bit, not exactly, check params that govern it]
     * @param pinPos -- in floorPlan pixels
     * @param positionNudgeFactor -- multiplier for closeness thresholds (always to make them smaller)
     */
    getPinGroupVisibilityTransition(pinPos, positionNudgeFactor) {
        const boundsA = CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageAUngroup / positionNudgeFactor;
        const boundsB = CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageBUngroup / positionNudgeFactor;
        const startPos = Object.assign({}, this.floorPlanCenteredAt);
        const endPos = Object.assign({}, pinPos);
        // how far is the start position from end position in canvas pixels
        const distance = Math.sqrt(Math.pow((startPos.x - endPos.x), 2) + Math.pow((startPos.y - endPos.y), 2));
        // how long is this distance in current scale in canvas pixels
        const distanceInCanvasPixels = distance * this.scale;
        const distAsPercentageOfCanvasRadius = distanceInCanvasPixels / this.halfCanvasSizeInDpr;
        if (distAsPercentageOfCanvasRadius < boundsA)
            return null; // pin is visible, no need to center
        // actual end position is somewhere between given pin position and center of canvas
        const percentageToMove = (distAsPercentageOfCanvasRadius - boundsB) / distAsPercentageOfCanvasRadius;
        endPos.x = lerp(startPos.x, endPos.x, percentageToMove);
        endPos.y = lerp(startPos.y, endPos.y, percentageToMove);
        return { startPos, endPos };
    }
    actuallySetFloorPlan({ floorPlanSize, floorCenter, floorSize, pins, startingSceneKey, accentColor, }) {
        this.floorPlanSize = floorPlanSize;
        this.floorCenter = Object.assign({}, floorCenter);
        this.buildingSize = floorSize;
        CanvasMiniMapRenderer.visitedScenes.set(startingSceneKey, true);
        this.pinsRenderer = new PinsRenderer({
            canvas: this.canvas,
            ctx: this.ctx,
            dpr: this.devicePixelRatio,
            floorPlanSize: this.floorPlanSize,
            assetPath: this.assetPath,
            pins,
            startingSceneKey,
            accentColor,
            onSceneKeyHoverChanged: this.onSceneKeyHoverChangedInMinimap.bind(this),
            onPinGroupHoverChanged: this.onPinGroupHoverChangedInMinimap.bind(this),
            getNextActivePinLabel: this.getNextActivePinLabel,
            forceRenderParent: this.forceRender,
        });
        this.floorPlanRenderer = new FloorPlanRenderer(this.ctx, this.floorPlanSize);
        this.floorPlanCenteredAt = Object.assign({}, this.floorCenter); // resetting the center..
        this.onResize();
        this.checkScale(true); // .. and the scale
    }
    actuallySetFloorPlanImage(imgUrlConfig, imageType) {
        var _a;
        (_a = this.floorPlanRenderer) === null || _a === void 0 ? void 0 : _a.setFloorPlanImage(imgUrlConfig, imageType, () => {
            var _a;
            this.checkScale(true);
            this.fadeAnimator.fpImageLoaded = true;
            this.setLoading(false);
            this.onFloorPlanImageLoaded();
            (_a = this.pinsRenderer) === null || _a === void 0 ? void 0 : _a.onFloorPlanImageLoaded(this.scale);
        }, this.onFloorPlanImageError);
    }
}
/** for initial scale: 1 would fit the building perfectly in square canvas, but not in circular */
CanvasMiniMapRenderer.initialScaleFit = 0.7;
/**  "zoom speed" scroll wheel rolling adds deltas, fingers on touchpad adds deltas [but much faster] */
CanvasMiniMapRenderer.maxScrollWheelDelta = 95;
/**  min scale is relative to initial scale,
 *  0.5 means 2x zoom out from initial scale,
 *  1 means no zoom out allowed */
CanvasMiniMapRenderer.minScale = 1;
/**  max scale is absolute, 4 means a WC bowl is ~60% of the minimap height when max zoomed in.
 * In .png floorPlan it a bit blurry. */
CanvasMiniMapRenderer.maxScale = 2;
/**  % of dist to center, when pin is considered out of bounds and needs to be centered in transition */
CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageA = 0.8;
/**  % of dist to center, where to move the pin to, when it's out of bounds (0 would be center) */
CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageB = 0.5;
/**  for pin zoom-to-ungroup, needs more intense centering to achieve similar effect */
CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageAUngroup = 0.3;
CanvasMiniMapRenderer.thresholdOutOfBoundsActivePinPercentageBUngroup = 0.2;
/** sceneKey -> isVisited (not visited are not present) static data structure seems adequate for this,
 * since it can't be cleared, only new data added */
CanvasMiniMapRenderer.visitedScenes = new Map();
export default CanvasMiniMapRenderer;
