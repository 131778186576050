import { getContrastRatioLuminances, getLuminance, hexToRGB } from '@g360/vt-utils';

class ContrastChecker {
  private readonly baseRGB: number[] | null;
  private readonly baseLuminance: number | null = null;

  constructor(baseColorHex: string) {
    this.baseRGB = hexToRGB(baseColorHex);

    if (this.baseRGB) {
      this.baseLuminance = getLuminance(this.baseRGB);
    }
  }

  getContrastRatio(colorHex: string): number {
    if (this.baseLuminance != null) {
      const colorRBG = hexToRGB(colorHex);

      if (colorRBG) {
        const luminance = getLuminance(colorRBG);

        return getContrastRatioLuminances(luminance, this.baseLuminance);
      }

      console.warn('Failed to get contrast ratio: invalid hex color provided');
      return -1;
    }

    console.warn('Failed to get contrast ratio: missing base luminance');
    return -1;
  }
}

export default ContrastChecker;
