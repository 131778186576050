var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import noop from 'lodash/noop';
import { createRef, memo, useCallback, useEffect, useState } from 'react';
import { validateDigit, validateNumber } from '../utils/gatedTourUtils';
const CodeInput = memo((_a) => {
    var { id = 'code-input', className = '', errorClassName = '', containerClassName = '', error, hideErrorMessage, digitCount = 5, testId = 'code-input', onChange = noop } = _a, rest = __rest(_a, ["id", "className", "errorClassName", "containerClassName", "error", "hideErrorMessage", "digitCount", "testId", "onChange"]);
    const [inputRefs] = useState(() => Array.from({ length: digitCount }, () => createRef()));
    const [code, setCode] = useState(Array(digitCount).fill(''));
    const [previousError, setPreviousError] = useState(error);
    const updateCode = useCallback((index, newChar) => {
        const newCode = [...code];
        newCode[index] = newChar;
        setCode(newCode);
        onChange(newCode.join(''));
    }, [onChange, code]);
    const onInputChange = (event, index) => {
        var _a;
        let newValue = event.target.value;
        if (newValue === '') {
            updateCode(index, newValue);
            return;
        }
        const previousValue = code[index];
        if (newValue.length > 1)
            newValue = newValue.replace(previousValue, '');
        if (!validateDigit(newValue))
            return;
        updateCode(index, newValue);
        if (index !== digitCount - 1)
            (_a = inputRefs[index + 1].current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const onInputKeyDown = (event, index) => {
        var _a;
        if (event.key !== 'Backspace')
            return;
        if (code[index] !== '' || index === 0)
            return;
        (_a = inputRefs[index - 1].current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    // specifying event type directly makes addEventListener/removeEventListener
    // to spit errors in the terminal
    const onPaste = useCallback((event) => {
        var _a;
        const typedEvent = event;
        const clipboard = (_a = typedEvent.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('Text');
        if (!clipboard)
            return;
        if (clipboard.length !== digitCount || !validateNumber(clipboard))
            return;
        setCode(clipboard.split(''));
        onChange(clipboard);
    }, [digitCount, onChange]);
    const getBorderStyle = useCallback((index) => {
        if (error && index === 0)
            return 'border-modal-red border-2';
        return 'border-modal-gray-light focus:border-modal-accent focus:border-2';
    }, [error]);
    // Register/unregister paste event
    useEffect(() => {
        window.addEventListener('paste', onPaste);
        return () => {
            window.removeEventListener('paste', onPaste);
        };
    }, [onPaste]);
    // Focus the first input initially
    useEffect(() => {
        var _a;
        (_a = inputRefs[0].current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRefs]);
    // On error
    useEffect(() => {
        var _a;
        if (previousError === error)
            return;
        setPreviousError(error);
        if (!error)
            return;
        setCode(Array(digitCount).fill(''));
        (_a = inputRefs[0].current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [digitCount, error, inputRefs, previousError]);
    return (_jsxs("div", { className: "relative w-full leading-none", children: [_jsx("div", { className: `flex gap-[0.625rem] ${containerClassName}`, children: inputRefs.map((ref, index) => (_jsx("input", Object.assign({}, rest, { ref: ref, id: `${id}-${index}`, className: `peer text-center font-primary text-base font-bold text-modal-dark caret-transparent outline-none no-appearance ${getBorderStyle(index)} ${className}`, placeholder: "", "data-testid": `${testId}-${index}`, value: code[index], onChange: (event) => onInputChange(event, index), onKeyDown: (event) => onInputKeyDown(event, index) }), `${id}-${index}`))) }), error && !hideErrorMessage && (_jsx("label", { htmlFor: "floating-label-input", className: `items-center pt-1 font-primary text-xs font-bold tracking-[0.0075rem] text-modal-red ${errorClassName}`, children: error }))] }));
});
CodeInput.displayName = 'CodeInput';
export default CodeInput;
