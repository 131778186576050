import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Label from '../../../common/Label';
import TextButton from '../../../common/TextButton';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
const ContactImage = ({ src }) => (_jsx("span", { className: "flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center justify-center overflow-hidden rounded-full", children: _jsx("img", { className: "h-full w-full object-cover", src: src, alt: "agent" }) }));
const ContactDetails = ({ phone, email }) => {
    if (!phone && !email)
        return null;
    // Split email into 2 parts, so we can add <wbr> tag before @ to allow breaking long email addresses
    const emailParts = email === null || email === void 0 ? void 0 : email.split('@');
    return (_jsxs("div", { className: "text-modal-dark-gray gap flex min-w-0 flex-shrink flex-col gap-0.5 text-xs font-medium", children: [phone && (_jsxs(Label, { className: "tracking-[0.01em]", size: "thin", testId: "phone-label", children: [_jsx("span", { className: "hidden md:block", children: phone }), _jsx("a", { className: "block tracking-[0.01em] md:hidden", href: `tel:${phone}`, children: phone })] })), email && emailParts && (_jsx(Label, { className: "tracking-[0.01em]", size: "thin", testId: "email-label", children: _jsxs("a", { href: `mailto:${email}`, children: [emailParts[0], _jsx("wbr", {}), "@", emailParts[1]] }) }))] }));
};
/** Component which renders the agent info section of the information modal */
const AgentInfo = () => {
    var _a, _b, _c, _d, _e;
    const { tourConfigService, layoutService } = useServices();
    const { projectDataConfig } = useReactive(tourConfigService, ['projectDataConfig']);
    const t = useTranslation();
    const { analytics } = useGlobalContext();
    const name = (_a = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _a === void 0 ? void 0 : _a.name;
    const email = (_b = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _b === void 0 ? void 0 : _b.email;
    const phone = (_c = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _c === void 0 ? void 0 : _c.phone;
    const image = (_d = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _d === void 0 ? void 0 : _d.image;
    const occupation = (_e = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _e === void 0 ? void 0 : _e.occupation;
    const contactForm = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contactFormFields;
    const imageSrc = useMemo(() => {
        if (!image || image === '')
            return null;
        if (!email && !name && !phone)
            return null;
        return image;
    }, [image, name, email, phone]);
    const handleContactClick = () => {
        layoutService.showCTA = true;
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'CTA', 'Open');
    };
    const showContactDetails = Boolean(name || email || phone || occupation);
    const showNameOrOccupation = Boolean(name || occupation);
    return (_jsx("div", { className: "mb-8 flex flex-row items-start border-b border-b-modal-gray-light pb-8", children: _jsxs("div", { className: "flex w-full flex-row flex-wrap gap-4", children: [showContactDetails && (_jsxs("div", { className: "flex flex-grow basis-60 flex-row gap-4", children: [imageSrc && _jsx(ContactImage, { src: imageSrc }), _jsxs("div", { className: "flex h-full w-full flex-col justify-center gap-2 break-words", children: [showNameOrOccupation && (_jsxs("div", { className: "flex h-fit w-full flex-col justify-center", children: [name && (_jsx(Label, { className: "h-[1.4375rem]", size: "medium", testId: "name-label", children: name })), occupation && (_jsx(Label, { className: "h-[1.1875rem]", size: "thin", testId: "occupation-label", children: _jsx("span", { className: "tracking-[0.01em]", children: occupation }) }))] })), _jsx(ContactDetails, { phone: phone, email: email })] })] })), contactForm && layoutService.appContext !== 'guided-viewing' && (_jsx(TextButton, { className: "h-10 min-w-[9.5rem]", onClick: handleContactClick, testId: "cta-btn", children: t('agent-info.cta') }))] }) }));
};
export default AgentInfo;
