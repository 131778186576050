import { jsx as _jsx } from "react/jsx-runtime";
import useServices from '../../../contexts/useServices';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
const MeasureDisclaimerButton = () => {
    const { layoutService } = useServices();
    const handleClick = () => {
        layoutService.showMeasureDisclaimerModal = true;
    };
    return (_jsx("button", { type: "button", className: "btn-theme-primary relative ml-3 h-10 w-10 rounded-full p-0 smvh:ml-4", onClick: handleClick, "data-testid": "measure-disclaimer-btn", children: _jsx(QuestionMarkIcon, { className: "h-4 w-4" }) }));
};
export default MeasureDisclaimerButton;
