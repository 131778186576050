import type {
  AnyHotSpotConfig,
  AssetConfig,
  ColorCorrectionSettings,
  Degree,
  EngineConfig,
  EquirectAssetPath,
  HotSpot2DType,
  HotSpot3DConfig,
  HotSpot3DType,
  HotSpotConfig,
  MeasureModePlatform,
  Pixel,
  SceneConfig,
  Size,
  Theme,
  UnitsConfig,
  WatermarkConfig,
} from '@g360/vt-types';
import { getVec3Difference, getVec3Length, linearScale, toRad } from '@g360/vt-utils';
import { Mixin } from 'ts-mixer';

import Engine from '..';
import { MAX_FOV_RAD } from '../common/Globals';
import RendererDebug from '../common/RendererDebug';
import type { TourConfigService } from '../common/services/TourConfigService';
import Utils, {
  areScenesConnected,
  cutOutWallIfEncountered,
  getTransitionDirection,
  makeEquirectPath,
  makeTilesPath,
} from '../common/Utils';
import getMinMaxPitch from '../common/Utils/clampUtils/clampPitch/getMinMaxPitch';
import getColorCorrectionUniformSettings from '../common/Utils/getColorCorrectionUniformSettings';
import { createTexture, setTextureFromBuffer } from '../common/webglUtils';
import FloorPlan3DController from '../controllers/FloorPlan3DController';
import GestureController from '../controllers/GestureController';
import type DebugControls from '../DebugControls';
import BlurProgram from '../programs/BlurProgram';
import ColorFilterProgram from '../programs/ColorFilterProgram';
import CubeProgram from '../programs/CubeProgram';
import DebugGeometryProgram from '../programs/DebugGeometryProgram';
import DepthProgram from '../programs/DepthProgram';
import EquirectProgram from '../programs/EquirectProgram';
import FloorPlan3DProgram from '../programs/FloorPlan3DProgram';
import HotSpotProgram from '../programs/HotSpotProgram';
import type HotSpot from '../programs/HotSpotProgram/HotSpot';
import HotSpotProgram3D from '../programs/HotSpotProgram3D';
import type HotSpot3D from '../programs/HotSpotProgram3D/HotSpot3D';
import MeasureToolProgram from '../programs/MeasureToolProgram';
import MeasureDebugProgram from '../programs/MeasureToolProgram/MeasureDebugProgram/MeasureDebugProgram';
import SphereProgram from '../programs/SphereProgram';
import VideoCaptionProgram from '../programs/VideoCaptionProgram';
import WatermarkProgram from '../programs/WatermarkProgram';
import type {
  AnyProgram,
  CaptionParams,
  HotSpotEditAction,
  MeasureToolDebugParams,
  PanoProgram,
  ProgramName,
} from '../types/internal';
import Animator from './Animator';
import Performance from './Performance';
import SnapshotMaker from './SnapshotMaker';

class Renderer extends Mixin(Performance, SnapshotMaker) {
  // #region public properties

  public engine: Engine;
  /** Chrome CPU mode, some alternatives in new-transition rendering must be used */
  public safeMode = false;
  public frameBuffer?: WebGLFramebuffer | null;
  public renderBuffer?: WebGLRenderbuffer | null;

  public fbTexture1?: WebGLTexture | null;
  public fbTexture2?: WebGLTexture | null;

  /** Only draw to FB [Frame Buffer] when the camera moves, otherwise reuse the last drawn frame
   * This flag is required to draw the first frame when measure mode is turned on */
  public needsToDrawFb = false;
  /** true if any camera angles were changed in this frame */
  public cameraMoved = true;
  /** Store whether the camera moved on the instance for callbacks */
  public nextDrawMoved = false;
  /** non-eased value */
  public transitionProgress = 0;
  /** are the scenes we are transitioning from-to are in same building and floor */
  public transitionConnected = false;
  /** last wall */
  public transitionWallEncounterPercentB = 0;
  /** RAD angle of the transition: where the user is looking; 0 means straight ahead to the next pano,
   * 180° means directly to the last pano */
  public transitionDirection = 0;
  /** Draw one frame after the camera has finished moving to render max lvl tiles */
  public shouldDrawAfterMove = false;
  public isInTransition = false;
  public renderMode: 'pano' | 'floorplan3D' = 'pano';
  /** is geometry coming from version "2" of the tour.json */
  public oldGeometry = false;
  public blurEditorEnabled = false;
  public actualCameraPos = [0, 0, 0];
  public previousSceneConfig?: SceneConfig | [SceneConfig, SceneConfig];

  // #endregion
  // #region protected properties

  protected canvas: HTMLCanvasElement;
  protected gl?: WebGLRenderingContext | null;
  protected previousFrameTimeStamp = 0;
  protected debugControls?: DebugControls;
  /** floorPlan3D currently can override pitch clamping limits (while it is active) */
  protected minPitchClamp: Degree | undefined;
  protected maxPitchClamp: Degree | undefined;

  // #endregion
  // #region private properties

  private tourConfigService: TourConfigService;
  private assetConfig: AssetConfig;
  private engineConfig: EngineConfig;
  private programs: AnyProgram[] = [];
  private programOrder: ProgramName[] = [
    'PanoProgram',
    'PanoPreloadProgram',
    'SphereProgram',
    'MeasureToolProgram',
    'BlurProgram',
    'VideoCaptionProgram',
    'WatermarkProgram',
    'HotSpotProgram3D',
    'HotSpotProgram2D',
    'ColorFilterProgram',
    'SphereProgram',
    'DepthProgram',
    'DebugGeometryProgram',
    'FloorPlan3DProgram',
  ];
  private pendingRedraws = 0;
  private contextLost = false;
  private contextRestored = false;
  private contextReset = false;
  private resolveRenderOneFramePromise: (() => void) | null = null;
  /** 0..1, first wall encountered, % of the total journey */
  private transitionWallEncounterPercentA = 0;
  /** Promise fulfilled when the first try to load watermark is done, returns success true or false */
  private watermarkPromise: Promise<boolean> | null = null;
  /** Listens for the canvas resize, in case ResizeObserver is not supported, `window.onresize` is used instead */
  private resizeObserver?: ResizeObserver;
  /** Similar to `pendingRedraws`, this registers that the resize handler should be called before drawing a frame */
  private pendingResize = true;
  private isBusyPreloadScene = false;
  private notifiedFirstPaint = false;

  // #endregion
  // #region constructor

  constructor(
    engine: Engine,
    canvas: HTMLCanvasElement,
    tourConfigService: TourConfigService,
    assetConfig: AssetConfig,
    engineConfig: EngineConfig
  ) {
    super();

    this.engine = engine;
    this.canvas = canvas;
    this.tourConfigService = tourConfigService;
    this.assetConfig = assetConfig;
    this.engineConfig = engineConfig;

    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log(`DEV=TRUE DEV_PANEL=${__DEV_PANEL__} USE_EQUIRECT=${__USE_EQUIRECT__} USE_VIDEO_EDITOR=${__USE_VIDEO_EDITOR__}`); // prettier-ignore
    }
  }
  // #endregion
  // #region public functions

  public init(debugControls: DebugControls | undefined): void {
    if (__DEV_PANEL__ && debugControls) {
      this.debugControls = debugControls;
      RendererDebug.addDebugCallbacksToDebugControls(this, debugControls);
    }

    this.renderLoop = this.renderLoop.bind(this);
    this.renderLoopAsyncFlushed = this.renderLoopAsyncFlushed.bind(this);
    this.render = this.render.bind(this);
    this.handleContextLost = this.handleContextLost.bind(this);
    this.handleContextRestored = this.handleContextRestored.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleResize = this.handleResize.bind(this);

    if (!this.gl || !this.contextReset) {
      const options = {
        powerPreference: 'high-performance',
        alpha: true,
        premultipliedAlpha: true,
      };

      // Getting WebGL2 context first
      // this is safe for "old tour" and necessary for 3D floorplan prototype
      // @todo -- make whole tour WebGL2 only or fix types (allow `this.gl` to be either WebGL1 or WebGL2)
      this.gl = this.canvas.getContext('webgl2', options) as WebGLRenderingContext; // casting as WebGL1 context to avoid refactoring types @todo -- fix later
      if (!this.gl) {
        this.gl = this.canvas.getContext('webgl', options) as WebGLRenderingContext;
      }

      this.canvas.addEventListener('webglcontextlost', this.handleContextLost);
      this.canvas.addEventListener('webglcontextrestored', this.handleContextRestored);
      document.addEventListener('visibilitychange', this.handleVisibilityChange);

      if (window.ResizeObserver !== undefined) {
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.canvas);
      } else {
        window.addEventListener('resize', this.handleResize);
      }
    }

    if (this.gl) {
      this.frameBuffer = this.gl.createFramebuffer();
      this.renderBuffer = this.gl.createRenderbuffer();

      this.fbTexture1 = createTexture(this.gl);
      this.fbTexture2 = createTexture(this.gl);

      const size: Size<Pixel> = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

      setTextureFromBuffer(this.gl, this.fbTexture1, null, size, {
        minFilter: this.gl.LINEAR,
        magFilter: this.gl.LINEAR,
        wrapSFilter: this.gl.CLAMP_TO_EDGE,
        wrapTFilter: this.gl.CLAMP_TO_EDGE,
        useAlphaChannel: true,
      });

      setTextureFromBuffer(this.gl, this.fbTexture2, null, size, {
        minFilter: this.gl.LINEAR,
        magFilter: this.gl.LINEAR,
        wrapSFilter: this.gl.CLAMP_TO_EDGE,
        wrapTFilter: this.gl.CLAMP_TO_EDGE,
        useAlphaChannel: true,
      });

      this.vendor = 'unknowable';
      const debugInfo = this.gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        this.vendor = this.gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        if (this.vendor.includes('SwiftShader')) {
          this.safeMode = true; // Suspected Chrome CPU-mode user
        }
      }

      this.gl.enable(this.gl.BLEND);
      this.gl.blendFuncSeparate(
        this.gl.SRC_ALPHA,
        this.gl.ONE_MINUS_SRC_ALPHA,
        this.gl.ONE,
        this.gl.ONE_MINUS_SRC_ALPHA
      );

      this.gl.viewport(0, 0, this.gl.drawingBufferWidth, this.gl.drawingBufferHeight);

      // The same index buffer is used for multiple webgl programs
      // This will cause issues if a new different buffer is used
      const indexBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
      this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 1, 2, 0, 2, 3]), this.gl.DYNAMIC_DRAW);

      // Set canvas background to white
      this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
      this.gl.clear(this.gl.COLOR_BUFFER_BIT);

      let panoProgram: PanoProgram;
      if (__USE_EQUIRECT__) {
        panoProgram = new EquirectProgram(this.gl, this.canvas, this);
        this.panoSnapshotProgram = new EquirectProgram(this.gl, this.canvas, this);
        this.panoSnapshotProgram.init();
      } else {
        const ccMode = this.engineConfig.colorCorrectionMode;
        panoProgram = new CubeProgram(this.gl, this.canvas, this, this.assetConfig, ccMode);
      }

      panoProgram.init();
      panoProgram.subscribe('render', this.render.bind(this));
      panoProgram.subscribe('error.set', (err) => {
        this.engine.emit('error.set', err);
      });
      panoProgram.subscribe('error.clear', (err) => {
        this.engine.emit('error.clear', err);
      });

      this.addProgram(panoProgram);

      if (this.engineConfig.renderHotSpots) {
        if (this.engine.usingNewHotSpots) {
          const hotSpotProgram3D = new HotSpotProgram3D(
            this.gl,
            this.canvas,
            this,
            this.tourConfigService.theme,
            this.assetConfig
          );
          hotSpotProgram3D.subscribe('render', this.render.bind(this));
          hotSpotProgram3D.init(this.tourConfigService.tourConfig, this.actualCameraPos, this.engine as Animator);
          this.addProgram(hotSpotProgram3D);
        }

        if (this.engine.hasInfoHotSpots || !this.engine.usingNewHotSpots || this.engine.appContext === 'editor') {
          const hotSpotProgram2D = new HotSpotProgram(
            this.gl,
            this.canvas,
            this,
            this.tourConfigService.theme,
            this.assetConfig,
            undefined,
            this.engine.usingNewHotSpots
          );
          hotSpotProgram2D.subscribe('render', this.render.bind(this));
          hotSpotProgram2D.init();
          this.addProgram(hotSpotProgram2D);
        }
      }

      const colorFilterProgram = new ColorFilterProgram(this.gl, this.canvas, this, this.tourConfigService.theme);
      colorFilterProgram.init();
      this.addProgram(colorFilterProgram);

      let sphereProgram: SphereProgram | undefined;
      let depthProgram: DepthProgram | undefined;

      if (!this.engineConfig.forceBlendTransition) {
        // don't load unnecessary programs | if not for this, then forceBlendTransition could be toggled before each transition
        const usingLocalCubemaps = __USE_EQUIRECT__;
        sphereProgram = new SphereProgram(
          this.gl,
          this.canvas,
          this,
          this.assetConfig,
          usingLocalCubemaps,
          !this.safeMode
        );

        depthProgram = new DepthProgram(this.gl, this.canvas, this.oldGeometry, undefined, this.debugControls);

        if (__DEV_PANEL__) {
          const debugGeometryProgram = new DebugGeometryProgram(this.gl, this.canvas, sphereProgram);
          debugGeometryProgram.init();
          debugGeometryProgram.cameraPosition = this.actualCameraPos;
          this.addProgram(debugGeometryProgram);
        }

        sphereProgram.init();
        sphereProgram.cameraPosition = this.actualCameraPos;
        depthProgram.init();

        this.addProgram(sphereProgram);
        this.addProgram(depthProgram);
      }

      if (this.blurEditorEnabled) {
        const blurProgram = new BlurProgram(this.gl, this.canvas, this);
        blurProgram.init();
        this.addProgram(blurProgram);
      }

      if (this.engine.overlay) this.enableHotSpots(false);

      if (__PERF_MEASURER__) this.initPerformance();

      this.initSpecialRenderer(this.gl, this.canvas, sphereProgram, depthProgram, this.panoSnapshotProgram);

      if (this.tourConfigService.watermark) this.createAndLoadWatermarkProgram(this.tourConfigService.watermark);

      if (__USE_VIDEO_EDITOR__) {
        let videoCaptionProgram = this.getProgram<VideoCaptionProgram>('VideoCaptionProgram');
        if (!videoCaptionProgram && this.engineConfig.textMode) {
          videoCaptionProgram = new VideoCaptionProgram(this.gl, this.canvas, this.assetConfig, this);
          this.addProgram(videoCaptionProgram);
        }
      }

      // init correct type of render loop
      if (this.engineConfig.recordingMode) {
        this.renderLoopAsyncFlushed();
      } else {
        this.renderLoop();
      }
    } else {
      throw new Error('WEBGL_ERROR');
    }

    // for switching between pano and floorplan3D modes
    if (__DEV_PANEL__) {
      this.engine.subscribe('scene.move.zoom.min', () => {
        this.switchToPanoMode();
      });
      this.engine.subscribe('scene.move.zoom.max', () => {
        this.switchToFloorPlan3DMode();
      });

      if (window.location.search.includes('autodev')) {
        setTimeout(() => {
          console.log(
            '%cdelayed auto switch to FloorPlan mode',
            'color: gold; font-style: italic; background-color: black; padding: 10px; border-radius: 5px; font-size: 20px;'
          );
          this.switchToFloorPlan3DMode();
        }, 500);
      }
    }
  }

  public destroy(): void {
    this.canvas.removeEventListener('webglcontextlost', this.handleContextLost);
    this.canvas.removeEventListener('webglcontextrestored', this.handleContextRestored);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);

    if (this.resizeObserver) this.resizeObserver.disconnect();
    else window.removeEventListener('resize', this.handleResize);

    if (this.frameBuffer) this.gl?.deleteFramebuffer(this.frameBuffer);
    if (this.fbTexture1) this.gl?.deleteTexture(this.fbTexture1);
    if (this.fbTexture2) this.gl?.deleteTexture(this.fbTexture2);

    this.programs.forEach((program) => program.destroy());
    this.programs = [];

    this.engine.sceneTransition = false;
  }

  public render(): void {
    this.pendingRedraws = Math.min(this.pendingRedraws + 1, 3);
  }

  public getProgram<TProgram extends AnyProgram>(programName: ProgramName): TProgram | undefined {
    return this.programs.find((program) => program.name === programName) as TProgram | undefined;
  }

  public getHotSpotProgramByType(
    hotSpotType?: HotSpot2DType | HotSpot3DType
  ): HotSpotProgram3D | HotSpotProgram | undefined {
    if (hotSpotType?.includes('hotspot-')) {
      return this.getProgram<HotSpotProgram>('HotSpotProgram2D');
    }

    return this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
  }

  /** Get the constructed hotsSpots for current scene, this is not just the config but also includes data for webgl state */
  public getHotSpots(): (HotSpot3D | HotSpot)[] {
    const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');
    const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
    return [...(hotSpotProgram2D?.getHotSpots() || []), ...(hotSpotProgram3D?.getHotSpots() || [])];
  }

  public setColorCorrectionSettings(settings: ColorCorrectionSettings): void {
    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');
    const cubeProgram = panoProgram instanceof CubeProgram ? panoProgram : undefined;

    if (!blurProgram && !cubeProgram) return;

    const colorCorrectionUniformSettings = getColorCorrectionUniformSettings(settings);

    if (blurProgram) blurProgram.colorCorrectionUniformSettings = colorCorrectionUniformSettings;
    if (cubeProgram) cubeProgram.colorCorrectionUniformSettings = colorCorrectionUniformSettings;

    this.render();
  }

  /**
   * Disables/enables hot-spots at runtime. If no hotSpotType provided - will affect all
   * the hot-spots. Can be called directly on the Engine instance
   */
  public setHotSpotsDisabled(flag = true, hotSpotType?: HotSpot2DType | HotSpot3DType): void {
    // Set the disabled flag in the tour config for persistence
    Object.keys(this.tourConfigService.scenes).forEach((sceneKey) => {
      const hotSpotsList = this.tourConfigService.scenes[sceneKey].hotSpots;

      if (!hotSpotsList) return;

      this.tourConfigService.scenes[sceneKey].hotSpots = hotSpotsList.map((hotSpot) => {
        if (hotSpotType && hotSpot.type !== hotSpotType) return hotSpot;
        return { ...hotSpot, disabled: flag };
      });

      const subScenes = this.tourConfigService.scenes[sceneKey].subScenes || [];
      if (subScenes)
        Object.keys(subScenes).forEach((subSceneKey) => {
          const subHotSpotsList = subScenes[subSceneKey].hotSpots || [];

          subHotSpotsList.forEach((hotSpot, index) => {
            if (hotSpotType && hotSpot.type !== hotSpotType) return;
            subScenes[subSceneKey].hotSpots[index].disabled = flag;
          });
        });
    });

    if (!hotSpotType) {
      // If type not provided then just set the flag for all the hot-spots for all the enabled programs
      const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');
      const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
      hotSpotProgram2D?.setHotSpotsDisabled(flag);
      hotSpotProgram3D?.setHotSpotsDisabled(flag);
    } else {
      // Now that we have 2D and 3D hot-spots possible at the same time,
      // we need to check which program to use by the hotSpotType
      const hotSpotProgram = this.getHotSpotProgramByType(hotSpotType);
      this.addProgram(hotSpotProgram);

      // Set the disabled flag in the program to disable the current hot-spot rendering
      if (hotSpotProgram instanceof HotSpotProgram) {
        hotSpotProgram?.setHotSpotsDisabled(flag, hotSpotType as HotSpot2DType);
      } else if (hotSpotProgram instanceof HotSpotProgram3D) {
        hotSpotProgram?.setHotSpotsDisabled(flag, hotSpotType as HotSpot3DType);
      }
    }
  }

  /**
   * Enables high resolution mode in CubeProgram, renders max level tiles and sets the resolution to 3x canvas size
   * @param highRes - true to enable high resolution mode, false to disable
   * @param renderCallback - optional callback to be called after rendering the frame
   */
  public setHighResMode(highRes: boolean, renderCallback: (() => void) | null = null): void {
    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (panoProgram instanceof CubeProgram) {
      panoProgram.setHighResMode(highRes);

      if (highRes) {
        this.resizeRenderingBuffer(4);
      } else {
        this.resizeRenderingBuffer();
      }

      this.resizeFramebufferTextures();

      const blurProgram = this.getProgram<PanoProgram>('BlurProgram');

      if (blurProgram) {
        this.updateProgramView(blurProgram);
        blurProgram.render();
      }

      if (renderCallback) {
        this.promiseOneFrame().then(renderCallback);
      }

      this.render();
    }
  }

  public updateEquirectOptions(): void {
    if (!__USE_EQUIRECT__) return;

    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (panoProgram instanceof EquirectProgram && this.assetConfig.equirectAssets) {
      const { primaryOptions, secondaryOptions } = this.assetConfig.equirectAssets;
      panoProgram.reloadWithOptions(primaryOptions, secondaryOptions);
    }
  }

  /** Update the watermark config, if the image is the same only the options will be changed,
   * if set to null, the watermark will be removed
   */
  public updateWatermarkConfig(watermarkConfig: WatermarkConfig | null): void {
    if (!this.gl) return;

    if (!watermarkConfig) {
      this.destroyWatermarkProgram();
      return;
    }

    const watermarkProgram = this.getProgram<WatermarkProgram>('WatermarkProgram');
    if (watermarkProgram) {
      watermarkProgram.loadWatermark(watermarkConfig);
      return;
    }

    this.createAndLoadWatermarkProgram(watermarkConfig);
  }

  /**
   * @deprecated -- use RenderTarget
   */
  public drawToFramebufferTexture(outputTexture: WebGLTexture, drawcall: () => void): void {
    if (!this.gl || !this.frameBuffer) return;

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, outputTexture);

    this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, outputTexture, 0);
    drawcall();
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
  }

  public hideCaption(render = false, noAnimation = false): void {
    if (!__USE_VIDEO_EDITOR__) return;

    const videoCaptionProgram = this.getProgram<VideoCaptionProgram>('VideoCaptionProgram');

    if (!videoCaptionProgram) return;
    videoCaptionProgram.hide(noAnimation);

    if (render) {
      this.pendingRedraws = 5;
      this.render();
    }
  }

  public updateCaption(textParams: CaptionParams, render = false): void {
    if (!__USE_VIDEO_EDITOR__) return;

    const videoCaptionProgram = this.getProgram<VideoCaptionProgram>('VideoCaptionProgram');

    if (!videoCaptionProgram) return;
    videoCaptionProgram.updateText(textParams);

    this.engine.emit('caption.change');
    if (render) {
      this.render();
    }
  }

  public resizeRenderingBuffer(density?: number): void {
    const pixelRatio = density ?? window.devicePixelRatio;

    this.canvas.width = this.canvas.clientWidth * pixelRatio;
    this.canvas.height = this.canvas.clientHeight * pixelRatio;

    if (this.gl) {
      this.gl.viewport(0, 0, this.gl.drawingBufferWidth, this.gl.drawingBufferHeight);
    }

    this.cameraMoved = true;
  }

  /**
   * Load next scene and set up transition rendering.
   *
   * Blocks render loop until loading is done (and transition starts)
   *
   * @param sceneConfig - Scene config to load, scene or [mainScene, subScene]
   * @returns - Promise that resolves when transition is done,
   * Promise is used by the controller, if that gets removed, this can be removed too.
   */
  public async loadConfig(sceneConfig: SceneConfig | [SceneConfig, SceneConfig], duration = 1000): Promise<void> {
    // Before transition to the next scene
    this.engine.emit('scene.preload.start');

    this.isBusyPreloadScene = true;
    const prev = this.previousSceneConfig;
    const previousWasSubScene = Array.isArray(prev);
    const nextIsSubScene = Array.isArray(sceneConfig);

    const prevTargetScene = previousWasSubScene ? prev?.[1] : prev;
    const nextTargetScene = nextIsSubScene ? sceneConfig[1] : sceneConfig;

    const sphereProgram = this.getProgram<SphereProgram>('SphereProgram');

    if (sphereProgram) {
      sphereProgram.setTourConfig(this.tourConfigService.tourConfig);
      sphereProgram.setNextSphere(
        nextIsSubScene ? [sceneConfig[0].sceneKey, sceneConfig[1].sceneKey] : sceneConfig.sceneKey
      );
    }

    const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
    hotSpotProgram3D?.changeScene(nextTargetScene.sceneKey);

    const assetPathConfig = __USE_EQUIRECT__
      ? makeEquirectPath(nextTargetScene.sceneKey, this.assetConfig, this.tourConfigService.tourConfig.tiles?.path)
      : makeTilesPath(this.tourConfigService.tourConfig, this.assetConfig.basePath, nextTargetScene.sceneKey);

    // Add asset path config to scene config, so that we don't have to pass it around explicitly
    const nextSceneConfig = { ...nextTargetScene, assetPathConfig };

    if (__USE_EQUIRECT__) {
      // Clearing cache to reduce RAM usage (larger projects can use a lot of memory and crash). We need to keep the
      // cache for the scene because the image is used in multiple places
      // TODO: This will un-optimize in-house editor asset loading when merged to VTE branch, so need to improve this
      // then, or make an exception for the editor
      EquirectProgram.clearCache();
      await this.createCubeMapsForCurrentAndNextPano(nextSceneConfig, prevTargetScene);
    }

    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');

    if (!__USE_EQUIRECT__ && blurProgram) {
      await blurProgram.setSceneConfig(nextTargetScene, assetPathConfig as string, this.assetConfig);
    }

    this.engine.sceneTransition = true;
    let transitionPromise = Promise.resolve(true);

    this.updateIsInTransition();

    if (prevTargetScene && this.gl) {
      if (!this.engineConfig.forceBlendTransition) {
        this.transitionConnected = areScenesConnected(prevTargetScene, nextSceneConfig);
        this.transitionDirection = getTransitionDirection(this.engine.yaw, prevTargetScene, nextSceneConfig);

        // will show loading circle while waiting on these
        await this.preloadSphereProgram();
        await this.preloadPanoProgram(nextSceneConfig);
        await this.prepHotSpotProgram(nextSceneConfig);

        this.createDepthmapsForCurrentAndNextPano(prevTargetScene, nextTargetScene, this.safeMode);

        if (!this.areDepthmapsReadyForCurrentAndNextPano(prevTargetScene, nextTargetScene)) {
          this.transitionConnected = false; // if DM creation fails, use the blending transition
        }
      } else {
        this.transitionConnected = false;
        await this.preloadPanoProgram(nextSceneConfig);
        await this.prepHotSpotProgram(nextTargetScene);
      }

      const nextConfigCombined: SceneConfig | [SceneConfig, SceneConfig] = nextIsSubScene
        ? [{ ...sceneConfig[0], assetPathConfig }, nextSceneConfig]
        : nextSceneConfig;

      transitionPromise = this.startAnimateTransition(nextConfigCombined, 'bezier', duration);
      this.previousSceneConfig = nextConfigCombined;
      this.engine.emit('scene.preload.end');
    } else {
      // This is initial loading (no need for preload → transition prep)
      this.engine.sceneTransition = false;
      await this.initialConfigLoad(nextSceneConfig);

      this.updateIsInTransition();
    }

    if (__DEV_PANEL__) {
      const depthProgram = this.getProgram<DepthProgram>('DepthProgram');

      // give geometry details of the next scene for debug rendering
      if (nextSceneConfig.geometry && depthProgram) {
        depthProgram.cameraPosition = this.engine.activeSceneConfig.camera;
        depthProgram.loadGeometry(nextSceneConfig);
      }

      const debugGeometryProgram = this.getProgram<DebugGeometryProgram>('DebugGeometryProgram');

      if (debugGeometryProgram) debugGeometryProgram.cameraPosition = this.engine.activeSceneConfig.camera;
    }

    this.isBusyPreloadScene = false;
    await transitionPromise;
  }

  public updateHotSpotProgram(hotSpotConfig: AnyHotSpotConfig, sceneKey: string, action: HotSpotEditAction): void {
    const hotSpotProgram = this.getHotSpotProgramByType(hotSpotConfig.type as HotSpot2DType | HotSpot3DType);
    this.addProgram(hotSpotProgram);

    if (hotSpotProgram instanceof HotSpotProgram3D) {
      hotSpotProgram?.updateHotSpot(hotSpotConfig as HotSpot3DConfig, sceneKey, action);
    } else if (
      sceneKey === this.engine.activeSceneConfig.sceneKey ||
      sceneKey === this.engine.activeSubSceneConfig?.sceneKey
    ) {
      hotSpotProgram?.updateHotSpot(hotSpotConfig as HotSpotConfig, sceneKey, action);
    }
  }

  public async promiseOneFrame() {
    this.pendingRedraws = 1;

    // Deferred promise resolved by the rendering loop
    await new Promise<void>((resolve) => {
      this.resolveRenderOneFramePromise = resolve;
    });
  }

  public resizeFramebufferTextures(): void {
    if (this.gl && this.fbTexture1 && this.fbTexture2) {
      if (this.frameBuffer && this.renderBuffer) {
        // Resize depth stencil attachment in case it doesn't match
        // this can cause incomplete framebuffer error
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
        this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, this.renderBuffer);
        const hasDepthStencil = this.gl.getFramebufferAttachmentParameter(
          this.gl.FRAMEBUFFER,
          this.gl.DEPTH_STENCIL_ATTACHMENT,
          this.gl.FRAMEBUFFER_ATTACHMENT_OBJECT_TYPE
        );
        if (hasDepthStencil !== this.gl.ZERO) {
          this.gl.renderbufferStorage(
            this.gl.RENDERBUFFER,
            this.gl.DEPTH_STENCIL,
            this.gl.drawingBufferWidth,
            this.gl.drawingBufferHeight
          );
        }

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
        this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, null);
      }

      const size: Size<Pixel> = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

      setTextureFromBuffer(this.gl, this.fbTexture1, null, size, { useAlphaChannel: true });
      setTextureFromBuffer(this.gl, this.fbTexture2, null, size, { useAlphaChannel: true });
    }
  }

  public clampView(): void {
    this.clampFov();
    this.clampPitch();
  }

  public setOptimalLevel(): void {
    if (__USE_EQUIRECT__) return;

    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');
    if (panoProgram instanceof CubeProgram) panoProgram.setOptimalLevel();
  }

  public changeMeasureDebugParams(params: MeasureToolDebugParams): void {
    if (params.opacity !== undefined) MeasureDebugProgram.overlayOpacity = params.opacity;
    if (params.radius !== undefined) MeasureDebugProgram.overlayHoverRadius = params.radius;
    if (params.mode !== undefined) MeasureDebugProgram.overlayMode = params.mode;
    if (params.snapRadius !== undefined) MeasureToolProgram.snapRadius = params.snapRadius;

    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');
    if (measureToolProgram) this.render();
  }

  public async changeDepthMap(file: File): Promise<void> {
    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');
    measureToolProgram?.setDepthMap(file);
  }

  public async changeNormalMap(file: File): Promise<void> {
    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');
    measureToolProgram?.setNormalMap(file);
  }

  public async changeEdgeMap(file: File): Promise<void> {
    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');
    measureToolProgram?.setEdgeMap(file);
  }

  public async toggleMeasureTool(mode: false | UnitsConfig): Promise<MeasureModePlatform | null> {
    let measureToolProgram: MeasureToolProgram | undefined;

    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (mode !== false) {
      // Toggle on
      if (!this.gl || !panoProgram || !this.frameBuffer || !this.fbTexture1 || !this.fbTexture2) {
        console.warn('Measure tool not initialized');
        return null;
      }

      this.engine.emit('measuretool.load.start');
      this.engine.emit('scene.preload.start');

      try {
        // Fill both textures before using them
        this.drawToFramebufferTexture(this.fbTexture1, () => panoProgram.draw(false));
        this.drawToFramebufferTexture(this.fbTexture2, () => panoProgram.draw(false));

        measureToolProgram = new MeasureToolProgram(this.gl, this.canvas, this, mode, this.assetConfig);

        await measureToolProgram.init();

        measureToolProgram.subscribe('render', this.render.bind(this));
        measureToolProgram.zoomScopeProgram.subscribe('render', this.render.bind(this));
        measureToolProgram.subscribe('camera.lock', () => this.engine.emit('measuretool.camera.lock'));
        measureToolProgram.subscribe('camera.unlock', () => this.engine.emit('measuretool.camera.unlock'));
        measureToolProgram.subscribe('toast.missing_data', () => this.engine.emit('measuretool.toast.missing_data'));
        measureToolProgram.subscribe('tutorial.dismiss', () => this.engine.emit('measuretool.tutorial.dismiss'));

        const scene = this.engine.activeSubSceneConfig ?? this.engine.activeSceneConfig;

        const yawOffset = Utils.toRad(scene.camera[3]);
        measureToolProgram.yawOffset = yawOffset;

        this.updateProgramView(measureToolProgram);

        await measureToolProgram?.loadScene(scene.sceneKey);

        this.needsToDrawFb = true;

        panoProgram.measureToolProgram = measureToolProgram;

        this.addProgram(measureToolProgram);
      } catch (e) {
        console.error('Failed to initialize measure tool', e);
        this.engine.emit('measuretool.load.error');
        this.engine.emit('error.set', 'MEASURE_TOOL_INIT_FAILED');
      }
      this.engine.emit('measuretool.load.end');
      this.engine.emit('scene.preload.end');
    } else {
      // Toggle off
      measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');

      if (measureToolProgram) {
        measureToolProgram.destroy();
        this.removeProgram(measureToolProgram);
      }

      if (panoProgram) panoProgram.measureToolProgram = undefined;
    }

    this.render();

    return measureToolProgram?.isHandheld ? 'handheld' : 'desktop';
  }

  public updateIsInTransition(): void {
    this.isInTransition = this.engine.sceneTransition && this.engine.animatingTransition;
  }

  public updateColorFilter(theme: Theme): void {
    const colorFilterProgram = this.getProgram<ColorFilterProgram>('ColorFilterProgram');
    colorFilterProgram?.setTheme(theme);
  }

  public updateHotSpotTheme(theme: Theme): void {
    const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');
    const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
    hotSpotProgram2D?.updateHotSpotTheme(theme);
    hotSpotProgram3D?.updateHotSpotTheme(theme);
  }

  public handleMeasureUnitsChange(units: UnitsConfig): void {
    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');
    measureToolProgram?.setUnits(units);
  }

  public async prepHotSpotProgram(sceneConfig: SceneConfig): Promise<void> {
    const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');

    if (!hotSpotProgram2D) return;

    hotSpotProgram2D.yawOffset = Utils.toRad(sceneConfig.camera[3]);
    await hotSpotProgram2D.loadHotSpots((sceneConfig.hotSpots as HotSpotConfig[]) || [], this.engine.usingNewHotSpots);
  }

  // #endregion
  // #region protected functions

  /** Create watermark program and start loading the watermark image, creates a deferred promise you can await
   * in a different async function to wait for the watermark to load before continuing
   */
  protected createAndLoadWatermarkProgram(watermarkConfig: WatermarkConfig): void {
    if (!this.gl) return;

    const watermarkProgram = new WatermarkProgram(this.gl, this.canvas, this);

    watermarkProgram.subscribe('error.set', (err) => {
      this.handleWatermarkInterrupted(true);
      this.engine.emit('error.set', err);
    });

    watermarkProgram.subscribe('error.clear', (err) => {
      this.handleWatermarkInterrupted(false);
      this.engine.emit('error.clear', err);
    });

    watermarkProgram.subscribe('render', this.render.bind(this));

    watermarkProgram.init();

    this.watermarkPromise = watermarkProgram.loadWatermark(watermarkConfig).then((success) => {
      this.handleWatermarkInterrupted(!success);
      return success;
    });

    this.addProgram(watermarkProgram);
  }

  /** Force scene in low resolution mode if watermark loading failed, or switch back to full resolution tiles if
   * watermark is no longer interrupted, affects only tiles rendering
   */
  protected handleWatermarkInterrupted(isInterrupted: boolean): void {
    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (panoProgram?.forcePreview) {
      panoProgram.forcePreview = isInterrupted;
    }

    if (this.engine.watermarkInterrupted && !isInterrupted) {
      this.watermarkPromise = null;
      if (panoProgram?.loadTiles) panoProgram.loadTiles();
    }

    this.engine.watermarkInterrupted = isInterrupted;

    this.render();
  }

  protected handleContextLost(evt: Event): void {
    this.contextLost = true;
    this.contextReset = false;
    this.contextRestored = false;
    evt.preventDefault();
  }

  protected handleContextRestored(evt: Event): void {
    this.contextRestored = true;

    if (document.visibilityState === 'visible') {
      this.engine.loadTourConfig(this.tourConfigService.tourConfig, this.assetConfig);
      this.contextReset = true;
    }

    evt.preventDefault();
  }

  protected handleVisibilityChange(): void {
    if (this.contextLost && this.contextRestored && !this.contextReset && document.visibilityState === 'visible') {
      this.engine.loadTourConfig(this.tourConfigService.tourConfig, this.assetConfig);
      this.contextReset = true;
    }
  }

  /** Destroys watermark program including loaded texture and event listeners */
  protected destroyWatermarkProgram(): void {
    const watermarkProgram = this.getProgram<WatermarkProgram>('WatermarkProgram');

    if (!watermarkProgram) return;

    watermarkProgram.destroy();
    this.removeProgram(watermarkProgram);
  }

  protected addProgram(program: AnyProgram | undefined): void {
    if (!program) return;

    const { programOrder } = this;

    // Find indices of all occurrences in the array
    const indices: number[] = [];
    for (let i = 0; i < programOrder.length; i += 1) {
      if (programOrder[i] === program.name) {
        indices.push(i);
      }
    }

    // Program location is not specified in the order, skipping
    if (indices.length === 0) return;

    this.removeProgramByName(program.name);

    const { programs } = this;

    // For all programs render() is called at least once per frame
    // eslint-disable-next-line no-param-reassign
    program.orderIndex = indices[0];

    // Insert new program after the program with highest orderIndex that is lower than new program orderIndex or secondOrderIndex
    let secondInsertStart = 0;
    let isSphereProgramCheckedOnce = false;
    let isInserted = false;

    for (let i = 0; i < programs.length; i += 1) {
      const activeProgram = programs[i];

      if (activeProgram.name === 'SphereProgram') {
        const sphereProgram = activeProgram as SphereProgram;

        if (isSphereProgramCheckedOnce) {
          if (sphereProgram.secondOrderIndex > program.orderIndex) {
            programs.splice(i, 0, program);
            secondInsertStart = i + 1;
            isInserted = true;
            break;
          }
        } else {
          isSphereProgramCheckedOnce = true;
          if (activeProgram.orderIndex > program.orderIndex) {
            programs.splice(i, 0, program);
            secondInsertStart = i + 1;
            isInserted = true;
            break;
          }
        }
      } else if (activeProgram.orderIndex > program.orderIndex) {
        programs.splice(i, 0, program);
        secondInsertStart = i + 1;
        isInserted = true;
        break;
      }
    }

    if (!isInserted) programs.push(program);

    // Sphere program can be rendered twice per frame: 1st in the transition, 2nd as a debug sphere
    if (indices.length >= 2) {
      // eslint-disable-next-line no-param-reassign
      program = program as SphereProgram;
      // eslint-disable-next-line no-param-reassign
      program.secondOrderIndex = indices[1];
      let isInserted2 = false;

      // Insert new program after the program with highest orderIndex that is lower than new program secondOrderIndex
      for (let i = secondInsertStart; i < programs.length; i += 1) {
        if (programs[i].orderIndex > program.secondOrderIndex) {
          programs.splice(i, 0, program);
          isInserted2 = true;
          break;
        }
      }

      if (!isInserted2) programs.push(program);
    }
  }

  protected removeProgram(programToRemove: AnyProgram | undefined): void {
    this.programs = this.programs.filter((program) => program !== programToRemove);
  }

  protected removeProgramByName(programName: ProgramName | undefined): void {
    this.programs = this.programs.filter((program) => program.name !== programName);
  }

  protected enableHotSpots(flag = true): void {
    const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');
    const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');
    hotSpotProgram2D?.enableHotSpots(flag);
    hotSpotProgram3D?.enableHotSpots(flag);
  }

  protected clampPitch(): void {
    const min = this.minPitchClamp ?? this.tourConfigService.minPitch;
    const max = this.maxPitchClamp;
    this.engine.pitch = Utils.clampPitch(this.engine.pitch, this.engine.fov, this.engine.getCanvasSize(), min, max);
  }

  protected clampFov(): void {
    this.engine.fov = Utils.clampFov(this.engine.fov, this.engine.getCanvasSize());
  }

  protected async startAnimateTransition(
    // Has to also take array so we can propagate to emitted transition events
    sceneConfig: SceneConfig | [SceneConfig, SceneConfig],
    easing,
    baseDuration = 1000
  ): Promise<boolean> {
    const prevScene = this.previousSceneConfig;
    if (!prevScene) return Promise.reject();

    const prevSceneConfig = Array.isArray(prevScene) ? prevScene[1] : prevScene;
    const nextSceneConfig = Array.isArray(sceneConfig) ? sceneConfig[1] : sceneConfig;

    const camA = [...prevSceneConfig.camera];
    const camBActual = [...nextSceneConfig.camera];
    const camBTransition = [...nextSceneConfig.camera];
    const duration =
      (this.engineConfig.transitionDuration ?? baseDuration) * RendererDebug.getDebugParam('transitionLength', 1);

    this.actualCameraPos[0] = -camA[0];
    this.actualCameraPos[1] = -camA[1];
    this.transitionWallEncounterPercentA = 0.65; // use this value, while calculating real one (if no walls encountered, use this)
    this.transitionWallEncounterPercentB = 0.65;

    if (this.transitionConnected) {
      const { nearestWall, farthestWall, wallVector } = cutOutWallIfEncountered(nextSceneConfig, prevSceneConfig);
      if (nearestWall && farthestWall) {
        const distTo1stWall = getVec3Length(getVec3Difference(prevSceneConfig.camera, nearestWall));
        const distFromLastWallToFinish = getVec3Length(getVec3Difference(nextSceneConfig.camera, farthestWall));
        let distToFinish = getVec3Length(getVec3Difference(prevSceneConfig.camera, nextSceneConfig.camera));

        if (wallVector) {
          camBTransition[0] -= wallVector[0];
          camBTransition[1] -= wallVector[1]; // don't move as far - cut out wall thickness portion from the path

          const sphereProgram = this.getProgram<SphereProgram>('SphereProgram');
          sphereProgram?.setNextSphereCameraOffsetForTransition(wallVector); // also draw next sphere closer

          const skippedLen = Utils.getVec2Length(wallVector);
          distToFinish -= skippedLen;
        }

        this.transitionWallEncounterPercentA = distTo1stWall / distToFinish;
        this.transitionWallEncounterPercentB = (distToFinish - distFromLastWallToFinish) / distToFinish;
      }
    }

    const startingFov = this.engine.fov;

    if (!this.engine.animatingTransition && this.previousSceneConfig) {
      this.engine.animatingTransition = true;
      this.updateIsInTransition();

      this.engine.toggleBlurmaskRendering(false, 0);
      this.engine.emit('scene.transition.start', this.previousSceneConfig, sceneConfig);
      this.resizeRenderingBuffer(1); // forget retina for transition

      return new Promise((resolve) => {
        this.engine.startAnimation(
          'transition',
          (progress) => {
            // update
            this.render();
            this.engine.emit('scene.transition.update', progress);
            this.transitionProgress = progress;
            this.updateCameraPos(progress, camA, camBTransition);
            this.engine.fov = linearScale(progress, [0, 1], [startingFov, MAX_FOV_RAD]);
            // Now that we reset fov in transition, we need to clamp it to max fov and pitch limit
            this.clampView();
          },
          () => {
            // complete
            this.engine.animatingTransition = false;
            this.engine.sceneTransition = false;
            this.transitionProgress = 0;

            this.updateIsInTransition();

            const panoPreloadProgram = this.getProgram<PanoProgram>('PanoPreloadProgram');

            if (panoPreloadProgram) {
              const panoProgram = this.getProgram<PanoProgram>('PanoProgram');
              panoProgram?.destroy();
              this.removeProgram(panoPreloadProgram);

              const newPanoProgram = panoPreloadProgram;
              newPanoProgram.isPreloadPano = false;
              newPanoProgram.alpha = 1;
              newPanoProgram.name = 'PanoProgram';
              this.addProgram(newPanoProgram);

              newPanoProgram.subscribe('render', this.render.bind(this));
              if (newPanoProgram.setOptimalLevel) newPanoProgram.setOptimalLevel(); // only if Cube Program
              if (newPanoProgram.loadTiles) newPanoProgram.loadTiles();

              if (__USE_EQUIRECT__ && newPanoProgram instanceof EquirectProgram) {
                const { secondaryPath, secondaryOptions } = nextSceneConfig.assetPathConfig as EquirectAssetPath;
                if (secondaryPath) {
                  newPanoProgram.loadSecondaryTexture(secondaryPath, secondaryOptions);
                }
              }
            }

            this.actualCameraPos[0] = -camBActual[0];
            this.actualCameraPos[1] = -camBActual[1];

            const sphereProgram = this.getProgram<SphereProgram>('SphereProgram');
            sphereProgram?.resetCameraOffsetForTransition();

            const hotSpotProgram2D = this.getProgram<HotSpotProgram>('HotSpotProgram2D');
            const hotSpotProgram3D = this.getProgram<HotSpotProgram3D>('HotSpotProgram3D');

            if (hotSpotProgram2D) hotSpotProgram2D.alpha = 1;
            if (hotSpotProgram3D) hotSpotProgram3D.alpha = 1;

            this.engine.toggleBlurmaskRendering(true, 0);
            this.engine.emit('scene.transition.end', sceneConfig);
            this.resizeRenderingBuffer(); // reset to retina
            this.resizeFramebufferTextures();
            this.render();
            resolve(true);
          },
          easing,
          duration
        );

        if (__DEV__) {
          // for autoplay debug
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.runAtTransition) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.runAtTransition(prevSceneConfig.sceneKey, nextSceneConfig.sceneKey);
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.runAtTransition2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.runAtTransition2(prevSceneConfig.sceneKey, nextSceneConfig.sceneKey);
          }

          // eslint-disable-next-line no-console
          console.log(`${prevSceneConfig.sceneKey}(${nextSceneConfig?.building}/${nextSceneConfig?.floor}) --> ${nextSceneConfig?.sceneKey} (${nextSceneConfig?.building}/${nextSceneConfig?.floor}) connected:${this.transitionConnected}  walls at:${Math.trunc(this.transitionWallEncounterPercentA * 100)}%/${Math.trunc(this.transitionWallEncounterPercentB * 100)}% travel angle:${Math.round(this.transitionDirection * 180 / Math.PI)}°`); // prettier-ignore
        }
      });
    }

    return Promise.resolve(true);
  }

  protected async preloadSphereProgram(): Promise<void> {
    const sphereProgram = this.getProgram<SphereProgram>('SphereProgram');
    if (sphereProgram) {
      await sphereProgram.preloadSpheres();
    }
  }

  /** Try to reload watermark and remove the forcePreview flag from pano program on success, affects only tiles rendering  */
  protected async preloadInterruptedWatermark(): Promise<void> {
    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (panoProgram && panoProgram.forcePreview) {
      panoProgram.forcePreview = true;

      if (this.engine.watermarkInterrupted) {
        if (this.tourConfigService.watermark) {
          this.createAndLoadWatermarkProgram(this.tourConfigService.watermark);
        }

        if (this.watermarkPromise) {
          const success = await this.watermarkPromise;
          panoProgram.forcePreview = !success;
        }
      }
    }
  }

  protected async preloadPanoProgram(sceneConfig: SceneConfig): Promise<void> {
    if (this.previousSceneConfig && this.gl) {
      const panoPreloadProgram = __USE_EQUIRECT__
        ? new EquirectProgram(this.gl, this.canvas, this, 'PanoPreloadProgram')
        : new CubeProgram(
            this.gl,
            this.canvas,
            this,
            this.assetConfig,
            this.engineConfig.colorCorrectionMode,
            'PanoPreloadProgram'
          );

      panoPreloadProgram.isPreloadPano = true;

      panoPreloadProgram.subscribe('error.set', (err) => {
        this.engine.emit('error.set', err);
      });

      panoPreloadProgram.subscribe('error.clear', (err) => {
        this.engine.emit('error.clear', err);
      });

      const yawOffset = Utils.toRad(sceneConfig.camera[3]);

      panoPreloadProgram?.init();
      this.updateProgramView(panoPreloadProgram);

      panoPreloadProgram.yawOffset = yawOffset;

      const panoProgram = this.getProgram<PanoProgram>('PanoProgram');
      panoProgram?.abortPending();

      if (panoProgram?.forcePreview) {
        panoPreloadProgram.forcePreview = true;
      }

      // In case of interrupted watermark, try again to load the watermark before the tiles
      await this.preloadInterruptedWatermark();

      if (panoPreloadProgram instanceof CubeProgram) {
        await panoPreloadProgram.preload(sceneConfig.assetPathConfig as string);
      }

      if (panoPreloadProgram instanceof EquirectProgram) {
        const { primaryPath, primaryOptions } = sceneConfig.assetPathConfig as EquirectAssetPath;

        await panoPreloadProgram.preload(primaryPath, primaryOptions);
      }

      const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');

      if (measureToolProgram) {
        measureToolProgram.yawOffset = yawOffset;
        this.updateProgramView(measureToolProgram);
        measureToolProgram.abortPending();

        await measureToolProgram.loadScene(sceneConfig.sceneKey);
      }

      this.addProgram(panoPreloadProgram);
    }
  }

  // #endregion
  // #region private functions

  private updateProgramView(program_: AnyProgram): void {
    program_.fov = this.engine.fov;
    program_.pitch = this.engine.pitch;
    program_.yaw = this.engine.yaw;
    if (program_ instanceof MeasureToolProgram) program_.updateProjectionMatrix();
    if (program_ instanceof FloorPlan3DProgram) {
      program_.zoom = this.engine.zoomFloorPlan;
    }
  }

  private performanceInfoAndDebugTick(frameTimeStamp: number): void {
    if (__DEV_PANEL__) {
      if (this.debugControls?.recentlyPressed && this.pendingRedraws <= 0) {
        // force redraw if any keys are recently pressed
        this.pendingRedraws = 1;
      }

      this.debugControls?.tick(this.engine.yaw);
    }

    if (__PERF_MEASURER__) {
      this.updatePerformanceInfo(frameTimeStamp, () => {
        this.pendingRedraws += 3; // never stop rendering ... for better FPS measurement
      });
    }
  }

  /**
   * This is the main rendering loop
   * call `this.render` to do a complete redraw
   */
  private renderLoop(frameTimeStamp = 0): void {
    requestAnimationFrame(this.renderLoop);
    this.renderLoopBase(frameTimeStamp);
  }

  private renderLoopBase(frameTimeStamp = 0): boolean {
    // Render loop is never paused/resumed
    // Rendering is skipped if the scene is not ready
    if (this.isBusyPreloadScene) return false;

    // no slacking, render every frame
    if (this.renderMode === 'floorplan3D') this.pendingRedraws += 1; // @todo -- later think about this, should 3d floorplan ask for a render if it needs or just render it every frame and let it skip them when needed

    if (this.pendingResize) {
      this.pendingResize = false;
      this.engine.onResize();
    }

    this.engine.tickAnimations();
    this.clampPitch();
    this.performanceInfoAndDebugTick(frameTimeStamp);

    if (this.pendingRedraws <= 0) {
      this.previousFrameTimeStamp = frameTimeStamp;
      return false;
    }

    this.pendingRedraws -= 1;

    const { programs } = this;
    const programsLength = programs.length;

    for (let i = 0; i < programsLength; i += 1) {
      const currentProgram = programs[i];

      if (this.cameraMoved) this.updateProgramView(currentProgram);
      currentProgram.render(frameTimeStamp);
    }

    if (this.isInTransition) {
      this.previousFrameTimeStamp = frameTimeStamp;
      return true;
    }

    if (this.cameraMoved) this.engine.onSceneMoved();
    this.cameraMoved = false;

    if (this.resolveRenderOneFramePromise) {
      // Resolve pending promise
      this.resolveRenderOneFramePromise();
      this.resolveRenderOneFramePromise = null;
    }

    this.previousFrameTimeStamp = frameTimeStamp;
    this.cameraMoved = false;

    if (!this.notifiedFirstPaint) {
      this.engine.emit('scene.first.paint');
      this.notifiedFirstPaint = true;
    }

    return true;
  }

  private handleResize(): void {
    this.pendingResize = true;
    this.render();
  }

  private updateCameraPos(progress: number, from: number[], to: number[]): void {
    this.actualCameraPos[0] = -linearScale(progress, [0, 0.99], [from[0], to[0]]);
    this.actualCameraPos[1] = -linearScale(progress, [0, 0.99], [from[1], to[1]]);
  }

  /**
   * Rendering loop for use in video rendering
   */
  private async renderLoopAsyncFlushed(frameTimeStamp = 0): Promise<any> {
    requestAnimationFrame(this.renderLoopAsyncFlushed);

    // const frameResult = this.renderLoopBase(frameTimeStamp);
    const frameResult = this.renderLoopBase(frameTimeStamp);
    if (!frameResult) return false;

    return () => {
      this.gl?.finish();
      this.gl?.flush();
    };
  }

  private async prepPanoProgram(sceneConfig: SceneConfig): Promise<void> {
    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (!panoProgram) return;

    const yawOffset = Utils.toRad(sceneConfig.camera[3]);
    panoProgram.yawOffset = yawOffset;

    if (!__USE_EQUIRECT__ && panoProgram instanceof CubeProgram) {
      panoProgram.setOptimalLevel();
      await panoProgram.loadPreview(sceneConfig.assetPathConfig as string);
    } else {
      const { primaryPath, primaryOptions } = sceneConfig.assetPathConfig as EquirectAssetPath;
      await panoProgram.preload(primaryPath, primaryOptions);
    }

    const measureToolProgram = this.getProgram<MeasureToolProgram>('MeasureToolProgram');

    if (measureToolProgram) {
      measureToolProgram.yawOffset = yawOffset;
      measureToolProgram.updateProjectionMatrix();

      await measureToolProgram.loadScene(sceneConfig.sceneKey);
    }

    this.engine.isSceneLayoutReady = true;
    this.engine.emit('sceneLayoutReady');
  }

  // Clean/fast load without any transition animation and preview.jpg as the first texture
  private async initialConfigLoad(sceneConfig: SceneConfig): Promise<void> {
    this.engine.sceneTransition = false;
    this.updateIsInTransition();

    // Set transition camera to the current position in actual geometry
    this.actualCameraPos[0] = -sceneConfig.camera[0];
    this.actualCameraPos[1] = -sceneConfig.camera[1];

    await this.prepPanoProgram(sceneConfig);
    await this.prepHotSpotProgram(sceneConfig);

    const panoProgram = this.getProgram<PanoProgram>('PanoProgram');

    if (this.watermarkPromise) {
      // TODO: this resize affects the welcome screen layout for some reason if watermark is loading slowly or blocked and
      // scene layout and skin is already loaded. We should investigate why this is happening and fix it in a better way.
      window.dispatchEvent(new Event('resize'));
      const success = await this.watermarkPromise;
      if (panoProgram) panoProgram.forcePreview = !success;
    }

    // This way scene preview image and hot-spot assets are loaded before L2+ tiles
    // Doing them in parallel can cause network traffic jam
    if (panoProgram?.loadTiles) {
      panoProgram.loadTiles();
    }

    if (__USE_EQUIRECT__ && panoProgram instanceof EquirectProgram) {
      const { secondaryPath, secondaryOptions } = sceneConfig.assetPathConfig as EquirectAssetPath;
      if (secondaryPath) {
        panoProgram.loadSecondaryTexture(secondaryPath, secondaryOptions);
      }
    }

    this.previousSceneConfig = sceneConfig;

    this.engine.isEngineReady = true;
    this.cameraMoved = true;

    // This is necessary to trigger skin navigation update if new tour.json is loaded
    this.engine.emit('scene.transition.end', sceneConfig);
    this.engine.emit('scene.preload.end');
    this.engine.emit('ready');
    window.dispatchEvent(new Event('resize'));
  }

  private switchToPanoMode(): void {
    if (!__DEV_PANEL__) return;

    if (this.renderMode === 'pano') return;
    this.renderMode = 'pano';
    // console.log("pano program is always on, since tour starts in this mode, but imagine it didn't, we need to load pano program now if it not "); // prettier-ignore
    this.engine.switchController(new GestureController());

    this.render();
    const minPitchInPano = toRad(
      getMinMaxPitch(this.engine.fov, this.engine.getCanvasSize(), this.tourConfigService.minPitch).minPitch
    );
    const mustAnimate = this.engine.pitch < minPitchInPano;
    // if pitch is not in the allowed range for pano mode then animate it to the min allowed pitch === nearest allowed
    if (mustAnimate) {
      const animationDuration = Math.abs(this.engine.pitch - minPitchInPano) * 3333; // 1 rad in 3 sec
      // allow any pitch while animating
      this.minPitchClamp = -90;
      this.maxPitchClamp = 90;

      this.engine.startAnimation(
        'backgroundAnimation', // can't use 'look' or 'transition' as they will be stopped and recreated by other processes
        (progress) => {
          this.engine.pitch = linearScale(progress, [0, 1], [this.engine.pitch, minPitchInPano]);
          this.render();
        },
        () => {
          this.render();
          // correct pitch clamp values for pano mode (when animation is over)
          this.minPitchClamp = undefined;
          this.maxPitchClamp = undefined;
        },
        'easeOutQuad',
        animationDuration
      );
    } else {
      // no anim, set correct pitch clamp values for pano mode
      this.minPitchClamp = undefined;
      this.maxPitchClamp = undefined;
    }

    const { cameraCenter } = this.getProgram<FloorPlan3DProgram>('FloorPlan3DProgram')?.onDeactivate() ?? {};
    if (cameraCenter) {
      const x = cameraCenter[0];
      const y = cameraCenter[2]; // y in pano world is z in floorplan world
      const building = this.engine.activeSceneConfig.building;
      const floor = this.engine.activeSceneConfig.floor;
      const nearestPanoToFPCenter = this.tourConfigService.getSceneConfigByPosition(x, y, building, floor);
      if (nearestPanoToFPCenter) {
        this.loadConfig(nearestPanoToFPCenter, 0); // instant transition to closest pano
      }
    }
  }

  private switchToFloorPlan3DMode(): void {
    if (!__DEV_PANEL__) return;
    if (!window.location.search.includes('dev')) return;

    if (this.renderMode === 'floorplan3D') return;
    this.renderMode = 'floorplan3D';

    let floorPlanProgram = this.getProgram<FloorPlan3DProgram>('FloorPlan3DProgram');
    if (!floorPlanProgram) {
      const gl = this.gl as WebGL2RenderingContext;
      floorPlanProgram = new FloorPlan3DProgram( gl, this.canvas, this.assetConfig, this.tourConfigService.tourConfig, this, this.debugControls); // prettier-ignore
      this.addProgram(floorPlanProgram);
      floorPlanProgram.init();
    }

    const maxPitchInFP = toRad(floorPlanProgram.maxPitchClamp);
    const mustAnimate = this.engine.pitch > maxPitchInFP;
    // if pitch is not in the allowed range for floorplan mode then animate it to the max allowed pitch === nearest allowed
    if (mustAnimate) {
      const animationDuration = Math.abs(this.engine.pitch - maxPitchInFP) * 3333; // 1 rad in 3 sec
      // allow any pitch while animating
      this.minPitchClamp = -90;
      this.maxPitchClamp = 90;

      this.engine.startAnimation(
        'backgroundAnimation', // can't use 'look' or 'transition' as they will be stopped and recreated by other processes
        (progress) => {
          this.engine.pitch = linearScale(progress, [0, 1], [this.engine.pitch, maxPitchInFP]);
          this.render();
        },
        () => {
          this.render();
          // update pitch clamp values when animation is over
          const fpp = this.getProgram<FloorPlan3DProgram>('FloorPlan3DProgram');
          this.minPitchClamp = fpp?.minPitchClamp;
          this.maxPitchClamp = fpp?.maxPitchClamp;
        },
        'easeOutQuad',
        animationDuration
      );
    } else {
      // no anim, set correct pitch clamp values for floorplan mode
      this.minPitchClamp = floorPlanProgram.minPitchClamp;
      this.maxPitchClamp = floorPlanProgram.maxPitchClamp;
    }

    this.engine.switchController(new FloorPlan3DController(floorPlanProgram));
    floorPlanProgram.onActivate(this.engine.activeSceneConfig.camera);
    this.render();
  }

  // #endregion
}

export default Renderer;
