import type { AssetConfig, HotSpot2DType, HotSpotConfig, Subscription, Theme } from '@g360/vt-types';
import { identityM3, translateM3 } from '@g360/vt-utils';
import urljoin from 'url-join';

import { fetchImage } from '../../common/Utils';
import {
  createTexture,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { HotSpotEditAction, HotSpotTextureGroup, ProgramName } from '../../types/internal';
import HotSpot from './HotSpot';
import fragmentShaderSource from './hotSpot.fs.glsl';
import vertexShaderSource from './hotSpot.vs.glsl';

// TODO(uzars): improve type-checking
type HotSpotProgramEvents = 'render' | 'onHotSpot';

class HotSpotProgram {
  name: ProgramName;
  orderIndex = 0;

  yaw = 0;
  pitch = 0;
  fov = 0;

  alpha = 1.0;
  yawOffset = 0;

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private theme: Theme;
  private assetConfig: AssetConfig;

  /** In this mode only info hot-spot assets are downloaded and rendered
   * Assets are used from 3d hot-spots, not the default v1 assets
   */
  private infoHotSpotMode = false;

  private textureCache: Map<string, HotSpotTextureGroup> = new Map();

  private vertexBuffer: WebGLBuffer | null;
  private textureBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private textureAttribute = 0;
  private perspectiveUniform: WebGLUniformLocation | null = null;
  private alphaUniform: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private targetCssPixelSize = 50;

  private boundingRect: DOMRect;
  private hotSpots: HotSpot[] = [];
  private ready = false;
  private texturesLoading = false;
  private interactive = true;

  private reloadTexturesTimeout: ReturnType<typeof setTimeout> | null = null;

  private listeners: { [key: string]: ((payload) => void)[] } = {};

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    theme: Theme,
    assetConfig: AssetConfig,
    name?: ProgramName,
    useInfoHotSpot3dAssets = false
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.theme = theme;
    this.assetConfig = assetConfig;
    this.name = name ?? 'HotSpotProgram2D';
    this.infoHotSpotMode = useInfoHotSpot3dAssets;

    if (useInfoHotSpot3dAssets) {
      this.targetCssPixelSize = 48;
    }

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.boundingRect = this.canvas.getBoundingClientRect();

    this.updateAllHotSpotSize = this.updateAllHotSpotSize.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  /** Disables/enables current scenes hot-spot rendering and interaction */
  public setHotSpotsDisabled(flag: boolean, hotSpotType?: HotSpot2DType): void {
    this.hotSpots.forEach((hotSpot, index) => {
      if (hotSpotType && hotSpot.type !== hotSpotType) return;

      this.hotSpots[index].disabled = flag;
    });

    this.emit('render');
  }

  getBoundingX(): number {
    return this.boundingRect.left || 0;
  }

  getBoundingY(): number {
    return this.boundingRect.top || 0;
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.perspectiveUniform = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
    }
    window.addEventListener('resize', this.handleWindowResize);

    this.listenOnDevicePixelRatio();

    this.ready = true;
  }

  destroy(): void {
    window.removeEventListener('resize', this.handleWindowResize);
    this.destroyEventEmitter();

    Object.values(this.textureCache).forEach((textureGroup: HotSpotTextureGroup) => {
      this.gl.deleteTexture(textureGroup.default);
      this.gl.deleteTexture(textureGroup.hover);

      this.textureCache.clear();
    });
  }

  async loadTextureAsync(imagePath: string, textureObject: WebGLTexture | null): Promise<void> {
    // This just resizes the image before passing it to the texture to avoid bad webgl rescale filtering
    // For browsers without bitMap support, the image will be loaded as is and may look worse
    const bitMapOptions: ImageBitmapOptions = {
      resizeHeight: this.targetCssPixelSize * window.devicePixelRatio,
      resizeWidth: this.targetCssPixelSize * window.devicePixelRatio,
      resizeQuality: 'high',
    };

    const textureImage = await fetchImage(imagePath, null, bitMapOptions);

    if (!textureImage) return;

    setTextureFromImage(this.gl, textureObject, textureImage, { useAlphaChannel: true });
  }

  async createTextureObject(imagePath: string): Promise<WebGLTexture | null> {
    const texture = createTexture(this.gl);

    await this.loadTextureAsync(imagePath, texture);

    return texture;
  }

  async preLoadTextures(): Promise<void> {
    if (this.infoHotSpotMode) {
      return this.preloadTexturesInfoHotSpotMode();
    }

    return this.preloadTexturesDefault();
  }

  async updateHotSpotTheme(theme: Theme): Promise<void> {
    if (this.theme === theme) return;

    this.theme = theme;
    this.texturesLoading = false;
    await this.preLoadTextures();
    this.emit('render');
  }

  async loadHotSpots(hotSpots: HotSpotConfig[], use3dMode = false): Promise<void> {
    this.canvas.style.cursor = 'default';
    this.hotSpots = [];

    // Preload textures if not loaded
    if (!this.texturesLoading) {
      this.texturesLoading = true;
      await this.preLoadTextures();
    }

    hotSpots.forEach((hotSpotConfig) => {
      // Just in case in 3d hot-spot mode filter out 2d hot-spots except info-hotspots
      const allowedType = use3dMode ? 'hotspot-info' : 'hotspot-';

      if (hotSpotConfig.type.includes(allowedType)) {
        this.hotSpots.push(new HotSpot(hotSpotConfig, this.canvas, this.targetCssPixelSize));
      }
    });

    this.emit('render');
  }

  updateHotSpot(nextHotSpotConfig: HotSpotConfig, sceneKey: string, action: HotSpotEditAction): void {
    // NOTE: sceneKey is not used here, but it is used in the 3d hotspot program

    switch (action) {
      case 'add': {
        const existingHotSpotIndex = this.hotSpots.findIndex(
          (hotSpotData) => hotSpotData.originalConfig.id === nextHotSpotConfig.id
        );

        if (existingHotSpotIndex === -1) {
          this.hotSpots.push(new HotSpot(nextHotSpotConfig, this.canvas, this.targetCssPixelSize));
        } else {
          // eslint-disable-next-line no-console
          console.warn('Trying to add hotspot with existing id:', nextHotSpotConfig.id);
        }
        break;
      }

      case 'update': {
        this.hotSpots.forEach((hotSpotObject, index) => {
          if (hotSpotObject.originalConfig.id === nextHotSpotConfig.id) {
            this.hotSpots[index] = new HotSpot(nextHotSpotConfig, this.canvas, this.targetCssPixelSize);
          }
        });
        break;
      }

      case 'delete': {
        this.hotSpots = this.hotSpots.filter((hotSpot) => hotSpot.originalConfig.id !== nextHotSpotConfig.id);
        break;
      }

      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown updateHotSpot action:', action);
        break;
    }

    this.emit('render');
  }

  handleWindowResize(): void {
    this.updateAllHotSpotSize();

    this.boundingRect = this.canvas.getBoundingClientRect();
  }

  updateAllHotSpotSize(): void {
    this.hotSpots.forEach((hotSpot) => {
      hotSpot.updateSize();
    });
  }

  getHotSpots(): HotSpot[] {
    return this.hotSpots;
  }

  enableHotSpots(flag = true): void {
    if (flag === false) {
      this.hotSpots.forEach((hotSpot_) => {
        hotSpot_.hover = false;
      });

      this.canvas.style.cursor = 'default';
    }

    this.interactive = flag;
  }

  listenOnDevicePixelRatio(): void {
    // Have to recreate media query and event listener after each change
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
    matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`).addEventListener(
      'change',
      () => {
        if (this.reloadTexturesTimeout) clearTimeout(this.reloadTexturesTimeout);
        this.reloadTexturesTimeout = setTimeout(() => {
          this.preLoadTextures();
          this.reloadTexturesTimeout = null;
        }, 250);
        this.listenOnDevicePixelRatio();
      },
      { once: true }
    );
  }

  subscribe(event: HotSpotProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  unsubscribe(event: HotSpotProgramEvents, fn: (payload) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  render(): void {
    if (!this.gl || !this.ready || this.renderer.isInTransition) return;

    this.draw();
  }

  private draw(): void {
    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.hotSpots.forEach((hotSpot) => {
      // TODO(uzars): Don't render info-hot-spot on small screens - no space for the info ballon (w>245, h<170)

      // In infoHotSpot mode there are no assets for other types, so skip rendering
      if (this.infoHotSpotMode && !hotSpot.type.startsWith('hotspot-info')) return;
      if (hotSpot.disabled) return;

      // Calculate screen space position for the hotspot
      hotSpot.updatePosition(this.pitch, this.yaw + this.yawOffset, this.fov);

      if (hotSpot.clipSpacePos && hotSpot.clipSpaceSize) {
        // Translate matrix to move geometry to the proper position
        const hotSpotMatrix = translateM3(
          identityM3(),
          hotSpot.clipSpacePos.x - hotSpot.clipSpaceSize.width / 2,
          hotSpot.clipSpacePos.y - hotSpot.clipSpaceSize.height / 2
        );

        this.gl.uniformMatrix3fv(this.perspectiveUniform, false, hotSpotMatrix);
        this.gl.uniform1f(this.alphaUniform, this.alpha);

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, HotSpot.textureCoords, this.gl.DYNAMIC_DRAW);
        this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, hotSpot.geometry, this.gl.DYNAMIC_DRAW);
        this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

        const activeTexture = hotSpot.hover
          ? this.textureCache.get(hotSpot.type)?.hover
          : this.textureCache.get(hotSpot.type)?.default;

        if (activeTexture) {
          this.gl.activeTexture(this.gl.TEXTURE0);
          this.gl.bindTexture(this.gl.TEXTURE_2D, activeTexture);

          this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
        }
      }
    });

    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  /** Destroy emitter by removing all subscribers */
  private destroyEventEmitter(): void {
    this.listeners = {};
  }

  private emit(event: HotSpotProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }

  private async preloadTexturesDefault(): Promise<void> {
    const basePath = urljoin(this.assetConfig.assetPath, 'hotspots/v1');
    const promiseList: Promise<void>[] = [];

    for (let i = 0; i < HotSpot.types.length; i += 1) {
      const type = HotSpot.types[i];

      promiseList.push(
        Promise.all([
          this.createTextureObject(urljoin(basePath, this.theme, 'normal', `${type}-default.png`)),
          this.createTextureObject(urljoin(basePath, this.theme, 'normal', `${type}-default-hover.png`)),
        ]).then(([defaultTexture, hoverTexture]) => {
          // Put the texture in cache after it is downloaded so there is no missing texture while switching themes
          this.textureCache.set(type, { default: defaultTexture, hover: hoverTexture });
        })
      );
    }

    // Edit info-hotspot icon
    // eslint-disable-next-line no-await-in-loop
    promiseList.push(
      this.createTextureObject(urljoin(basePath, 'dark/edit/hotspot-info-edit.png')).then((texture) => {
        this.textureCache.set('hotspot-info-edit', { default: texture, hover: texture });
      })
    );

    await Promise.all(promiseList);
  }

  private async preloadTexturesInfoHotSpotMode(): Promise<void> {
    const basePath = urljoin(this.assetConfig.assetPath, 'hotspots/v4_themes', this.theme);

    await Promise.all([
      this.createTextureObject(urljoin(basePath, 'info.png')),
      this.createTextureObject(urljoin(basePath, 'infoHover.png')),
      this.createTextureObject(urljoin(basePath, 'infoEdit.png')),
    ]).then(([defaultTexture, hoverTexture, editTexture]) => {
      // Put the texture in cache after it is downloaded so there is no missing texture while switching themes
      this.textureCache.set('hotspot-info', { default: defaultTexture, hover: hoverTexture });
      this.textureCache.set('hotspot-info-edit', { default: editTexture, hover: editTexture });
    });
  }
}

export default HotSpotProgram;
