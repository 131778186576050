import { ErrorLogger } from '@g360/vt-utils/';
// timestamp format: 2023-06-29T10:00:28Z
function formatTimestamp(timestamp, options) {
    const { timestampFormat = 12, locales = 'en', timestampTimezone = 'UTC' } = options || {};
    if (Number.isNaN(Date.parse(timestamp))) {
        // eslint-disable-next-line no-console
        console.error(`Invalid timestamp: ${timestamp}`);
        ErrorLogger.captureMessage(`Invalid timestamp`);
        return null;
    }
    const date = new Date(timestamp);
    const dateOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: timestampTimezone,
    };
    const timeOptions = Object.assign({ hour: '2-digit', minute: '2-digit', timeZone: timestampTimezone }, (timestampFormat === 12
        ? {
            hour12: true,
        }
        : {
            hourCycle: 'h23',
        }));
    let dateString = '';
    let timeString = '';
    // Format the date and time with current browser locale
    try {
        dateString = date.toLocaleDateString(locales, dateOptions);
    }
    catch (e) {
        ErrorLogger.captureException(e);
        return null;
    }
    try {
        timeString = date.toLocaleTimeString(locales, timeOptions).toUpperCase();
    }
    catch (e) {
        ErrorLogger.captureException(e);
        return null;
    }
    return `${dateString} ${timeString}`;
}
export default formatTimestamp;
