import { jsx as _jsx } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import ToggleIcon from '../icons/ToggleIcon';
const MiniMapMenuButton = ({ onToggle }) => {
    const { layoutService } = useServices();
    const { isNavigationOpen } = useReactive(layoutService, ['isNavigationOpen']);
    const { analytics } = useGlobalContext();
    return (_jsx("button", { type: "button", className: "btn-theme-primary h-10 w-10 min-w-[2.5rem] p-0\n        disabled:cursor-not-allowed disabled:hover:bg-opacity-theme-low", onClick: () => {
            layoutService.toggleNavigation();
            if (onToggle)
                onToggle(layoutService.isNavigationOpen);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'MAP', layoutService.isNavigationOpen ? 'Open' : 'Close');
        }, "data-testid": "toggle-btn", children: _jsx(ToggleIcon, { className: "h-8 w-8", isOpen: isNavigationOpen }) }));
};
export default MiniMapMenuButton;
