import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import Link from '../../../common/Link';
import Text from '../../../common/Text';
import { useCookieConsentContext } from '../../../contexts/useCookieConsentContext';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServicesContext from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import UnitSwitch from './UnitSwitch';
// TODO: Change the terms and conditions link
const Footer = ({ className, noCookieConsent, disabledDisclaimerAndUnitSwitch, onFpLearnMoreClick }) => {
    const { showCookiePreferencesLink, setShowCookieModal } = useCookieConsentContext();
    const t = useTranslation();
    const { analytics } = useGlobalContext();
    const { tourConfigService } = useServicesContext();
    const { projectDataConfig } = useReactive(tourConfigService, ['projectDataConfig']);
    // which FP standard was used
    const standard = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorplanStandard;
    const fpStandardInfo = useMemo(() => {
        if (standard === 'international') {
            return t('footer.international-standard');
        }
        if (standard === 'german') {
            return t('footer.german-standard');
        }
        if (standard === 'default') {
            return t('footer.default-standard');
        }
        return '';
    }, [standard, t]);
    if (noCookieConsent && disabledDisclaimerAndUnitSwitch)
        return null;
    const twLinkMarginTop = disabledDisclaimerAndUnitSwitch ? 'mt-0' : 'mt-6';
    return (_jsxs("div", { className: `items-center px-8 py-6 ${className}`, children: [!disabledDisclaimerAndUnitSwitch && (_jsx(_Fragment, { children: standard === undefined ? (_jsx(Text, { size: "tiny", children: t('footer.disclaimer') })) : (_jsxs("div", { className: "select-text font-secondary text-xs font-normal text-modal-gray-dark", children: [_jsx("span", { children: fpStandardInfo }), ' ', _jsx("button", { type: "button", className: "cursor-pointer underline", onClick: onFpLearnMoreClick, children: t('footer.learn-more') })] })) })), _jsxs("div", { className: `flex flex-wrap items-center ${twLinkMarginTop}`, children: [showCookiePreferencesLink && !noCookieConsent && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    _jsx(Link, { onClick: () => {
                            setShowCookieModal(true);
                            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', 'Cookie preferences');
                        }, testId: "cookie-preference-link", children: t('footer.cookie-preference') })), _jsx("span", { className: "grow" }), !disabledDisclaimerAndUnitSwitch && _jsx(UnitSwitch, {})] })] }));
};
export default Footer;
