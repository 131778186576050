import type { Theme } from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';

import { Utils } from '../..';
import {
  createTexture,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import fragmentShaderSource from './colorFilter.fs.glsl';
import vertexShaderSource from './colorFilter.vs.glsl';

class ColorFilterProgram {
  private static readonly textureCoords = new Float32Array([0, 1, 1, 1, 1, 0, 0, 0]);
  private static readonly geometry = new Float32Array([
    -1,
    1, // top left
    1,
    1, // top right
    1,
    -1, // bottom right
    -1,
    -1, // bottom left
  ]);

  name: ProgramName;
  orderIndex = 0;

  yaw = 0; // unused
  pitch = 0; // unused
  fov = Utils.toRad(120); // unused

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private theme: Theme;

  private vertexBuffer: WebGLBuffer | null;
  private textureBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private textureAttribute = 0;
  private alphaUniform: WebGLUniformLocation | null = null;
  private resolutionUniform: WebGLUniformLocation | null = null;
  private kernelUniform: WebGLUniformLocation | null = null;
  private texture: WebGLTexture | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private ready = false;
  private alpha = 1.0;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    theme: Theme,
    name?: ProgramName
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.renderer = renderer;
    this.theme = theme;
    this.name = name ?? 'ColorFilterProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.setTheme(this.theme);
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
    }

    this.ready = true;
  }

  setTheme(theme: Theme): void {
    const color = theme === 'dark' ? [35, 31, 32] : [255, 255, 255];
    const alpha = linearScale(0.8, [0, 1], [0, 255]);
    this.texture = createTexture(this.gl, [...color, alpha], true);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  destroy(): void {}

  render(): void {
    if (!this.gl || !this.ready) return;

    const { renderer } = this;

    if (!renderer.engine.overlay || renderer.isInTransition) return;

    this.draw();
  }

  private draw(): void {
    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, ColorFilterProgram.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, ColorFilterProgram.geometry, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);

    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default ColorFilterProgram;
