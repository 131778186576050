var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Input from '../../../../common/Input';
import Label from '../../../../common/Label';
import Text from '../../../../common/Text';
import TextButton from '../../../../common/TextButton';
import PhoneInput from '../../../../components/PhoneInput';
import useGlobalContext from '../../../../contexts/useGlobalContext';
import { useTranslation } from '../../../../contexts/useLocalizationContext';
import useServices from '../../../../contexts/useServices';
import useReactive from '../../../../hooks/useReactive';
import MessageIcon from '../../../../icons/MessageIcon';
import validator from '../../../../utils/validator';
import Textarea from '../../common/Textarea';
import { gapiPost } from './gapiUtils';
import useSessionStorageFormState from './useSessionStorageFormState';
const initialFormState = {
    name: { value: '', error: null },
    email: { value: '', error: null },
    message: { value: '', error: null },
    phone: { value: '', error: null },
};
function Contact({ hasOverflowY }) {
    const t = useTranslation();
    const [sendingStatus, setSendingStatus] = useState(null);
    const [sending, setSending] = useState(false);
    const { appContext, analytics } = useGlobalContext();
    const { tourConfigService, layoutService, phoneNumberService } = useServices();
    const { projectDataConfig } = useReactive(tourConfigService, ['projectDataConfig']);
    const contactFormFields = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contactFormFields;
    const { formState, setFormState } = useSessionStorageFormState('vt-cta-form', initialFormState);
    const validateInput = useMemo(() => validator(phoneNumberService, t), [phoneNumberService, t]);
    const fields = useMemo(() => {
        if (!contactFormFields)
            return null;
        const fieldsRecord = {};
        // Reduce contactFormFields to object with field names as keys and required and type as values
        return contactFormFields.reduce((acc_, field) => {
            acc_[field.name] = field;
            return acc_;
        }, fieldsRecord);
    }, [contactFormFields]);
    function handleInputChange(value, inputField) {
        if (!fields)
            return;
        setFormState((prevState) => {
            const nextInputState = Object.assign(Object.assign({}, prevState[inputField]), { value, error: null });
            return Object.assign(Object.assign({}, prevState), { [inputField]: nextInputState });
        });
    }
    function handleFieldError(inputField, error) {
        if (!fields)
            return;
        setFormState((prevState) => {
            const nextInputState = Object.assign(Object.assign({}, prevState[inputField]), { error });
            return Object.assign(Object.assign({}, prevState), { [inputField]: nextInputState });
        });
    }
    const handleBack = () => {
        if (sendingStatus === 'failed') {
            setSendingStatus(null);
        }
        else {
            layoutService.showCTA = false;
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'CTA', 'Back button');
        }
    };
    const handleSend = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        if (!fields)
            return;
        let stopWithError = false;
        const filledFields = [];
        Object.values(fields).forEach((field) => {
            const nextInputState = formState[field.name];
            if (validateInput[field.name]) {
                nextInputState.error = validateInput[field.name](formState[field.name].value, field.required);
                if (nextInputState.error)
                    stopWithError = true;
                else
                    filledFields.push(field.name);
            }
            setFormState(Object.assign(Object.assign({}, formState), { [field.name]: nextInputState }));
        });
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'CTA', sendingStatus === 'failed' ? 'Retry' : 'Send', {
            filled_fields: filledFields,
        });
        if (stopWithError)
            return;
        setSending(true);
        // Reduce formState to object with only values
        const formData = Object.entries(formState).reduce((acc_, [key, value]) => {
            acc_[key] = value.value;
            return acc_;
        }, {});
        const body = {
            project_id: tourConfigService.tourConfig.projectId,
        };
        if (formData.name)
            body.contact_name = formData.name;
        if (formData.email)
            body.email = formData.email;
        if (formData.phone) {
            const phoneNumber = phoneNumberService.validatePhoneNumber(formData.phone);
            if ((phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.isValid) && phoneNumber.nationalNumber !== '')
                body.phone_number = phoneNumber.number;
        }
        if (formData.message)
            body.message = formData.message;
        const res = yield gapiPost('cta', body);
        if (res.ok) {
            setSendingStatus('success');
            setFormState(initialFormState);
            setSending(false);
            return;
        }
        // Handle errors
        if (res.body) {
            if ((_a = res.body) === null || _a === void 0 ? void 0 : _a.project_id) {
                // eslint-disable-next-line no-console
                console.error('project_id:', (_b = res.body) === null || _b === void 0 ? void 0 : _b.project_id);
                // Nothing we can do if missing projectId
                setSendingStatus('failed');
                setSending(false);
                return;
            }
            const errorKeys = Object.keys(res.body);
            // Set the backed error messages for each field
            errorKeys.forEach((key) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (key === 'phone_number') {
                    handleFieldError('phone', (_c = (_b = (_a = res.body) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : null);
                }
                if (key === 'email') {
                    handleFieldError('email', (_f = (_e = (_d = res.body) === null || _d === void 0 ? void 0 : _d[key]) === null || _e === void 0 ? void 0 : _e[0]) !== null && _f !== void 0 ? _f : null);
                }
                if (key === 'non_field_errors') {
                    // TODO: for now this is placed above the submit button, but maybe need to update the design
                    if (((_h = (_g = res.body) === null || _g === void 0 ? void 0 : _g[key]) === null || _h === void 0 ? void 0 : _h[0]) === 'Either email or phone number is required.') {
                        handleFieldError('phone', t('contact.error-phone'));
                        handleFieldError('email', t('contact.error-email'));
                    }
                }
            });
            setSendingStatus(null);
            setSending(false);
            return;
        }
        setSendingStatus('failed');
        setSending(false);
        // eslint-disable-next-line no-console
        console.error((_c = res.statusText) !== null && _c !== void 0 ? _c : res);
    });
    const disabledSend = Object.values(formState).some(({ error }) => !!error) ||
        !tourConfigService.tourConfig.projectId ||
        (!!sendingStatus && sendingStatus !== 'failed') ||
        appContext === 'editor';
    if (!fields)
        return null;
    const twContentTopPadding = hasOverflowY ? 'pt-4' : 'pt-2';
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: `relative px-8 ${twContentTopPadding}`, children: [_jsx("div", { className: "flex flex-col gap-4", children: _jsxs(_Fragment, { children: [fields.name && (_jsx(Input, { className: "h-14 w-full max-w-full rounded-lg border", label: t('contact.label-name'), type: fields.name.type, value: formState.name.value, error: formState.name.error, onChange: (e) => {
                                        handleInputChange(e.target.value, fields.name.name);
                                    }, testId: "name-input" })), fields.email && (_jsx(Input, { className: "h-14 w-full max-w-full rounded border", type: "text", label: t('contact.label-email'), value: formState.email.value, error: formState.email.error, onChange: (e) => {
                                        handleInputChange(e.target.value, fields.email.name);
                                    }, testId: "email-input" })), fields.phone && (_jsx(PhoneInput, { value: formState.phone.value, error: formState.phone.error, onChange: (value) => {
                                        handleInputChange(value, fields.phone.name);
                                    } })), fields.message && (_jsx(Textarea, { placeholder: `${t('contact.placeholder-message')} ${fields.message.required ? '' : t('contact.placeholder-message-optional')}`, value: formState.message.value, error: formState.message.error, onChange: (e) => {
                                        handleInputChange(e.target.value, fields.message.name);
                                    }, testId: "message-input" }))] }) }), sendingStatus && (_jsxs("div", { className: "absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center bg-white p-8 text-center", children: [_jsx("span", {}), _jsx(MessageIcon, { className: "stroke-modal-dark mx-auto mb-5 h-16 w-24", success: sendingStatus === 'success' }), _jsx(Label, { className: "mb-2", size: "normal", children: {
                                    success: t('contact.success1'),
                                    failed: t('contact.failed1'),
                                }[sendingStatus] }), _jsx(Text, { size: "medium", children: {
                                    success: t('contact.success2'),
                                    failed: t('contact.try-again'),
                                }[sendingStatus] })] }))] }), _jsxs("div", { className: "warp relative mt-12 flex flex-row flex-wrap-reverse justify-end gap-5 px-8 pb-5", children: [_jsx(TextButton, { className: "@xl:w-auto h-10 w-full max-w-full rounded-lg border", variant: "secondary", onClick: handleBack, testId: "back-btn", children: t('contact.back') }), _jsx(TextButton, { className: "@xl:w-auto h-10 w-full max-w-full rounded-lg", disabled: disabledSend, onClick: handleSend, loading: sending, tooltip: appContext === 'editor' ? 'Not available in editor' : undefined, testId: "send-btn", children: sendingStatus === 'failed' ? t('contact.retry') : t('contact.send') })] })] }));
}
export default Contact;
