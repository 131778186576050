import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import Modal from '../../../common/Modal';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import useResizeObserver from '../../../hooks/useResizeObserver';
const MeasureDisclaimerModal = () => {
    const { layoutService } = useServices();
    const { showMeasureDisclaimerModal } = useReactive(layoutService, ['showMeasureDisclaimerModal']);
    const scrollRef = useRef(null);
    const [hasOverflowY, setHasOverflowY] = useState(false);
    const t = useTranslation();
    const handleClose = () => {
        layoutService.showMeasureDisclaimerModal = false;
    };
    const handleOutside = () => {
        layoutService.showMeasureDisclaimerModal = false;
    };
    const handleResize = () => {
        var _a, _b;
        const osInstance = (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.osInstance();
        setHasOverflowY((_b = osInstance === null || osInstance === void 0 ? void 0 : osInstance.state().hasOverflow.y) !== null && _b !== void 0 ? _b : false);
    };
    useResizeObserver(handleResize, scrollRef, true, 5);
    const twContentTopPadding = hasOverflowY ? 'pt-6' : 'pt-2';
    return (_jsx(Modal, { className: "z-measure-disclaimer-modal", show: showMeasureDisclaimerModal, title: t('measure-tool.disclaimer-modal-title'), showCloseButton: true, onClose: handleClose, onOutsideClick: handleOutside, ref: scrollRef, children: _jsx("div", { className: `px-8 pb-8 ${twContentTopPadding}`, children: _jsx("p", { className: "font-secondary text-base font-normal leading-7 text-modal-gray-dark", children: t('measure-tool.disclaimer-modal-text') }) }) }));
};
export default MeasureDisclaimerModal;
