import type {
  Centimeter,
  ClipSpace,
  HotSpot3DConfig,
  HotSpot3DType,
  HotSpotInfoContent,
  Pixel,
  Pos,
  Radian,
  Size,
  TourConfig,
} from '@g360/vt-types';

import RendererDebug from '../../common/RendererDebug';
import { toRad } from '../../common/Utils';
import { Utils } from '../../index';
import type { HotSpot3DSprite } from '../../types/internal';
import type HotSpotProgram3D from './index';

class HotSpot3D {
  public readonly position: Centimeter[]; // in 3D space
  public readonly type: HotSpot3DType;
  public readonly hidden: boolean;
  public readonly obstructed: boolean;
  public readonly noArrow: boolean;
  public readonly infoContent: HotSpotInfoContent | null;
  public readonly target: string;

  // managed by the controller
  public hover = false;

  // managed by HotSpot3DProgram (or logic subclass)
  public drawArrowFadeOutTTL = 0;
  public drawArrowFadeInTTL = 0;
  public drawArrowFadeProximityTTL = 0;
  public drawArrowSlotDelayTTL = 0;
  public drawArrowFadeCenterTTL = 0;
  public drawingDeterminantAngle = 0;
  public drawingDeterminantDist = 0;
  public invisibleHotSpot = false;
  public arrow: HotSpot3DSprite | null = null; // arrow sprite, if one is currently drawn for this hotspot
  public outside = false;
  public positionPx: Pos<Pixel> | null = null;
  public clipSpaceSize: Size = { width: 0, height: 0 };
  public clipSpacePos: Pos<ClipSpace> | null = null;
  public clipSpacePosAround: Pos<ClipSpace>[] | null = null;
  public proximityScale = 1;
  public readonly rotation: Radian[];
  public arrowClipSpaceSize: Size = { width: 0, height: 0 }; // params of its corresponding arrow (for hovering)
  public arrowClipSpacePos: Pos<ClipSpace> | null = null;
  public arrowPosPx: Pos<Pixel> | null = null;
  public arrowVisible = false;
  public closestHsToArrow: HotSpot3D | null = null; // this or any other hotspot that is closest to the arrow that belongs to this hotspot (set only if screen angles are used)
  public closestHsToArrowDistance: number | null = null;
  public closestHsToArrowDirection: number[] | null = null; // normalized direction
  public disabled = false; // If true, rendering and interaction is disabled
  public originalConfig: HotSpot3DConfig; // Needed for hot spot editing
  public id?: string | number; // Needed for hot spot editing
  public distanceToCamera = 0;
  public farAway = false; // don't draw and ignore hovers on far away hotspots (arrow behaviour is not changed) (set only for direct HS)
  public obstructedByHs = false; // don't draw or hover, only for non-direct HS (not 'normal')
  public obstructedByHsTTL = 0; // animation timer for obstructed HS

  constructor(hotSpotProgram3D: HotSpotProgram3D, hotSpotData: HotSpot3DConfig) {
    this.originalConfig = hotSpotData;
    this.id = hotSpotData.id;

    const tourConfig = hotSpotProgram3D.tourConfig || ({} as TourConfig);
    const currentPanoKey = hotSpotProgram3D.currentPanoKey;
    const cameraPosition = hotSpotProgram3D.cameraPosition;
    const sceneConfigCurrent = tourConfig.scenes[currentPanoKey];
    const sceneConfigTarget = tourConfig.scenes[hotSpotData.target];

    let pos: number[];
    if (hotSpotData.pos) {
      pos = [hotSpotData.pos[0], hotSpotData.pos[1], hotSpotData.pos[2] * -1]; // .json thinks +z is up, but it's down in our geometry
    } else {
      // if not known calculate from sceneConfig
      let cameraHeight = 137;

      // measure actual distance to the floor
      const geom =
        tourConfig.buildings?.[sceneConfigCurrent.building]?.floors[sceneConfigCurrent.floor as string]?.geometry[0];
      if (geom) {
        const floor = Utils.getCameraWallCrossPointGeneric(geom, sceneConfigTarget.camera, [
          cameraPosition[0],
          cameraPosition[1],
          cameraPosition[2] - 5000,
        ]);
        if (floor && floor.length > 2) {
          cameraHeight = -floor[2];
        }
      }

      const overrideCameraHeight = RendererDebug.getDebugParam('cameraHeight', 0);
      if (overrideCameraHeight > 0) {
        cameraHeight = overrideCameraHeight;
      }
      pos = [sceneConfigTarget.camera[0], sceneConfigTarget.camera[1], sceneConfigTarget.camera[2] + cameraHeight];
    }

    this.type = hotSpotData.type;
    this.hidden = hotSpotData.hidden;
    this.obstructed = hotSpotData.obstructed;
    this.noArrow = !!hotSpotData.noArrow;
    this.target = hotSpotData.target;
    this.position = pos;
    this.infoContent = { ops: hotSpotData.content };

    this.rotation = [0, 0, 0];

    // billboard hotspots
    if (this.type !== 'normal') {
      this.rotation = [toRad(90), 0, 0];
    }
  }
}

export default HotSpot3D;
