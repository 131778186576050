var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import noop from 'lodash/noop';
import React, { useCallback, useState } from 'react';
import Link from '../../../common/Link';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import CodeInput from '../components/CodeInput';
import { genericGatedTourErrorMessage } from '../utils/gatedTourUtils';
import VerificationFailedError from '../utils/VerificationFailedError';
/**
 * Default valid code in local and testing environments is `00000`.
 */
const VerifyCodeView = React.memo(({ onCodeVerified = noop }) => {
    const t = useTranslation();
    const { gatedTourService, layoutService } = useServices();
    const { analytics } = useGlobalContext();
    const { email, requestEmailCooldownEmail, requestEmailCooldown } = useReactive(gatedTourService, [
        'email',
        'verificationCode',
        'requestEmailCooldownEmail',
        'requestEmailCooldown',
    ]);
    const [verificationCodeError, setVerificationCodeError] = useState('');
    const onVerificationCodeChange = useCallback((newVerificationCode) => __awaiter(void 0, void 0, void 0, function* () {
        gatedTourService.verificationCode = newVerificationCode;
        setVerificationCodeError('');
        if (newVerificationCode.length !== 5)
            return;
        try {
            yield gatedTourService.verifyCode();
        }
        catch (error) {
            if (error instanceof VerificationFailedError) {
                gatedTourService.verificationCode = '';
                setVerificationCodeError('gated-tour.code-invalid');
                return;
            }
            layoutService.addToastMessage(error instanceof Error ? error.message : genericGatedTourErrorMessage);
            return;
        }
        onCodeVerified();
    }), [gatedTourService, layoutService, onCodeVerified]);
    const onResendCodeClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Resend code');
        try {
            yield gatedTourService.requestEmail();
        }
        catch (error) {
            layoutService.addToastMessage(error instanceof Error ? error.message : genericGatedTourErrorMessage);
            return;
        }
        layoutService.addToastMessage(t('gated-tour.code-sent'));
    }), [analytics, layoutService, t, gatedTourService]);
    return (_jsxs("div", { children: [_jsx("div", { className: "px-8 pb-6", children: _jsx("div", { className: "select-text font-secondary text-sm font-normal tracking-[0.0075rem] text-modal-gray-dark", children: _jsx("span", { children: t('gated-tour.verify-email-description').replace('{{email}}', email) }) }) }), _jsx("div", { className: "px-8 py-3", children: _jsx(CodeInput, { className: "h-[3.625rem] w-full max-w-full gap-1 rounded-lg border", errorClassName: "leading-[0.9375rem] pt-0", containerClassName: "leading-none", error: verificationCodeError ? t(verificationCodeError) : '', onChange: onVerificationCodeChange, testId: "gated-verification-code-input" }) }), _jsx("div", { className: "flex justify-between px-8 py-6", children: _jsx(Link, { className: `items-center !font-primary !text-base !font-bold !text-modal-dark hover:!text-modal-gray-dark ${requestEmailCooldownEmail === email && requestEmailCooldown > 0
                        ? 'pointer-events-none cursor-default !text-modal-gray-label'
                        : 'underline'}`, onClick: onResendCodeClick, testId: "gated-resend-code", children: `${t('gated-tour.resend-code')}${requestEmailCooldownEmail === email && requestEmailCooldown > 0 ? ` (${requestEmailCooldown})` : ''}` }) })] }));
});
VerifyCodeView.displayName = 'VerifyEmailView';
export default VerifyCodeView;
