import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserInfo } from '@g360/vt-utils/';
import draftToHtml from 'draftjs-to-html';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useEffect, useMemo, useState } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import ExpandIcon from '../../../icons/ExpandIcon';
import IconButton from './IconButton';
const HotSpotContentImage = ({ src, alt, style, onExpand, }) => (
// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
_jsxs("div", { className: "group relative w-fit py-[5px]", onClick: BrowserInfo.isMobile()
        ? (e) => {
            e.stopPropagation();
            onExpand();
        }
        : undefined, children: [_jsx("img", { src: src, alt: alt, style: style, className: "rounded-lg" }), _jsx("div", { className: "absolute right-2 top-[13px] hidden group-hover:block", children: _jsx(IconButton, { onClick: (e) => {
                    e.stopPropagation();
                    onExpand();
                }, children: _jsx(ExpandIcon, {}) }) })] }));
const HotSpotBalloonContent = ({ hotSpotInfo, signatureData, canvasRect, areHotSpotLinksClickable, onExpandImage, }) => {
    const [hotSpotParsedHtml, setHotSpotParsedHtml] = useState(null);
    const maxWidthOverride = useMemo(() => {
        if (canvasRect.width < 320 + 32)
            return { maxWidth: `${canvasRect.width - 32}px` };
        return {};
    }, [canvasRect.width]);
    const maxHeightOverride = useMemo(() => {
        if (canvasRect.height < 360 + 32)
            return { maxHeight: `${canvasRect.height - 32}px` };
        return {};
    }, [canvasRect.height]);
    useEffect(() => {
        if (!hotSpotInfo)
            return;
        let html = null;
        if (Array.isArray(hotSpotInfo.ops)) {
            const converter = new QuillDeltaToHtmlConverter(hotSpotInfo.ops); // Quill.JS
            html = converter.convert();
        }
        else if (hotSpotInfo.ops.blocks && Array.isArray(hotSpotInfo.ops.blocks)) {
            const customEntityTransformNoLinks = (entity, text) => entity.type === 'LINK' ? text : undefined;
            const linkTransform = areHotSpotLinksClickable ? undefined : customEntityTransformNoLinks;
            html = draftToHtml(hotSpotInfo.ops, undefined, undefined, linkTransform); // Draft.JS
        }
        else {
            return;
        }
        setHotSpotParsedHtml(html);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotSpotInfo, areHotSpotLinksClickable]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transformMediaNodes = (node, index) => {
        if (node.name === 'p') {
            const newNode = Object.assign(Object.assign({}, node), { attribs: Object.assign(Object.assign({}, node.attrs), { class: `font-secondary font-normal select-text text-modal-gray-dark text-sm leading-6 ${node.children.length === 0 ? 'mb-6' : ''}` }) });
            return convertNodeToElement(newNode, index, transformMediaNodes);
        }
        if (node.name === 'h1') {
            const newNode = Object.assign(Object.assign({}, node), { attribs: Object.assign(Object.assign({}, node.attrs), { class: `font-primary text-base font-bold text-modal-dark leading-6 first:mt-0 mt-6` }) });
            return convertNodeToElement(newNode, index, transformMediaNodes);
        }
        if (node.name === 'img') {
            const rawStyle = node.attribs.style || '';
            const dimensions = rawStyle.split(';').reduce((acc, styleProp) => {
                const [key, value] = styleProp.split(':');
                if (key && value && (key === 'width' || key === 'height')) {
                    return Object.assign(Object.assign({}, acc), { [key.trim()]: parseInt(value.trim(), 10) });
                }
                return acc;
            }, {});
            const style = {
                maxHeight: `${dimensions.height}px`,
            };
            let imageSrc = node.attribs.src;
            if (imageSrc.startsWith('http')) {
                const params = new URLSearchParams();
                Object.entries(signatureData).forEach(([key, value]) => params.append(key, value));
                imageSrc = `${imageSrc}?${params.toString()}`;
            }
            // imageSrc = 'https://picsum.photos/1200/800';
            return (_jsx(HotSpotContentImage, { src: imageSrc, alt: node.attribs.alt, style: style, onExpand: () => onExpandImage(imageSrc) }, index));
        }
        return undefined;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const preprocessNodes = (nodes) => {
        // Remove some draftjs random linebreak noddes that don't really mean anything
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newNodes = nodes.filter((node) => !(node.type === 'text' && node.data === '\n'));
        return newNodes;
    };
    if (!hotSpotInfo || !hotSpotParsedHtml)
        return null;
    return (_jsx("div", { className: "relative max-h-[360px] min-w-[32px] max-w-[320px] ", style: Object.assign(Object.assign({}, maxWidthOverride), maxHeightOverride), children: _jsx(OverlayScrollbarsComponent, { defer: true, className: "p-small pointer-events-auto relative max-h-[360px] ", style: Object.assign(Object.assign(Object.assign({}, maxHeightOverride), maxWidthOverride), { width: maxWidthOverride.maxWidth ? 'max-content' : undefined }), options: {
                scrollbars: { theme: 'os-theme-hotspot' },
                overflow: { x: 'hidden', y: 'scroll' },
            }, children: _jsx("div", { className: "hot-spot-content py-5 pl-6 pr-5 align-middle", children: ReactHtmlParser(hotSpotParsedHtml, {
                    transform: transformMediaNodes,
                    preprocessNodes,
                }) }) }) }));
};
export default HotSpotBalloonContent;
