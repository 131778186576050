var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
const ShadowFilter = ({ filterSize = 38, shadowIntensity = 'light', }) => {
    const shadowIntensityValue = shadowIntensity === 'light' ? 0.35 : 0.55;
    return (_jsx(_Fragment, { children: _jsxs("filter", { id: "shadow-filter", x: "-2", y: "-1", width: filterSize, height: filterSize, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [_jsx("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }), _jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }), _jsx("feOffset", { dy: "2" }), _jsx("feGaussianBlur", { stdDeviation: "1.5" }), _jsx("feComposite", { in2: "hardAlpha", operator: "out" }), _jsx("feColorMatrix", { type: "matrix", values: `0 0 0 0 0.101961 0 0 0 0 0.133333 0 0 0 0 0.180392 0 0 0 ${shadowIntensityValue} 0` }), _jsx("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "BackgroundImageFixResult" }), _jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFixResult", result: "shape" })] }) }));
};
const asIconWrapper = (WrappedComponent) => {
    const Icon = (_a) => {
        var { filterSize, shadowIntensity } = _a, props = __rest(_a, ["filterSize", "shadowIntensity"]);
        return (_jsx(WrappedComponent, Object.assign({}, props, { filter: _jsx(ShadowFilter, { filterSize: filterSize, shadowIntensity: shadowIntensity }) })));
    };
    return Icon;
};
export default asIconWrapper;
