var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Input from '../common/Input';
import { useTranslation } from '../contexts/useLocalizationContext';
import useServices from '../contexts/useServices';
import useReactive from '../hooks/useReactive';
import useResizeObserver from '../hooks/useResizeObserver';
import CheckIcon from '../icons/CheckIcon';
import DropIcon from '../icons/DropIcon';
import SpinnerIcon from '../icons/SpinnerIcon';
const FlagImage = ({ src, spinnerSize = 'lg' }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hidden, setHidden] = useState(false);
    const twOpacity = isLoaded ? 'opacity-100' : 'opacity-0';
    const twSpinnerSize = spinnerSize === 'sm' ? 'h-4 w-4' : 'h-6 w-6';
    return (_jsxs("div", { className: "relative h-5 w-[1.875rem]", children: [!hidden && src && (_jsx("img", { className: `h-full w-full object-contain ${twOpacity}`, loading: "lazy", src: src, alt: "country-flag", onLoad: () => setIsLoaded(true), onError: () => {
                    setHidden(true);
                } })), !isLoaded && !hidden && (_jsx("div", { className: "absolute left-0 top-0 flex h-full w-full items-center justify-center", children: _jsx(SpinnerIcon, { className: twSpinnerSize }) }))] }));
};
const PortalAnchor = ({ children, anchorElementRef, }) => {
    const [rootElem, setRootElem] = useState(null);
    const [contentStyle, setContentStyle] = useState({});
    const contentRef = useRef(null);
    const rootRef = useRef(null);
    const handleSizeChange = useCallback(() => {
        if (rootRef.current && anchorElementRef.current && contentRef.current && typeof window !== 'undefined') {
            const anchorPosition = anchorElementRef.current.getBoundingClientRect();
            const contentRect = contentRef.current.getBoundingClientRect();
            const anchorTop = anchorPosition.top + anchorPosition.height;
            const rootRect = rootRef.current.getBoundingClientRect();
            const style = {
                top: `${anchorTop - rootRect.top}px`,
                left: `${anchorPosition.left - rootRect.left}px`,
                maxHeight: 'calc(100% - 20px)',
            };
            const bottomOverflow = Math.max(0, anchorTop + contentRect.height - window.innerHeight);
            let styleTop = anchorTop;
            if (bottomOverflow) {
                styleTop = window.innerHeight - contentRef.current.clientHeight - 10;
                style.top = `${styleTop}px`;
            }
            setContentStyle(style);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorElementRef.current, contentRef.current, rootRef.current]);
    useEffect(() => {
        var _a;
        const elem = (_a = document.querySelector('.vt-player-lib')) !== null && _a !== void 0 ? _a : document.body;
        rootRef.current = elem;
        setRootElem(elem);
        return () => {
            setRootElem(null);
        };
    }, []);
    useLayoutEffect(() => {
        handleSizeChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootElem, anchorElementRef.current]);
    useResizeObserver(handleSizeChange, contentRef, true, 3);
    if (!rootElem)
        return null;
    return (_jsx(_Fragment, { children: createPortal(_jsx("div", { ref: contentRef, className: "absolute z-[9001] h-[17.875rem] py-1", style: contentStyle, children: children }), rootElem) }));
};
const PhoneInput = ({ value, className = '', error, onChange }) => {
    const { phoneNumberService } = useServices();
    const { phoneServiceReady, phoneCodes } = useReactive(phoneNumberService, ['phoneServiceReady', 'phoneCodes']);
    const [inputFocused, setInputFocused] = useState(false);
    const [countryFlag, setCountryFlag] = useState(null);
    const t = useTranslation();
    const buttonRef = useRef(null);
    const inputRef = useRef(null);
    const parsedPhoneData = useMemo(() => {
        const data = phoneNumberService.parsePhoneData(value);
        if (data.countryFlag && data.countryFlag !== countryFlag)
            setCountryFlag(data.countryFlag);
        return data;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, phoneServiceReady]);
    const handleCallingCodeChange = (nextCallingCode, nextCountryCode, focus = false) => {
        // Lock in the preferred countryCode until the input is cleared
        if (nextCountryCode) {
            const data = phoneCodes === null || phoneCodes === void 0 ? void 0 : phoneCodes.getCountryData(nextCountryCode);
            phoneNumberService.setPreferredCountry(nextCountryCode);
            if ((data === null || data === void 0 ? void 0 : data.flagUrl) && data.flagUrl !== countryFlag)
                setCountryFlag(data.flagUrl);
        }
        const formattedNextCallingCode = `+${nextCallingCode}`;
        onChange === null || onChange === void 0 ? void 0 : onChange(formattedNextCallingCode, formattedNextCallingCode);
        if (focus) {
            setTimeout(() => {
                var _a, _b, _c, _d;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.setSelectionRange((_c = inputRef.current) === null || _c === void 0 ? void 0 : _c.value.length, (_d = inputRef.current) === null || _d === void 0 ? void 0 : _d.value.length);
                // Something weird is going on with the dropdown, if we focus to early the dropdown will not close
            }, 300);
        }
    };
    const handlePhoneInputChange = (nextValue) => {
        let plusValue = nextValue;
        if (!plusValue.startsWith('+'))
            plusValue = `+${plusValue}`;
        if (!phoneCodes) {
            onChange === null || onChange === void 0 ? void 0 : onChange(plusValue);
            return;
        }
        const countryCodes = phoneCodes.getCountryCodesForCallingCode(plusValue);
        if (!countryCodes || countryCodes.length === 0) {
            onChange === null || onChange === void 0 ? void 0 : onChange(plusValue);
            return;
        }
        const countryData = phoneCodes.getCountryData(countryCodes[0]);
        if (!countryData) {
            onChange === null || onChange === void 0 ? void 0 : onChange(plusValue);
            return;
        }
        const formattedCallingCode = `+${countryData.callingCode}`;
        onChange === null || onChange === void 0 ? void 0 : onChange(plusValue, formattedCallingCode);
    };
    const handleDropDownChange = (nextCountryCode) => {
        const nextCountryData = phoneCodes === null || phoneCodes === void 0 ? void 0 : phoneCodes.getCountryData(nextCountryCode);
        if (nextCountryData)
            handleCallingCodeChange(nextCountryData.callingCode, nextCountryData.countryCode, true);
    };
    useEffect(() => {
        if (!phoneServiceReady) {
            phoneNumberService.initService();
            return;
        }
        (() => __awaiter(void 0, void 0, void 0, function* () {
            // If value is already set (f.e. from session storage), don't trigger geolocation
            if (value !== '' && value !== '+')
                return;
            const preferredCallingCode = phoneNumberService.getPreferredCallingCode();
            // Also don't trigger geolocation if the user has already selected a country previously
            if (preferredCallingCode) {
                handleCallingCodeChange(preferredCallingCode);
                return;
            }
            const geoLocationData = yield phoneNumberService.getCountryCallingCodeByGeoLocation();
            handleCallingCodeChange(geoLocationData.callingCode);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneServiceReady]);
    useEffect(() => {
        // Preferred countryCode is locked in until the number is cleared
        if (value === '+')
            phoneNumberService.removePreferredCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    let twBorderColor = inputFocused ? 'border-modal-gray-dark' : 'border-modal-gray-light';
    if (error)
        twBorderColor = 'border-modal-red';
    return (_jsxs("span", { children: [_jsxs("div", { className: `flex h-14 ${className}`, children: [_jsx(Listbox, { as: "div", className: "relative block text-left", disabled: !phoneCodes || !phoneServiceReady, value: parsedPhoneData.countryCode || phoneNumberService.getFallbackCountryCode(), onChange: handleDropDownChange, children: ({ open }) => {
                            var _a;
                            return (_jsxs(_Fragment, { children: [_jsxs(Listbox.Button, { ref: buttonRef, className: `
                  group flex h-full min-w-[5.625rem] max-w-[7rem] flex-row items-center rounded-l-lg
                  border-y border-l stroke-modal-gray-label pl-5 pr-4 font-primary text-base
                  font-bold text-modal-dark hover:stroke-modal-dark focus:stroke-modal-dark ${twBorderColor}`, "data-testid": "country-code-btn", children: [_jsx("span", { className: "pointer-events-none max-w-full pr-4 group-disabled:text-modal-gray-light", children: _jsx(FlagImage, { src: countryFlag }) }), _jsx(DropIcon, { className: `pointer-events-none h-2 w-2 group-disabled:stroke-modal-gray-light ${open ? 'rotate-180' : ''}` })] }), _jsx(PortalAnchor, { anchorElementRef: buttonRef, children: _jsx(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0", enterTo: "transform opacity-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100", leaveTo: "transform opacity-0", children: _jsx(Listbox.Options, { className: `z-10 h-full w-60 overflow-hidden rounded-lg border bg-white outline-none ${twBorderColor}`, unmount: false, children: _jsx(OverlayScrollbarsComponent, { className: "p-small pointer-events-auto relative h-full w-full", options: { overflow: { x: 'hidden' }, scrollbars: { theme: 'os-theme-custom' } }, children: (phoneCodes === null || phoneCodes === void 0 ? void 0 : phoneCodes.countriesList) ? ((_a = phoneCodes.countriesList) === null || _a === void 0 ? void 0 : _a.map((item) => (_jsx(Listbox.Option, { as: Fragment, value: item.countryCode, children: ({ active }) => {
                                                            const twBgColor = active ? 'bg-modal-gray' : '';
                                                            return (_jsxs("button", { type: "button", className: `flex w-full pb-[0.44rem] pt-[0.38rem] ${twBgColor}`, children: [_jsxs("span", { className: "flex pr-[0.62rem]", children: [_jsx("span", { className: "flex min-w-[1.74rem] items-center justify-center", children: parsedPhoneData.countryCode === item.countryCode && (_jsx(CheckIcon, { className: "h-2 w-2 stroke-modal-dark" })) }), _jsx(FlagImage, { src: item.flagUrl, spinnerSize: "sm" })] }), _jsx("span", { className: "max-w-[10rem] grow overflow-hidden px-[0.62rem] text-left font-primary text-sm\n                                      font-bold text-modal-dark", children: item.name }), _jsxs("span", { className: "px-5 pt-1 text-right font-primary text-xs font-bold text-modal-gray-dark", children: ["+", item.callingCode] })] }));
                                                        } }, item.countryCode)))) : (_jsx(SpinnerIcon, { className: "h-6 w-6", isDarkSpinner: true })) }) }) }) })] }));
                        } }), _jsx(Input, { ref: inputRef, id: "phone-number", error: error, hideErrorMessage: true, className: `h-full w-full grow rounded-l-none rounded-r border ${twBorderColor}`, label: t('phone-input.label'), value: parsedPhoneData.formattedNumber, type: "tel", onChange: (e) => handlePhoneInputChange(e.target.value), onFocus: () => setInputFocused(true), onBlur: () => setInputFocused(false), testId: "phone-number-input" })] }), error && (_jsx("div", { className: "items-center pl-[20px] pt-[6px] font-primary text-[12px] font-bold leading-[15px] text-modal-red", children: error }))] }));
};
export default PhoneInput;
