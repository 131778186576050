import type { AssetConfig } from '@g360/vt-types';
import { normalizeVec3, vec3CrossProduct } from '@g360/vt-utils';
import urlJoin from 'url-join';

import { fetchImage } from '../../../common/Utils';
import {
  createTexture,
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../../common/webglUtils';
import type { Image } from '../../../types/internal';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './measureCrosshair.fs.glsl';
import vertexShaderSource from './measureCrosshair.vs.glsl';

const XHAIR_RADIUS = 120;
/** If crosshair is too far, the user can't see it, so we artificially make it closer (mm) */
const MAX_XHAIR_DIST = 2200;
/** Draws the crosshair outer ring */
class MeasureCrosshairProgram {
  // Save the crosshair image to avoid fetching it multiple times
  private static crosshairImg: Image | null = null;

  private readonly uvMap = new Float32Array([1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0]);

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private assetConfig: AssetConfig;
  private mainProgram: MeasureToolProgram;

  private vertexBuffer: WebGLBuffer | null;
  private uvBuffer: WebGLBuffer | null = null;
  private vertexAttribute = 0;
  private uvAttribute = 0;

  private projectionMatUniform: WebGLUniformLocation | null = null;
  private localRotationMatUniform: WebGLUniformLocation | null = null;
  private xhairTexUniform: WebGLUniformLocation | null = null;
  private crosshairTexture: WebGLTexture | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private abortController = window.AbortController ? new AbortController() : null;
  private vertices = new Float32Array(18);

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    assetConfig: AssetConfig,
    mainProgram: MeasureToolProgram
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;
    this.assetConfig = assetConfig;

    this.vertexBuffer = this.gl.createBuffer();
    this.uvBuffer = this.gl.createBuffer();

    this.crosshairTexture = createTexture(this.gl);
  }

  async init(): Promise<void> {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');
      this.uvAttribute = this.gl.getAttribLocation(this.program, 'a_uv');

      this.xhairTexUniform = this.gl.getUniformLocation(this.program, 'u_xhair_tex');

      this.projectionMatUniform = this.gl.getUniformLocation(this.program, 'u_proj_mat');
      this.localRotationMatUniform = this.gl.getUniformLocation(this.program, 'u_local_rot_mat');

      this.vertexAttributes = [this.vertexAttribute, this.uvAttribute];
    }

    await this.loadCrosshairTexture();
  }

  async loadCrosshairTexture(): Promise<void> {
    if (!MeasureCrosshairProgram.crosshairImg) {
      const url = urlJoin(this.assetConfig.assetPath, 'measure/crosshair_outer.png');
      const img = await fetchImage(url, this.abortController);
      if (!img) {
        throw new Error('Failed to load crosshair texture');
      }
      MeasureCrosshairProgram.crosshairImg = img;
    }

    setTextureFromImage(this.gl, this.crosshairTexture, MeasureCrosshairProgram.crosshairImg, {
      minFilter: this.gl.LINEAR_MIPMAP_LINEAR,
      magFilter: this.gl.LINEAR,
      useAlphaChannel: true,
    });

    this.gl.generateMipmap(this.gl.TEXTURE_2D);
  }

  destroy(): void {
    this.gl.deleteTexture(this.crosshairTexture);
    this.abortPending();
    destroyProgram(this.gl, this.program);
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  draw(): void {
    const cursorPosition = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;

    if (!cursorPosition) return;
    // Don't draw outer ring if cursor above missing data point
    if (cursorPosition.distance === 0) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    const normalVec = this.mainProgram.getNormalMapData(cursorPosition.textureX, cursorPosition.textureY);
    if (!normalVec) return;

    const randomAxis = [-normalVec[0], normalVec[1], 2 * normalVec[2]];

    const axis1 = normalizeVec3(vec3CrossProduct(normalVec, randomAxis));
    const axis2 = normalizeVec3(vec3CrossProduct(normalVec, axis1));

    let x = cursorPosition.cartX;
    let y = cursorPosition.cartY;
    let z = cursorPosition.cartZ;

    if (cursorPosition.distance > MAX_XHAIR_DIST) {
      const cursorDirection = normalizeVec3([x, y, z]);
      x = cursorDirection[0] * MAX_XHAIR_DIST;
      y = cursorDirection[1] * MAX_XHAIR_DIST;
      z = cursorDirection[2] * MAX_XHAIR_DIST;
    }

    const v1 = [x + axis1[0] * XHAIR_RADIUS, y + axis1[1] * XHAIR_RADIUS, z + axis1[2] * XHAIR_RADIUS];
    const v2 = [x - axis1[0] * XHAIR_RADIUS, y - axis1[1] * XHAIR_RADIUS, z - axis1[2] * XHAIR_RADIUS];
    const v3 = [x + axis2[0] * XHAIR_RADIUS, y + axis2[1] * XHAIR_RADIUS, z + axis2[2] * XHAIR_RADIUS];
    const v4 = [x - axis2[0] * XHAIR_RADIUS, y - axis2[1] * XHAIR_RADIUS, z - axis2[2] * XHAIR_RADIUS];

    // Two triangles to form a quad
    this.vertices[0] = v3[0];
    this.vertices[1] = v3[1];
    this.vertices[2] = v3[2];
    ///
    this.vertices[3] = v1[0];
    this.vertices[4] = v1[1];
    this.vertices[5] = v1[2];
    ///
    this.vertices[6] = v2[0];
    this.vertices[7] = v2[1];
    this.vertices[8] = v2[2];
    ///
    this.vertices[9] = v2[0];
    this.vertices[10] = v2[1];
    this.vertices[11] = v2[2];
    ///
    this.vertices[12] = v1[0];
    this.vertices[13] = v1[1];
    this.vertices[14] = v1[2];
    ///
    this.vertices[15] = v4[0];
    this.vertices[16] = v4[1];
    this.vertices[17] = v4[2];

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMap, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.uvAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.uniformMatrix4fv(this.projectionMatUniform, false, this.mainProgram.projectionMatrix);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.crosshairTexture);
    this.gl.uniform1i(this.xhairTexUniform, 1);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.vertices.length / 3);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default MeasureCrosshairProgram;
