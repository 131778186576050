import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import urljoin from 'url-join';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
function SceneTutorial() {
    const { assetConfig } = useGlobalContext();
    const { layoutService } = useServices();
    const { showTutorial } = useReactive(layoutService, ['showTutorial']);
    const [twGifOpacity, setTwGifOpacity] = useState('opacity-0');
    const gifPath = urljoin(assetConfig.assetPath, 'images', `tutorial.gif`);
    const t = useTranslation();
    const handleGifLoaded = () => {
        setTwGifOpacity('opacity-100');
    };
    return (_jsxs(Transition, { show: showTutorial, className: "pointer-events-none absolute inset-0 z-tutorial-anim flex select-none flex-col items-center justify-center", enter: "transition duration-[800ms] ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-[800ms] ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", "data-testid": "tutorial", children: [_jsx("div", { className: "absolute left-1/2 top-1/2 -z-10 h-[12.5rem] w-[25.625rem] -translate-x-1/2 -translate-y-1/2 bg-modal-dark/30 blur-[77px]" }), _jsx("img", { className: `h-[4.063rem] w-[8.125rem] transition ${twGifOpacity}`, src: gifPath, alt: "tutorial", onLoad: handleGifLoaded }), _jsx("div", { className: "max-w-[14.375rem] text-center font-primary text-lg font-bold text-white", children: t('tutorial.text') })] }));
}
export default SceneTutorial;
