import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CheckIcon from '../icons/CheckIcon';
const CheckBox = ({ className = '', labelClassName = '', checkBoxClassName = '', iconClassName = '', label, description, disabled, checked, onChange, testId, }) => {
    const twDisabled = disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer';
    const twChecked = checked
        ? 'bg-modal-dark group-hover:bg-modal-gray-dark rounded-lg'
        : 'bg-white text-primary group-hover:bg-modal-gray-light border border-modal-gray-light rounded-md';
    const twLabelSize = description ? 'text-base' : 'text-sm';
    const twDescriptionColor = disabled ? 'text-modal-gray-label' : 'text-modal-gray-dark';
    return (_jsxs(_Fragment, { children: [_jsxs("label", { className: `group flex items-center ${twDisabled} ${className}`, htmlFor: label, children: [_jsxs("span", { className: `flex h-6 w-6 shrink-0 items-center justify-center ${checkBoxClassName} ${twChecked}`, children: [_jsx("input", { id: label, className: "hidden", type: "checkbox", disabled: disabled, checked: checked, "aria-checked": checked, onChange: onChange, "data-testid": testId }), _jsx(CheckIcon, { className: `h-1.5 w-1.5 stroke-white ${iconClassName}` })] }), label && (_jsx("span", { className: `ml-3 font-primary font-bold text-modal-dark ${labelClassName} ${twLabelSize}`, children: label }))] }), description && (_jsx("div", { className: "mb-3 ml-9 flex items-start", children: _jsx("div", { className: "", children: _jsx("p", { className: `font-secondary text-sm font-normal ${twDescriptionColor}`, children: description }) }) }))] }));
};
CheckBox.displayName = 'Checkbox';
export default CheckBox;
