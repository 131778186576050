import { drawFloorPlanImage } from './utils/drawing';
import { getSvgViewBoxSizes } from './utils/svg';
export default class FloorPlanRenderer {
    constructor(ctx, floorPlanSize) {
        /* _opt, original, .svg  |  could be a lot simpler if only .SVG was supported */
        this.images = new Map();
        this.floorPlanSize = floorPlanSize;
        this.drawFloorPlanImageProps = {
            halfCanvasSize: 0,
            ctx,
            img: new Image(),
            angle: 0,
            userScale: 0,
            pixelScale: 0,
            floorPlanOffset: { x: 0, y: 0 },
            opacity: 0,
        };
    }
    render({ opacity, halfCanvasSizeDpr, yaw, scale, floorPlanCenter }) {
        if (opacity <= 0)
            return;
        const floorPlanImage = this.getBestLoadedFloorPlanImage();
        if (!floorPlanImage)
            return;
        this.drawFloorPlanImageProps.img = floorPlanImage.htmlImageElement;
        this.drawFloorPlanImageProps.pixelScale = floorPlanImage.pixelScale;
        this.drawFloorPlanImageProps.halfCanvasSize = halfCanvasSizeDpr;
        this.drawFloorPlanImageProps.angle = yaw;
        this.drawFloorPlanImageProps.userScale = scale;
        this.drawFloorPlanImageProps.floorPlanOffset = floorPlanCenter;
        this.drawFloorPlanImageProps.opacity = opacity;
        drawFloorPlanImage(this.drawFloorPlanImageProps);
    }
    setFloorPlanImage(imgUrlConfig, imageType, onImageLoaded, onImageError) {
        if (!this.images.has(imageType)) {
            const image = {
                url: imgUrlConfig[imageType],
                htmlImageElement: new Image(),
                loaded: false,
                pixelScale: 1,
            };
            image.htmlImageElement.onerror = () => {
                onImageError(imageType);
            };
            if (imageType === 'svg') {
                // svg images are created by manually fetching the svg string and creating a blob url
                fetch(image.url)
                    .then((response) => response.text())
                    .then((svgString) => {
                    const url = URL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' }));
                    image.htmlImageElement.onload = () => {
                        var _a;
                        URL.revokeObjectURL(url);
                        // FireFox can't get width and height from svg image,
                        // using viewBox size to set width and height
                        if (!image.htmlImageElement.width || !image.htmlImageElement.height) {
                            const { width, height } = getSvgViewBoxSizes(svgString);
                            image.htmlImageElement.width = width;
                            image.htmlImageElement.height = height;
                        }
                        image.pixelScale = image.htmlImageElement.width / (((_a = this.floorPlanSize) === null || _a === void 0 ? void 0 : _a.width) || 1);
                        image.loaded = true;
                        onImageLoaded();
                    };
                    image.htmlImageElement.src = url;
                })
                    .catch(() => {
                    onImageError(imageType); // Handle network or fetch errors
                });
            }
            else {
                // normal images are loaded as usual
                image.htmlImageElement.onload = () => {
                    var _a;
                    image.pixelScale = image.htmlImageElement.width / (((_a = this.floorPlanSize) === null || _a === void 0 ? void 0 : _a.width) || 1);
                    image.loaded = true;
                    onImageLoaded();
                };
                image.htmlImageElement.src = image.url;
            }
            this.images.set(imageType, image);
        }
        else {
            onImageError(imageType); // unknown type
        }
    }
    getBestLoadedFloorPlanImage() {
        let img = this.images.get('svg');
        if (img && img.loaded)
            return img;
        img = this.images.get('original');
        if (img && img.loaded)
            return img;
        img = this.images.get('optimized');
        if (img && img.loaded)
            return img;
        return undefined;
    }
}
