import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import urljoin from 'url-join';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServicesContext from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import usePreloading from '../../preload/hooks/usePreloading';
function MeasureTutorial() {
    const { assetConfig } = useGlobalContext();
    const { layoutService } = useServicesContext();
    const { preloading } = usePreloading();
    const { showMeasureTutorial, showTutorial, measureModePlatform } = useReactive(layoutService, [
        'showMeasureTutorial',
        'showTutorial',
        'measureModePlatform',
    ]);
    const [twGifOpacity, setTwGifOpacity] = useState('opacity-0');
    const gifPath = urljoin(assetConfig.assetPath, 'measure', `measure_tutorial.gif`);
    const t = useTranslation();
    const handleGifLoaded = () => {
        setTwGifOpacity('opacity-100');
    };
    return (_jsxs(Transition, { show: showMeasureTutorial && !showTutorial && !preloading, className: "pointer-events-none absolute inset-0 z-tutorial-anim flex select-none flex-col items-center justify-center", enter: "transition duration-[800ms] ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-[800ms] ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", "data-testid": "tutorial", children: [_jsx("div", { className: "absolute left-1/2 top-1/2 -z-10 h-[12.5rem] w-[25.625rem] -translate-x-1/2 -translate-y-1/2 bg-modal-dark/30 blur-[77px]" }), _jsx("img", { className: `w-[11.8rem] transition ${twGifOpacity}`, src: gifPath, alt: "tutorial", onLoad: handleGifLoaded }), _jsx("div", { className: "max-w-[14.375rem] text-center font-primary text-lg font-bold text-white", children: t(measureModePlatform === 'handheld'
                    ? 'measure-tool.tutorial-gif-text-mobile'
                    : 'measure-tool.tutorial-gif-text-desktop') })] }));
}
export default MeasureTutorial;
